import React from 'react';
import IntegrationsPage from '../../../pages/integrationsPage';
import { Box } from '@chakra-ui/react';

// Define types for component props
interface ComponentWrapperProps {
    componentId: string;
    args?: Record<string, any>;
}


// Define component map
const componentMap: Record<string, React.ComponentType<any>> = {
    'integration-connect': (props: any) => <Box minW="500px"> <IntegrationsPage connectors={props.connectors} /></Box>,
    // Add more components as needed
};

// Component wrapper that dynamically renders based on componentId
export const CanvasCustomComponent: React.FC<ComponentWrapperProps> = ({
    componentId,
    args = {}
}) => {
    const Component = componentMap[componentId];

    if (!Component) {
        return <div>Component not found: {componentId}</div>;
    }

    return <Component {...args} />;
};

export default CanvasCustomComponent;