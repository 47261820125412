import React, { useEffect, useMemo, useState } from 'react';
import FlowPageProps from './pageProps';
import { Box, Button, CloseButton, Heading, HStack, Kbd, Progress, Stack, Text, Tooltip } from '@chakra-ui/react';
import { AutoUI, GeniouslyThemeProvider, MarkdownContent, ChatInput } from 'geniously-chat-ui';
import { EditableField } from '../../EditableField/editableField';
import { IconAlertTriangle, IconArrowRight, IconChevronLeft, IconChevronRight, IconAlertTriangleFilled, IconInfoCircle } from '@tabler/icons-react';
import { motion } from 'framer-motion';

const ProblemPanel = ({ message, type, onClose }: { message: string, type: "error" | "warning" | "info", onClose: () => void }) => {
    function getIcon() {
        if (type == "error")
            return <IconAlertTriangleFilled size="15px" />
        else if (type == "warning")
            return <IconAlertTriangle size="15px" />
        else if (type == "info")
            return <IconInfoCircle size="15px" />
    }
    function getColor() {
        if (type === "error") return "red.100";
        if (type === "warning") return "orange.100";
        if (type === "info") return "blue.100";
        return "gray.500";
    }
    return <HStack background={getColor()} flexGrow={1} justify="space-between">
        <HStack p="2px 6px">

            {getIcon()}
            <Text fontWeight={500} noOfLines={1}>

                {message}
            </Text>
        </HStack>
        <CloseButton onClick={onClose} />
    </HStack>
}

const FlowPageWrapper = ({ children, pageProps }: { children?: React.ReactNode, pageProps: FlowPageProps }) => {


    // const [showShortcuts, setShowShortcuts] = useState(true);

    // useEffect(() => {
    //     const handleKeyDown = (event: KeyboardEvent) => {
    //         const activeElement = document.activeElement;
    //         const isInputFocused = activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA');

    //         if (!isInputFocused) {
    //             if (event.key === 'Enter') {
    //                 pageProps.onNext();
    //             } else if (event.key === 'Backspace' || event.key === 'ArrowLeft') {
    //                 pageProps.onBack();
    //             }
    //         }
    //     };

    //     const handleFocusIn = () => setShowShortcuts(false);
    //     const handleFocusOut = () => setShowShortcuts(true);

    //     document.addEventListener('keydown', handleKeyDown);
    //     document.addEventListener('focusin', handleFocusIn);
    //     document.addEventListener('focusout', handleFocusOut);

    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown);
    //         document.removeEventListener('focusin', handleFocusIn);
    //         document.removeEventListener('focusout', handleFocusOut);
    //     };
    // }, [pageProps]);

    const [hiddenProblems, setHiddenProblems] = useState<number[]>();
    const [initialState, setInitialState] = useState<{ [pageIndex: number]: boolean }>({});
    useEffect(() => {
        if (pageProps.currentPageState) {
            setHiddenProblems([]);
            setInitialState({ [pageProps.currentPageIndex]: true });
        }


    }, [pageProps.currentPageState]);

    const isInitialState = useMemo(() => !initialState[pageProps.currentPageIndex], [initialState, pageProps.currentPageIndex])

    return (
        <Stack p={[5, 10, 10]} flexGrow={1} flexShrink={1} height="100%">
            <Stack position="relative" overflow="auto" flexGrow={1} >
                {pageProps.isUpdating && pageProps.updatingMode === 'generate' && pageProps.page.type != "chat" && (

                    <motion.div
                        initial={{ y: '-100%' }}
                        animate={{ y: '0%' }}
                        transition={{ duration: 6, repeat: Infinity, repeatType: 'loop' }}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,

                            background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255 255 255 / 0.2) 20%,rgba(167 167 167 / 0.2) 60%,  rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 0) 100%)',
                            zIndex: 1,
                            filter: 'blur(50px)'
                        }}
                    />

                )}
                <Stack position="sticky" top={0} zIndex={10} background='linear-gradient(to bottom, white 0%, white 80%, rgba(255, 255, 255, 0) 100%)' pb="5px">
                    <Heading>{pageProps.page.title}</Heading>
                </Stack>

                <GeniouslyThemeProvider>
                    <Stack>
                        <MarkdownContent>
                            {pageProps.page.description}
                        </MarkdownContent>
                    </Stack>
                </GeniouslyThemeProvider>
                <Stack flexGrow={1}>
                    {children}
                </Stack>

            </Stack>
            <Stack spacing={1}>
                {!isInitialState && pageProps.page?.problems?.filter((p, i) => i < 4 && /* !p.path &&*/ !(hiddenProblems?.includes(i))).map((p, i) => (
                    <ProblemPanel message={p.message} type={p.type} onClose={() => {

                        setHiddenProblems([...(hiddenProblems || []), i]);

                    }} />
                ))}

            </Stack>

            <HStack>

                <Progress isIndeterminate={pageProps.isUpdating} colorScheme="brand"
                    //value={100}
                    value={pageProps.currentPageFinal ? 100 : ((pageProps.currentPageIndex + 1) / (pageProps.totalPages + 1) * 100)}
                    size="xs" width="100%" />
            </HStack>
            <Stack direction="row" justifyContent="space-between">

                {pageProps.currentPageIndex > 0 ? <Button onClick={pageProps.onBack} leftIcon={<IconChevronLeft />}
                    isDisabled={pageProps.isUpdating}
                >Back</Button> : <Box minW="100px"></Box>}

                {pageProps.page.supports_feedback && <Stack flexGrow={1} maxW={["50%", "800px"]} opacity={pageProps.isUpdating ? 0.2 : undefined}>
                    {pageProps.onFeedback && <GeniouslyThemeProvider>
                        <ChatInput
                            placeholderText={pageProps?.page.type == "chat" ? "Type your message" : 'Type your feedback here'}
                            onSend={(message) => {
                                if (pageProps.onFeedback && !pageProps.isUpdating) {
                                    pageProps.onFeedback(message);
                                }
                            }}
                        />
                    </GeniouslyThemeProvider>
                    }
                </Stack>}
                <Tooltip placement="top-start" label={!pageProps.page.ready ? "Please finish this step before moving to next" : undefined}>

                    <Button colorScheme="brand" isDisabled={!pageProps.page.ready || pageProps.isUpdating} rightIcon={<IconChevronRight />} onClick={() => {

                        if (pageProps.page.ready) {
                            pageProps.onNext()
                        }

                    }}>{!pageProps.currentPageFinal ? "Next" : "Finish"}</Button>
                </Tooltip>
            </Stack>
        </Stack >
    );
};

export default FlowPageWrapper;