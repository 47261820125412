import { useEffect, useMemo, useRef, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { FlowSession, PageDefinition } from './model';
import { FlowSessionService, FlowSessionServiceType } from './FlowSessionService';

export function useFlow(flowType: string) {
    const flowSessionService: FlowSessionServiceType = useMemo(() => new FlowSessionService(flowType), [flowType]);
    const [flowSession, setFlowSession] = useState<FlowSession | null>(null);
    const [currentPageState, setCurrentPageState] = useState<any>();

    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [updatingMode, setUpdatingMode] = useState<"refresh" | "generate">();
    const [queryParams, setQueryParams] = useSearchParams();


    const toast = useToast();
    const _updatePageStatePromiseRef = useRef<() => void>();
    async function updatePageState(newState: any): Promise<void> {
        setCurrentPageState(newState);
        return new Promise((resolve) => {
            _updatePageStatePromiseRef.current = resolve;
        });
    }

    function withUpdateIndicator(updateMode: "refresh" | "generate", promise: Promise<any>) {
        setIsUpdating(true);
        setUpdatingMode(updateMode);
        promise.finally(() => {
            setIsUpdating(false);
            setUpdatingMode(undefined);
        });
    }

    function handleErrors(error?: Error) {
        toast({
            title: "Error",
            description: error?.message || "Ooops... Something went wrong. Please try again later",
            status: "error",
            duration: 5000,
            isClosable: true,
        });
    }

    function handleUpdate(updatedCurrentPage: PageDefinition) {
        if (!isUpdating) {
            setUpdatingMode("generate");
            setIsUpdating(true);
        }
        setFlowSession(prevSession => {
            if (!prevSession) return null;
            return {
                ...prevSession,
                currentPage: updatedCurrentPage
            };
        });
    }

    function handleMessage(msg: string, type: "error" | "warning" | "info") {
        if (type === "error") {
            handleErrors(new Error(msg));
        } else if (msg) {
            toast({
                description: msg,
                status: type,
                duration: 5000,
                isClosable: true,
            });
        }
    }
    function handleFinish(redirectUrl: string) {
        window.location.href = redirectUrl || "/";

    }

    useEffect(() => {
        async function fetchInitialData() {
            const sessionId = queryParams.get("sessionId");
            const inputArgs = Object.fromEntries(
                Array.from(queryParams.entries()).filter(([key]) => key.startsWith("i_"))
            );
            if (flowSessionService && (!flowSession || flowSession?.flowSessionId !== sessionId)) {
                const initialSession = await flowSessionService.connect(sessionId, inputArgs);
                setFlowSession(initialSession);
                setQueryParams({ sessionId: initialSession.flowSessionId });

                let hooks = [flowSessionService.onPartialUpdate(handleUpdate), flowSessionService.onMessage(handleMessage), flowSessionService.onFinish(handleFinish)];
                return () => hooks.forEach(h => h());
            }
        }
        if (!flowSession) {
            withUpdateIndicator("generate", fetchInitialData());
        }
    }, [queryParams]);

    useEffect(() => {
        if (currentPageState) {
            withUpdateIndicator("refresh", flowSessionService.update(flowSession.currentPage.id, currentPageState).then((newSessionState) => {
                setFlowSession(newSessionState);
                setCurrentPageState(undefined);
            }));
        }
        else {
            // waiting for update of state
            if (_updatePageStatePromiseRef.current) {
                _updatePageStatePromiseRef.current();
                _updatePageStatePromiseRef.current = undefined;
            }
        }
    }, [currentPageState]);

    function nextPage() {
        if (isUpdating) {
            console.log("Need to wait for update");
            return;
        }
        if (!flowSession.currentPage.ready) {
            console.log("Not ready to move to next page");
            return;
        }
        if (flowSession) {
            if (!flowSessionService.pages?.find(p => p.id === flowSession.currentPage.id)) {
                flowSessionService.pages = [...(flowSessionService.pages || []).slice(0, flowSession.currentPageIndex), flowSession.currentPage, ...(flowSessionService.pages || []).slice(flowSession.currentPageIndex)];
            }

            withUpdateIndicator("generate", flowSessionService.confirmPage(flowSession.currentPage.id).then(setFlowSession).catch(handleErrors));
        } else {
            console.log("Can't go next");
        }
    }

    function previousPage() {
        if (isUpdating) {
            console.log("Need to wait for update");
            return;
        }
        let currentPage = flowSessionService?.pages && flowSessionService?.pages[flowSession.currentPageIndex - 1]
        let goBack = (flowSession) => {
            if (flowSession && flowSession.currentPageIndex > 0) {
                setFlowSession({
                    ...flowSession,
                    currentPage: currentPage,
                    currentPageFinal: false,
                    currentPageIndex: flowSession.currentPageIndex - 1
                });
            } else {
                console.log("Can't go back");
            }
        }
        if (!currentPage) {
            flowSessionService.refresh(flowSession.currentPage.id).then((flowSession) => {
                setFlowSession(flowSession);
                currentPage = flowSessionService?.pages && flowSessionService?.pages[flowSessionService?.pages.length - 1]
                goBack(flowSession)

            })
        }
        else {
            goBack(flowSession)
        }

    }


    // ... existing code ...

    return {
        flowSession,
        setFlowSession, // Add this line
        currentPageState,
        updatePageState,
        isUpdating,
        updatingMode,
        nextPage,
        previousPage,
        withUpdateIndicator,
        handleErrors,
        flowSessionService // Return the flowSessionService
    };

}