import React from "react";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import { FaReply } from "react-icons/fa";
import { IconAddressBook, IconCheckbox, IconSend } from "@tabler/icons-react";

interface AnalyticsStatsProps {
    stats: {
        contacts_count?: number;
        contacted?: number;
        canceled?: number;
        accepted_invite?: number;
        replied?: number;

    };
}
interface MiniWidgetProps {
    title: string;
    subtitle?: string;
    value: string;
    icon?: React.ElementType;
    size?: 'sm' | 'md' | 'lg';
    iconSize?: string;
    color?: string;
}

const MiniWidget = ({ title, subtitle, color, value, icon: Icon, size = 'md' }: MiniWidgetProps) => {
    const sizes = {
        sm: {
            width: "60px",
            height: "40px",
            iconSize: "14px",
            padding: "2px",
            valueFontSize: "sm",
            titleSize: "xs",
            subtitleSize: "hidden"
        },
        md: {
            width: "80px",
            height: "60px",
            iconSize: "22px",
            padding: "4px",
            valueFontSize: "lg",
            titleSize: "sm",
            subtitleSize: "xs"
        },
        lg: {
            width: "100px",
            height: "85px",
            iconSize: "25px",
            padding: "5px 10px",
            valueFontSize: "2xl",
            titleSize: "sm",
            subtitleSize: "xs"
        }
    }
    const sizeProps = sizes[size]
    const currentValueFontSize = sizes[size].valueFontSize
    const currentTitleSize = sizes[size].titleSize

    return (
        <Stack
            textAlign="center"
            minWidth={sizeProps.width}
            minH={sizeProps.height}
            align="center"
            justify="end"
            border="1px solid lightgray"
            backgroundColor={color || "white"}
            p={sizeProps.padding}
            borderRadius={6}

            spacing={subtitle && sizes[size].subtitleSize != "hidden" ? 0 : sizeProps.padding}
        >
            <Stack flexShrink={1} spacing={0}>
                <Text fontSize={currentTitleSize} fontWeight="500" noOfLines={1}>{title}</Text>
                {(subtitle && sizes[size].subtitleSize != "hidden" && <Text m="-4px 0px" fontSize={sizes[size].subtitleSize} fontWeight="500" noOfLines={1}>{subtitle}</Text>)}
            </Stack>
            <HStack>
                <Box>
                    {Icon && <Icon size={sizeProps.iconSize} />}
                </Box>
                <Text whiteSpace="nowrap" fontSize={currentValueFontSize} fontWeight="900">{value}</Text>
            </HStack>
        </Stack>
    )
}


const CampaignStatsWidget: React.FC<AnalyticsStatsProps & { size?: 'sm' | 'md' | 'lg' }> = ({ stats, size = 'lg' }) => {
    return (
        <HStack spacing={2}>
            <MiniWidget
                title="Contacts"
                value={stats.contacts_count?.toString() || "0"}
                icon={IconAddressBook}
                size={size}
            />
            <MiniWidget
                title="Sent"
                value={stats.contacted?.toString() || "0"}
                icon={IconSend}
                size={size}
            />
            <MiniWidget
                title="Progress"
                subtitle={`${stats.contacted || 0}/${stats.contacts_count && stats.canceled
                    ? stats.contacts_count - stats.canceled
                    : 0
                    }`}
                value={
                    stats.contacts_count && !(stats.canceled && (stats.contacts_count - (stats.canceled || 0) == 0))
                        ? Math.round(
                            100 * ((stats.contacted || 0) / (stats.contacts_count - (stats.canceled || 0)))
                        ).toString() + "%"
                        : "-"
                }
                size={size}
            />

            {stats.accepted_invite !== undefined && (
                <MiniWidget
                    title="Accepted invite"
                    subtitle={stats.accepted_invite ? `${stats.accepted_invite}/${stats.contacted}` : undefined}
                    value={(stats.accepted_invite && stats.contacted) ? `${Math.round(100 * stats.accepted_invite / stats.contacted)} %` : "-"}
                    icon={IconCheckbox}
                    size={size}
                />
            )}
            <MiniWidget
                title="Replied"

                subtitle={stats.replied ? `${stats.replied}/${stats.contacted}` : undefined}
                value={(stats.replied && stats.contacted) ? `${Math.round(100 * stats.replied / stats.contacted)} %` : "-"}
                icon={FaReply}
                size={size}
            />
        </HStack>
    );
};

export { CampaignStatsWidget, MiniWidget };