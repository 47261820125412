import { Box, Button, Checkbox, Flex, FormControl, FormLabel, HStack, Heading, Image, Input, Link, PinInput, PinInputField, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { firebaseApp } from '../../firebase/firebaseApp';
import "./loginBox.css"
import { Link as ReactRouterLink } from 'react-router-dom'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup, sendPasswordResetEmail,
  GoogleAuthProvider, GithubAuthProvider
} from "firebase/auth";
import { useAppContext } from '../../appContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FcGoogle } from 'react-icons/fc';
import { FaGithub } from 'react-icons/fa';
import { getApi } from '../../apiService';



const VERIFICATION_CODE_REQUIRED = true


const LoginComponent = ({ mode }: {
  mode: "login" | "signup"
}) => {
  const [email, setEmail] = useState('');

  const [password, _setPassword] = useState('');
  const [password2, _setPassword2] = useState('');

  const [isSigning, setIsSigning] = useState(false);
  const [error, setError] = useState<string|any>('');
  const [verificationCode, setVerificationCode] = useState<string | undefined>(VERIFICATION_CODE_REQUIRED?"":undefined)
  const [inviteInfo, setInviteInfo] = useState<{ invite_id: string, tenant?:string, sent_by:string, join_tenant:boolean }>()
  const [wrongPassword, setWrongPassword] = useState<boolean | string>(false);
  const [searchParams, setSearchParams] = useSearchParams()
  const { currentUser, setCurrentUser } = useAppContext()
  const navigate = useNavigate()
  
  const [isTestingInvite, setIsTestingInvite] = useState(false);
  const [agreeWithTerms, setAggreeWithTerms] = useState(false)
  const isExtensionContext = searchParams.get("extension-login") === "true"
  useEffect(() => {

    if (searchParams && searchParams.get("invite-id")) {
      const invite_id = searchParams.get("invite-id")
      const _email = searchParams.get("email").toLowerCase()
      if (!_email) {
        setError("Incomplete invite link")
        return
      }
      setEmail(_email)
     
      
      getApi().testInvite(_email, { invite_id }).then(res => {
        setInviteInfo(res)
        setIsTestingInvite(false)
      }).catch(error => {
        setIsTestingInvite(false)
        setError((error.response?.data?.errors?.length && error.response?.data?.errors[0] ) ||  error.message || "Error processing invite")
      })
      .finally(()=>{
      })

    }
  }, [searchParams])

  useEffect(() => {
    setError(undefined)
  }, [mode])

  const verifyEmailFormat = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
    if (verificationCode && verificationCode.length == 4) {
      if (email && verifyEmailFormat(email)) {
        
        getApi().testInvite(email, { invite_verification_code: verificationCode }).then(res => {
          setInviteInfo(res)
          setError(res ? undefined : "Invalid verification code or email")
        }).catch(error => setError(error.message))
        .finally(()=>{
          //setIsTestingInvite(false)
        })
      }
      else {
        setError("Please enter your email")
      }
    }
  }, [verificationCode, email])

  const setPassword = (val: string) => {
    _setPassword(val)
    if (password2 && val && val != password2) {
      setError("Password do not match")
    }
    else {
      setError("")
    }
  }

  const setPassword2 = (val: string) => {
    _setPassword2(val)
    if (password && val && val != password) {
      setError("Password do not match")
    } else {
      setError("")
    }
  }




  const handleRegisterWithProvider = (provider) => {
    const auth = getAuth(firebaseApp);
    if (!agreeWithTerms){
      setError("Please agree with our terms and privacy policy")
      return
    }
    if (VERIFICATION_CODE_REQUIRED && !inviteInfo?.invite_id) {
      setError("Please enter your invite code first")
      return
    }
    setError("")
    signInWithPopup(auth, new provider())
      .then((result) => {
        console.log(result)
        // This gives you a provider Access Token. You can use it to access the provider API.


        // The signed-in user info.
        const user = result.user;
        if (mode=="login"){
          setCurrentUser(user)}
          else{

            activateAccount(user)
            
          }

        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        console.error(error)
        const errorMessage = error.message;
        setError(errorMessage.replace("Firebase: ", ""))
        // ...
      });
  };

  const handleResponseTypeUrlParams = (currentUser) => {
    if (currentUser) {
        currentUser.getIdToken().then(token=>{
      
        fetch((process.env.REACT_APP_API_URL || "http://localhost:4080") + "/oauth2/authorize/code",{
          headers:{authorization: 'Bearer ' + token}
        }).then(res=> res.json()).then(res=>{
          if (res.code){

            const redirect_query_param = new URLSearchParams(searchParams)
            redirect_query_param.set("code",res.code)
            
            let redirect_url = redirect_query_param.get("redirect_url")
            if (redirect_url)
              redirect_query_param.delete("redirect_url")
            else{
              redirect_url = redirect_query_param.get("redirect_uri")
              if (redirect_url)
                redirect_query_param.delete("redirect_uri")
              else{
                return
              }
            }
            if (window.parent&&isExtensionContext && redirect_url.includes("extension://")){
              let redirectUrl = new URL(redirect_url)            
              window.parent.postMessage(Object.fromEntries(redirect_query_param.entries()),redirectUrl.origin)
            }
            
            window.location.href = (redirect_url+"?"+redirect_query_param.toString())
          }
          else{
            setError("Error getting access token")
            console.error(res)
          }
          
        })

      })
    }
  }

  useEffect(() => {
    if(error){
      setIsSigning(false)
    }
  },[error])


  const login = (auth = undefined, onError?: () => any) => {
    if (!email) {
      setError("Please fill up your email")
      return
    }
    if (!password) {
      setError("Please fill up your password")
      return
    }
    const _auth = auth || getAuth(firebaseApp);
    setError("")
    return signInWithEmailAndPassword(_auth, email, password).then(userCredential => {

      return getApi().getMyAccount().then((res) => {
        setCurrentUser(userCredential.user)
        
        if (searchParams.get("response_type")!=="code" && !isExtensionContext){
          navigate("/welcome")
        }
        else{
          handleResponseTypeUrlParams(userCredential.user)
          
        }
      })
        .catch((err) => {
          setError("Account has't been activated properly. Please contact support (support@easypie.ai).")
          onError && onError()
        })

    }).catch(error => {
      setIsSigning(false)
      setError(error.message.replace("Firebase: ", ""))
    })
  }


  const activateAccount = (user) => getApi().signup({
    email,
    invite_id: inviteInfo?.invite_id,
    account_id: user.uid,
  }).then(() => {
    setCurrentUser(user)
    const followup = searchParams.get("followup")
    navigate(followup || "/welcome")
  })
    .catch((error) => {
      setError(error.message)
    })
    

  const handleEmailRegister = async (event: React.FormEvent<HTMLFormElement> = undefined,) => {
    if (!agreeWithTerms){
      setError("Please agree with our terms and privacy policy")
      return
    }
    if (!email) {
      setError("Please fill up your email")
      return
    }
    if (!password) {
      setError("Please fill up your password")
      return
    }
    if (mode !== "login" && VERIFICATION_CODE_REQUIRED && !inviteInfo?.invite_id) {
      setError("Please enter your invite code")
      return
    }

    event?.preventDefault();
    

    const auth = getAuth(firebaseApp);
    if (mode == "login") {
      login().catch(error => {
        setError(error.message.replace("Firebase: ", ""))
        if (error.code = "auth/wrong-password") {
          setWrongPassword(true)
        }
      })
    }
    else {
      if (!password2) {
        setError("Please re enter your password")
        return
      }
      if (password !== password2) {
        setError("Passwords do not match")
        return
      }
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          activateAccount(userCredential.user)
        })
        .catch((error) => {
          const errorMessage = error.message;
          if ("auth/email-already-in-use") {
            login(auth, () => {
              signInWithEmailAndPassword(auth, email, password).then(userCredential => {
                activateAccount(userCredential.user)
              }
              ).catch(error => {
                setError(error.message.replace("Firebase: ", ""))
              })
            }
            )

          }
        });
    }
  }



  return (
    <Box className='main' >

      <Flex direction="row" width="100%" justify="stretch" align="center" flexWrap="wrap" minH="90vh" overflow="hidden">
        <Flex
          p={["10px","40px"]}
          align={'center'}
          justify={'center'}
        >
          <Stack spacing={2} mx={'auto'} maxW={'lg'} py={2} px={6} boxShadow={'2xl'} rounded="lg" borderWidth="1px"
            background="white"
            alignSelf='center'
            width={["95vw","85vw","500px"]}
            zIndex={1}
          >
            <Stack align={'center'}>

              <Heading fontSize={'4xl'}>{mode == "login" ? "Login in to your account" : "Create new account"}</Heading>
              {mode == "signup" && <Text fontSize={'lg'} color={'gray.600'}>
                sign up to enjoy all of our cool features
                {/* <Link color={'blue.400'} >features</Link> ✌️ */}
              </Text>}
            </Stack>
            {isTestingInvite?(
              <Stack height="30vw" width="30vw" align="center" justify="center">

                <Spinner size="xl" />
              </Stack>
            ):(
              <Box
                rounded={'lg'}
                p={[1,8]}>
                  
                <Stack spacing={2} >
                  
                  <form onSubmit={(e) => {
                    e.preventDefault()
                    setIsSigning(true)
                    mode == "login" ? login() : handleEmailRegister()
                  }}>
                    <FormControl id="email">
                      <FormLabel>Email address</FormLabel>
                      <Input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                    </FormControl>
                    <FormControl id="password">
                      <FormLabel>Password</FormLabel>
                      <Input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                    </FormControl>

                    {mode == "signup" && (

                      <FormControl id="password2">
                        <FormLabel>Repeat password</FormLabel>
                        <Input type="password" value={password2} onChange={e => setPassword2(e.target.value)} />
                      </FormControl>
                    )}
                    {mode == "signup" && verificationCode !== undefined && (

                      <FormControl id="verification_code">
                        <FormLabel>Invite verification code</FormLabel>
                        {!inviteInfo ? <HStack spacing={2} justify="center">
                          <PinInput value={verificationCode} onChange={setVerificationCode} >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                         
                          
                        </HStack>:(
                          <Stack rounded={5} background="blue.100" p="5px" align="center">
                            <Text>
                              Sign up using invite from <b>{inviteInfo?.sent_by}</b>
                            </Text>
                            <Text>
                              {inviteInfo?.join_tenant?"to join his organization " + (inviteInfo.tenant||""):""}
                            </Text>
                            <Button size="xs" onClick={()=>{
                              setSearchParams({})
                              setInviteInfo(undefined)
                            }}>Cancel</Button>
                          </Stack>
                        )}
                      </FormControl>
                    )}
                     {VERIFICATION_CODE_REQUIRED &&!inviteInfo && <Box p="10px 0px">
                            <Text fontSize="xs">Unfortunately access to our platform is currently only via invite,</Text>
                            
                            <Text  fontSize="xs"> please sign up for our <Link color="pink.700" _hover={{textDecoration:"underline"}} href="https://www.easypie.ai/#join"> 👉 waiting list 👈</Link> if you don't have one yet</Text>
                            
                            
                          </Box>}
                    <Stack spacing={0} m="10px 0px">
                      <Stack
                        direction={{ base: 'column', sm: 'row' }}
                        align={'start'}
                        justify={'space-between'}>


                      </Stack>
                      
                      <VStack spacing={2} align="stretch">
                      {inviteInfo && inviteInfo.tenant &&   <Stack align="center" backgroundColor="blue" m="0px 10px" rounded={5}>
                          <Text color="blue.700"> Join {inviteInfo.tenant } organization</Text>
                        </Stack>  
                      }
                      {mode == "signup" &&(
                        <HStack>
                            <Checkbox size="lg" colorScheme="brand" checked={agreeWithTerms} onChange={e=>setAggreeWithTerms(e.target.checked)} />
                            <Text>I have read and agree to the <Link textDecoration="underline" isExternal href='https://www.easypie.ai/docs/privacy_policy'>privacy policy</Link> and <Link textDecoration="underline" isExternal href='https://www.easypie.ai/docs/privacy_policy'>terms of service</Link></Text>
                          </HStack>
                        )}
                        {error &&   <Stack align="center" backgroundColor="pink" m="0px 10px" rounded={5}>
                            <Text color="red.700">{error}</Text>
                          </Stack>  
                        }
                        <Button
                          colorScheme={'brand'}
                          type="submit"
                          isLoading={isSigning}
                          //onClick={() => mode == "login" ? login() : handleEmailRegister()}
                        >
                          {mode == "login" ? "Continue" : "Sign up"}
                        </Button>

                        <Button
                          colorScheme={'brand'} variant="outline"
                          onClick={() => {
                            handleRegisterWithProvider(GoogleAuthProvider)
                          }}
                          leftIcon={<FcGoogle />}
                        >

                          {mode == "login" ? "Login with Google" : "Sign up with Google"}
                        </Button>
                        
                        {mode == "signup" && verificationCode === undefined && <Link as={Text} alignSelf="center" color={'gray.400'} fontSize="xs" onClick={() => setVerificationCode("")} >Have an invite?</Link>}
                        {mode == "login" && <Link as={ReactRouterLink} to="/password-reset" alignSelf="center" color={'blue.400'}  >Forgot password?</Link>}
                      </VStack>
                    </Stack>
                  </form>
                </Stack>
              </Box>
            )}
            {mode == "login" ? <Link as={ReactRouterLink} to="/signup" alignSelf="center" color={'blue.400'}  >Don't have an account? Sign up</Link> :
              <Link as={ReactRouterLink} to="/login" alignSelf="center" color={'blue.400'}  >Already have an account? Login</Link>}
          </Stack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LoginComponent;