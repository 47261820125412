import { useState } from "react"
import { MessageWithContact } from "./dashboardModel"
import { Stack, HStack, Checkbox, Text, Button } from "@chakra-ui/react"
import MessageCard from "./messageCard"

const MessageList = ({ messages, bulkOperation: bulkOperation, onSelectionChanged, onMessageClicked }: {
    messages: MessageWithContact[],
    bulkOperation?: { label: string, icon: any, onClick: (messages: { message: { id: string } }[]) => Promise<any> }[],
    onSelectionChanged?: (val: boolean) => any
    onMessageClicked?: (message: MessageWithContact) => any
}) => {
    const [selection, setSelection] = useState<MessageWithContact[]>([])
    const [runningAction, setRunningAction] = useState<string>()
    return <Stack maxH="75vh" overflow="auto">
        {bulkOperation && <HStack width="100%" justify="space-between">
            <HStack >

                {bulkOperation && <Checkbox colorScheme="brand" isChecked={selection?.length == messages?.length} onChange={() => {
                    setSelection(!selection?.length ? messages : [])
                }} />}
                {selection?.length && <Text fontWeight={500} fontSize="xs">{selection?.length} selected</Text>}
            </HStack>
            <HStack justifySelf="flex-end">
                {selection?.length && bulkOperation.map((operation) => <Button
                    isLoading={runningAction == operation.label}
                    colorScheme="brand" variant="outline" size="xs" onClick={() => {
                        setRunningAction(operation.label)

                        setRunningAction(operation.label)
                        operation.onClick(selection).then(() => {
                            setSelection([])
                        }).finally(() => {
                            setRunningAction(undefined)

                        })

                    }}>{operation.icon} {operation.label}</Button>)}
            </HStack>
        </HStack>}

        {messages?.map((message) => <MessageCard message={message} checkbox={!!bulkOperation}

            isChecked={selection?.includes(message)}
            onClick={() => {
                onMessageClicked && onMessageClicked(message)
            }}
            onCheckedChanged={(val) => {
                if (val) {
                    setSelection([...(selection || []), message])
                }
                else {
                    setSelection(selection?.length ? selection.filter((item) => item != message) : null)
                }
            }} />)}
    </Stack>
}

export default MessageList