import useSWR, { SWRResponse } from 'swr';
import { getApi } from '../apiService';
import { useState } from 'react';
import { useToast } from '@chakra-ui/react';





export const useApiFetch = <T>(path: string,options?:{ 
  method?:"GET"|"POST"|"PATCH"|"DELETE", 
  queryArgs?: Record<string, any>, 
  body?:any, 
  redirectOnUnauthorized?:boolean,
  static?:boolean,
  swrOptions?:{
    refreshInterval?:number,
    revalidateIfStale?:boolean,
    revalidateOnFocus?: boolean,
    revalidateOnReconnect?: boolean,
    revalidateOnMount?: boolean,
    [key:string]:any
  }, 
  onError?:(error:any)=>any,
  shouldFetch?:boolean,
  mapData?:(data:any)=>T
}): SWRResponse<T, any>  => {
  const api = getApi();
  let swrOptionsPresets={} as any
  if (options?.shouldFetch!==undefined){
    //swrOptionsPresets.isPaused=()=>!options.shouldFetch
  }
  if (options?.static){
    swrOptionsPresets={
      revalidateOnFocus:false,
      revalidateOnMount:true,
      revalidateOnReconnect:false,
      refreshInterval:99999
    }
  }

  let queryArgs =  options?.queryArgs? Object.entries(options?.queryArgs).filter(([_, value]) => value !== undefined && value!=="" && value!==null):undefined
  const url = `${api.baseUrl}${path}`+(queryArgs?`?${new URLSearchParams(queryArgs).toString()}`:"")
  const swrResponse = useSWR(`${options?.shouldFetch}:::${url}`, () =>{
    return (options?.shouldFetch===undefined || options?.shouldFetch) ?api.authorize().then(headers =>{ 

      return fetch(url, {
      method: options?.method||"GET",
      body: options?.body?JSON.stringify(options.body):undefined,
      headers: {
        ...headers,
        "Content-Type": "application/json",
        
      }
    }).then(res=>{
      if (res.status<300){
        return res.json()
      }
      else{
        if (res.status===401 && options?.redirectOnUnauthorized!==false){
          window.location.href = "/login"
        }

        throw {status:res.status,text:res.statusText}
      }
    }).then((data) => {
      return options?.mapData?options?.mapData(data):data
    })
    .catch((error) => {
      if (options?.onError){
        options?.onError(error)
      }
      throw error
    
    })}):(
       undefined
    )
  },{...swrOptionsPresets,...(options?.swrOptions||{})})
  


        return swrResponse
};

interface ApiEndpoint<T>{
  execute: (pathArgs?:Record<string,any>, body?:any, queryArgs?:Record<string,any>) => Promise<any>,
  invoke: (options:{pathArgs?:Record<string,any>, body?:any, queryArgs?:Record<string,any>}) => Promise<any>,
  isRunning: boolean,
  error: any
  runningArgs: Record<string,any>

}


export const useApiEndpoint = <T>( 
    method:"GET"|"POST"|"PATCH"|"DELETE",
    pathTemplate?: string, 
    showErrorToast:(boolean|((args)=>string)) = true,
    raiseErrors?:boolean, 
    options?:{outputType:"json"|"text"|"blob"

    }
  ):ApiEndpoint<T>  => {
  const api = getApi();
  const [isRunning, setIsRunning] = useState(false);
  const [runningArgs, setRunningArgs] = useState<Record<string,any>>();
  const [error, setError] = useState(false);
  const toast = useToast()
  
  
    function interpolateTemplate(template: string, args: Record<string, any>) {
      if (!args) {
        return template;
      }
      let interpolatedTemplate = template;
      Object.keys(args).forEach((arg) => {
        const regex = new RegExp(`\{${arg}\}`, 'g');
        interpolatedTemplate = interpolatedTemplate.replace(regex, args[arg]);
      });
      return interpolatedTemplate;
    }
  
  const execute = (pathArgs:Record<string,any>={}, body?:any, queryArgs?:Record<string,any>) =>
  {
    return invoke({pathArgs,body,queryArgs})
  }
  
  const invoke = ({
    pathArgs={},
    body,
    queryArgs,
    
  
  }:{pathArgs:Record<string,any>, body?:any, queryArgs?:Record<string,any>}) =>{
    let path = interpolateTemplate(pathTemplate||"", pathArgs)
    let _queryArgs = queryArgs ? Object.entries(queryArgs).reduce((acc, [key, value]) => {
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {}):undefined
    let url = `${api.baseUrl}${path}`+(_queryArgs?`?${new URLSearchParams(_queryArgs).toString()}`:"")
    setRunningArgs({url,...pathArgs,...queryArgs})
    setError(undefined)
    setIsRunning(true)
    let _body=body
    if (body instanceof FormData){
      _body=body
    }
    else if (body && typeof body==="object"){
      _body=JSON.stringify(body)
    }

    return api.authorize().then(headers => fetch(url, {
      method: method,
      body: _body,
      
      headers:  (body instanceof FormData)?headers:{
        ...headers,
        "Content-Type": "application/json",
        
      }
    })).then(res=>{
      setRunningArgs(undefined)
      setIsRunning(false)
      if (res.status<300){     
        if (options?.outputType==="blob")
          return res.blob() as T
        else if (options?.outputType==="text")
          return res.text() as T
        else{
          let _res = res.json() as any
          
          return _res as T

        }


      }
      else{
        throw {status:res.status,text:res.statusText+"\n"+res.text()}
      }
    }).then((data:any) => {
      if (data && typeof(data?.error)==="string" && showErrorToast){
        toast({
          title: "Error",
          description: data.error ,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        
      }
      return data
    })
    .catch((error) => {
      setError(error)
      setRunningArgs(undefined)
      setIsRunning(false)
      let errorMessage = "An error occurred while calling the server"
      if (showErrorToast && typeof showErrorToast === "function"){
        errorMessage = showErrorToast({url,...pathArgs,...queryArgs})
      }
      if (showErrorToast){
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
      if (raiseErrors){

        throw Error(error?.text)
      }
      return error
    })
  }
  


        return {
          execute,
          invoke,
          isRunning,
          error,
          runningArgs
        }
};
