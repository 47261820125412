import { Box, Text, Stack, Tag } from "@chakra-ui/react";
import { CampaignIdeaWithDetails } from "./dashboardModel";
import { MarkdownContent, GeniouslyThemeProvider } from "geniously-chat-ui"
import ExpandableTextSection from "../ExpandableSection/expandableTextSection";
import { CampaignStatsWidget } from "../Campaigns/campaignStatsWidgets";
interface CampaignCardProps {
    campaign: CampaignIdeaWithDetails;
    onClick?: () => void;
}

const CampaignIdeaCard: React.FC<CampaignCardProps> = ({ campaign, onClick }) => {
    // Aggregate campaign stats from campaigns array
    const stats = campaign.campaigns?.reduce((acc, cur) => ({
        contacts_count: acc.contacts_count + cur.contacts_count,
        contacted: acc.contacted + cur.contacted,
        canceled: acc.canceled + cur.canceled,
        accepted_invite: acc.accepted_invite + cur.accepted_invite,
        replied: acc.replied + cur.replied,
    }), { contacts_count: 0, contacted: 0, canceled: 0, accepted_invite: 0, replied: 0 }) || { contacts_count: 0, contacted: 0, canceled: 0, accepted_invite: 0, replied: 0 };

    return <Box
        borderWidth="1px"
        borderRadius="lg"
        p={4}
        background="white"
        cursor={onClick ? "pointer" : "default"}
        onClick={onClick}
        shadow="md"
        _hover={onClick ? { shadow: "lg" } : undefined}
    >
        <Stack spacing={3} align="start">
            <Text fontSize="xl" fontWeight="bold">
                {campaign.campaign_idea.name}
            </Text>
            <Text fontSize="sm" fontWeight="500">
                <ExpandableTextSection maxHeight="3em">
                    <GeniouslyThemeProvider>

                        <MarkdownContent>
                            {campaign.campaign_idea.description}

                        </MarkdownContent>
                    </GeniouslyThemeProvider>
                </ExpandableTextSection>
            </Text>
            <Tag
                alignSelf="start"
                colorScheme={campaign.campaign_idea.status === "running" ? 'green' : 'gray'}>
                {campaign.campaign_idea.status}
            </Tag>
            {campaign.campaigns?.length && <CampaignStatsWidget stats={stats} size="md" />}
        </Stack>
    </Box>

}


export default CampaignIdeaCard;