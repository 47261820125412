

import { useEffect, useState } from "react";



import {
  Tabs, TabList, DrawerBody,
  DrawerFooter, TabPanels, Tab, TabPanel, Tag, HStack, Checkbox, Button, Link, Tooltip, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader,
  Spinner
} from '@chakra-ui/react'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { Box, Grid, GridItem, Stack, Text } from "@chakra-ui/react";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconBrandLinkedin, IconSquareRoundedCheckFilled, IconX } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import { ContactInbox } from "../components/Inbox/inbox";
import { IconBulb } from "@tabler/icons-react";
import { CampaignIdeaWithDetails, MessageWithContact, PagedMessages } from "../components/HomeDashboard/dashboardModel";
import MessageList from "../components/HomeDashboard/messageList";
import CampaignIdeaCard from "../components/HomeDashboard/campaignIdeaCard";
import { IconCopyPlus } from "@tabler/icons-react";
import CampaignIdeaDetail from "../components/HomeDashboard/campaignIdeaDetail";



export default function HomePage() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])

  const pendingVerification = useApiFetch<PagedMessages>("/inbox/messages/pending-verification", { swrOptions: { refreshInterval: 3 * 60 * 1000, revalidateOnFocus: false } })
  const receivedMessages = useApiFetch<MessageWithContact[]>("/messages/recent-received", { swrOptions: { refreshInterval: 3 * 60 * 1000, revalidateOnFocus: false } })
  const proposedIdeas = useApiFetch<CampaignIdeaWithDetails[]>("/home/campaign/ideas?status=proposal", { static: true })
  const activeCampaigns = useApiFetch<CampaignIdeaWithDetails[]>("/home/campaign/ideas?status=running&status=preparing&status=finished", { static: true })
  const scheduledMessages = useApiFetch<PagedMessages>("/inbox/messages/scheduled?next_n_hours=24", { swrOptions: { refreshInterval: 3 * 60 * 1000, revalidateOnFocus: false } })
  const sentMessages = useApiFetch<PagedMessages>("/inbox/messages/sent?last_n_hours=24", { swrOptions: { refreshInterval: 3 * 60 * 1000, revalidateOnFocus: false } })

  const patchEndpoint = useApiEndpoint("POST", "/inbox/messages/actions")

  const [modal, setModal] = useState<any>();

  const addCampaignIdea = () => {
    navigate("/flows/start-campaign")
  }

  const openDrawer = (content: any, header: string = null) => {

    setModal(<DetailDrawer onClose={() => {
      setModal(null)
    }} header={header || "Details"}>
      {content}
    </DetailDrawer>)
  }

  const openCampaignIdeaDetail = (campaignIdea: any) => {

    setModal(<DetailDrawer onClose={() => {

      setModal(null);
    }} header={<HStack><IconBulb /><Text>Campaign idea</Text></HStack>}>
      <CampaignIdeaDetail campaign={campaignIdea} />
    </DetailDrawer>);
  }



    return (
      <Stack overflow="auto" h='calc(100% - 40px)' w="calc(100% - 10px)" p="10px" className="homePage" background="dark-1">
        {modal}
        <Grid
          h='100%'
          templateRows='repeat(2, 1fr)'
          templateColumns='repeat(3, 1fr)'
          gap={4}
        >
          <GridItem rowSpan={2} colSpan={2}  >

            <Tabs colorScheme="brand">
              <TabList>
                {pendingVerification.data?.total > 0 && <Tab>Pending verification {pendingVerification.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{pendingVerification.data?.total}</Tag>}</Tab>}

                <Tab>Requires attention </Tab>

                <Tab>Scheduled messages  {scheduledMessages.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{scheduledMessages.data?.total}</Tag>}</Tab>
                <Tab>Sent recently  {sentMessages.data?.total > 0 && <Tag colorScheme="brand" fontSize="xs" size="sm" m="2px">{sentMessages.data?.total}</Tag>}</Tab>
              </TabList>

              <TabPanels>
                {pendingVerification.data?.total > 0 && <TabPanel>
                  <Stack>

                    <MessageList messages={pendingVerification.data?.messages} bulkOperation={[
                      {
                        label: "Approve", icon: <IconCheck />, onClick: (messages) => patchEndpoint.execute({}, {
                          message_ids: messages.map((message) => message.message.id),
                          action: "verify"
                        }

                        ).then(() => {
                          pendingVerification.mutate()
                        })
                      },


                      {
                        label: "Reject", icon: <IconX size="20px" />, onClick: (messages) => patchEndpoint.execute({}, {
                          message_ids: messages.map((message) => message.message.id),
                          action: "reject"
                        }).then(() => {
                          pendingVerification.mutate()
                        })
                      },
                    ]}
                      onMessageClicked={(message) => {
                        openDrawer(<ContactInbox contactId={message.contact.id} onContactModified={() => pendingVerification.mutate()} />, "Conversation")
                      }}
                    />

                  </Stack>
                </TabPanel>}
                <TabPanel>
                  <Text fontSize="xs" >Received recently</Text>
                  <MessageList messages={receivedMessages.data}
                    onMessageClicked={(message) => {
                      openDrawer(<ContactInbox contactId={message.contact.id} onContactModified={() => receivedMessages.mutate()} />, "Conversation")
                    }}
                  />
                </TabPanel>


                <TabPanel>
                  <MessageList messages={scheduledMessages.data?.messages}
                    onMessageClicked={(message) => {
                      openDrawer(<ContactInbox contactId={message.contact.id} />, "Conversation")
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MessageList messages={sentMessages.data?.messages}
                    onMessageClicked={(message) => {
                      openDrawer(<ContactInbox contactId={message.contact.id} />)
                    }}
                  />

                </TabPanel>
              </TabPanels>
            </Tabs>


          </GridItem>
          {/* <GridItem rowSpan={2} colSpan={2} bg='red' /> */}
          <GridItem rowSpan={2} colSpan={1} >
            <Stack>
              <Button colorScheme="brand" mb="-40px" alignSelf="end" size="sm" leftIcon={<IconCopyPlus />} onClick={() => addCampaignIdea()}>Add new</Button>
              <Tabs colorScheme="brand" >
                <TabList>
                  {activeCampaigns.data?.length ? <Tab>Active Campaigns</Tab> : <></>}
                  <Tab><HStack><IconBulb /><Text>Campaign ideas </Text></HStack></Tab>
                </TabList>
                <TabPanels overflow="auto" maxH="calc(100vh - 150px)">
                  {activeCampaigns.data?.length ? (
                    <TabPanel overflow="auto">
                      <Stack spacing={3}>
                        {activeCampaigns.data?.map((campaign) => <CampaignIdeaCard campaign={campaign} onClick={() => openCampaignIdeaDetail(campaign)} />)}
                      </Stack>
                    </TabPanel>
                  ) : undefined}
                  <TabPanel overflow="auto">
                    {proposedIdeas.data?.length ? (
                      <Stack spacing={3}>
                        {proposedIdeas.data?.map((campaign) => <CampaignIdeaCard campaign={campaign} onClick={() => openCampaignIdeaDetail(campaign)} />)}
                      </Stack>
                    ) : (
                      <Stack align="center">
                        {proposedIdeas.isLoading ? <Spinner /> : (
                          <Stack align="center" p="20px" m="10px" backgroundColor="gray.100" borderRadius="8px" spacing={0}>

                            <IconBulb size="30px" />
                            <Text>No ideas yet... </Text>
                            <Text fontWeight={500}>... let's work on something together!</Text>
                            <Button colorScheme="brand" mt="8px" size="sm" leftIcon={<IconCopyPlus />} onClick={() => addCampaignIdea()}>Add campaign idea</Button>
                          </Stack>
                        )}
                      </Stack>

                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </GridItem>

        </Grid>
     
     
      </Stack>
    );
  }



interface DetailDrawerProps {

  placement?: 'right' | 'left' | 'top' | 'bottom';
  onClose: () => void;
  header?: string | React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

const DetailDrawer: React.FC<DetailDrawerProps> = ({

  placement = 'right',
  onClose,
  header,
  children,
  footer
}) => {
  return (
    <Drawer isOpen={true} placement={placement} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent w={["100vw", "100vw", "100vw", "50vw", "34vw"]} maxW={["100vw", "100vw", "100vw", "50vw", "34vw"]} >
        <DrawerCloseButton />
        {header && <DrawerHeader p="6px 10px">{header}</DrawerHeader>}
        <DrawerBody p="8px">
          {children}
        </DrawerBody>
        {footer ? (

          <DrawerFooter p="4px">
            {footer}
          </DrawerFooter>
        ) : (<></>
        )}
      </DrawerContent>
    </Drawer>
  );
}



