import { Box, Button, ButtonGroup, Checkbox, Flex, HStack, IconButton, Input, Link, Select, Spinner, Stack, Tag, TagCloseButton, TagLabel, Text, Tooltip, useToast, Wrap, WrapItem } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import GenericList from '../GenericList/genericList';
import { AutoUI, ChatContainer, ChatMessage, GeniouslyThemeProvider, AutoDatePicker, AutosizeTextArea } from 'geniously-chat-ui';
import { useApiEndpoint, useApiFetch } from '../../utils/useApiHook';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import { EditModal } from '../ModalDialogs/editModal';
import { IconArchive, IconBrandGmail, IconBrandLinkedin, IconChevronDown, IconClipboard, IconClipboardCheck, IconClock, IconEye, IconMail, IconMailExclamation, IconMailFilled, IconNote, IconPlus, IconRefresh, IconSend, IconSubtask } from '@tabler/icons-react';
import { ConfirmModal } from '../ModalDialogs/confirmModal';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteTag,
} from "@choc-ui/chakra-autocomplete";
import ContactCard from './ContactCard';
import { IconClockExclamation } from '@tabler/icons-react';
import { IconInbox } from '@tabler/icons-react';
import ContactDetail from './ContactDetail';
import CampaignsSelect from './CampaignSelect';

import { channel } from 'diagnostics_channel';
import { ActivityCard, ArchivedActivity } from './activitiesCards';


interface ContactActivityBase {
    id: string;
    contact_id: string;
    type: "message_out" | "message_in" | "event" | "note" | "task";
    timestamp: string;
    account?: string;
    text?: string;
    metadata: Record<string, any>;
    archived?: boolean
}

interface EventBase {
    id: string;
    contact_id: string;
    type: "event" | "note" | "task";
    event_type: string;
    timestamp: string;
    account?: string;
    text?: string;
    metadata: Record<string, any>;
    reminder?: string;
}

interface ExternalMessageBase extends ContactActivityBase {
    id: string;
    thread_id?: string;
    subject?: string;
    text?: string;  // to allow lazy generation
    attachments?: string[];
    has_been_read?: boolean;
    account: string;
    channel: "linkedin" | "email";

}

interface ExternalOutboundMessage extends ExternalMessageBase {
    type: "message_out";
    generation_info?: Record<string, any>;
    sent_at?: string;
    archived: boolean;
    archive_reason: string
    verification_status?: "pending" | "verified" | "rejected" | null;
    scheduled_send_time?: string;
    sending_error?: string;
    schedule_preconditions?: Record<string, any>;

}

function getChannelIcon(channel: string) {
    switch (channel) {
        case "linkedin":
            return <IconBrandLinkedin color="#5090FF" />
        case "email":
            return <IconMail />
    }
}

const ActivityDetail = ({ activity, onChange }: { activity: any, onChange: (val: any) => void }) => {
    if (activity.type == "message_out" || activity.type == "message_in") {
        const message = activity as ExternalOutboundMessage //the most specific type


        return <Stack>
            <AutoUI value={activity}
                schema={{
                    type: "object",
                    properties: {
                        type: {
                            type: "string",
                            title: "Channel",
                            enum: ["message_out", "message_in", "event", "note"]

                        },
                        channel: {
                            type: "string",
                            title: "Channel",


                        },
                        timestamp: {
                            type: "string",
                            title: "Timestamp",
                            format: "date-time"
                        },
                        text: {
                            type: "string",
                            title: "Text",
                            maxLength: 10000
                        },
                        event_type: {
                            type: "string",
                            title: "Event type",
                            showConditions: { type: "event" }

                        }

                    }
                }}
            />

        </Stack>

    }
}

const ScheduleDetail = ({ activity, lastReceivedMessage, onChange }: { activity: ExternalOutboundMessage, lastReceivedMessage?: ExternalMessageBase, onChange: (val: ExternalOutboundMessage) => void }) => {
    if (activity.type == "message_out" || activity.type == "message_in") {
        const message = activity as ExternalOutboundMessage //the most specific type
        const replyToLast = activity.thread_id == lastReceivedMessage?.thread_id && !!lastReceivedMessage?.thread_id;
        //const sendEndpoint = useApiEndpoint("POST", `/inbox/contacts/${activity.contact_id}/activities/{activityId}/send`, true, true)
        return <Stack >
            <HStack justify="space-between">

                <HStack >
                    <Text>From: </Text>
                    {getChannelIcon(activity.channel)}
                    <Text alignSelf="end" fontWeight={600} fontSize="xs">{activity.account}</Text>
                </HStack>
                {activity.channel == "email" && lastReceivedMessage?.channel == activity.channel && lastReceivedMessage?.thread_id && <Checkbox size="md" isChecked={replyToLast} onChange={(e) => {
                    onChange({ ...activity, thread_id: e.target.checked ? lastReceivedMessage?.thread_id : undefined, subject: null })
                }}>Reply to last email message</Checkbox>}
            </HStack>
            <GeniouslyThemeProvider>
                {activity.channel == "email" && <HStack>
                    <Text fontSize="sm" fontWeight={600}>Subject</Text>
                    <Input size="sm" borderColor="gray.500" value={replyToLast ? "RE: " + lastReceivedMessage.subject : activity.subject}
                        isReadOnly={replyToLast}
                        backgroundColor={replyToLast ? "gray.100" : undefined}
                        onChange={(e) => {
                            onChange({ ...activity, subject: e.target.value })
                        }} />
                </HStack>}
                <AutosizeTextArea minH="6em" borderColor="gray.500" value={activity.text} onApply={(val) => {
                    onChange({ ...activity, text: val })
                }} />

                <HStack justify="space-between">
                    <HStack spacing={2}>
                        <Text fontSize="sm" fontWeight={600}>
                            Scheduled send
                        </Text>

                        <AutoDatePicker borderColor="gray.500" value={message.scheduled_send_time} onApply={(val) => {
                            onChange({ ...message, scheduled_send_time: val })
                        }} />
                    </HStack>
                    {/* <Button size="xs" justifySelf="end" variant="outline" colorScheme="brand" onClick={() => {
                        onChange({ ...message, scheduled_send_time: undefined })
                    }} leftIcon={<IconSend size="15px" />}>Send now</Button> */}
                </HStack>
            </GeniouslyThemeProvider>
        </Stack>

    }
}



const InboxPage = () => {
    const navigate = useNavigate();
    const settingsEndpoint = useApiFetch<{ contact_statuses: { id: string, name: string }[] }>("/org/settings", { static: true, swrOptions: { refreshInterval: 9999999, revalidateIfStale: false, revalidateOnFocus: false, revalidateOnMount: true } });
    const [modal, setModal] = React.useState<JSX.Element>()
    const toast = useToast()
    const createContactEndpoint = useApiEndpoint("POST", `/inbox/contacts`, true, false)
    const getAccountsRequest = useApiFetch<{ name: string }[]>(`/inbox/accounts`, { static: true })
    const { contactId } = useParams()
    const listRef = useRef();
    const [companyFilter, setCompanyFilter] = useState<string>()
    return (
        <HStack align="stretch" justify="stretch" height="calc(100% - 5px)" maxW={["100vh", "100vw", "calc(100vw - 40px)"]} width="100%">
            {modal}
            <GenericList ref={listRef} collapsible endpointPath='/inbox/contacts' listTitle='Contacts' primaryField='full_name' secondaryFields={["last_activity", "headline", "position", "company"]}
                selectedId={contactId}
                fetchItemsMaxCap={100}
                customCardRenderer={(item, index, selected) => {
                    return <ContactCard key={index} color={item.waiting_for_activity ? "#e00f0f" : undefined} backgroundColor={selected ? "#eaeaea" : undefined} contact={item} />
                }}
                onSelected={(item) => {
                    navigate({
                        pathname: `/inbox/${item.id}`,
                        search: window.location.search
                    })
                }}
                extraFilterRender={(extraFilterValue, setExtraFilterValue) => {
                    return <Stack fontSize="xs" fontWeight="500" spacing={1}>
                        <CampaignsSelect campaignId={extraFilterValue.campaign} onChange={(val) => {
                            setExtraFilterValue({ campaign: val?.id })
                        }} />
                        <FilterSelect label="Status"
                            value={extraFilterValue?.status}
                            onChange={(val) => setExtraFilterValue({ status: val })}
                            options={settingsEndpoint.data?.contact_statuses?.length ? settingsEndpoint.data?.contact_statuses?.reduce((dict, status) => { dict[status.id] = status.name; return dict; }, {}) : []} />
                        <FilterSelect label="Recent activity type"
                            value={extraFilterValue?.recent_activity_type}
                            onChange={(val) => setExtraFilterValue({ recent_activity_type: val })}
                            options={{
                                "message_in": "Message received",
                                "message_out": "Message send",
                                "linkedin:connection_accepted": "LinkedIn connection accepted",
                                "linkedin:work_anniversary": "LinkedIn Work anniversary",
                                "linkedin:birthday": "LinkedIn Birthday",
                                "linkedin:job_change": "LinkedIn Job change"
                            }} />
                        <FilterSelect label="Account"
                            value={extraFilterValue?.account}
                            onChange={(val) => setExtraFilterValue({ account: val })}
                            options={getAccountsRequest.data?.reduce((dict, a) => { dict[a.name] = a.name; return dict; }, {}) || {}} />
                        <Input size="sm" placeholder="Company name" value={companyFilter || extraFilterValue?.company}
                            onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                    setCompanyFilter(undefined)
                                    setExtraFilterValue({ company: (e.target as any).value })
                                }
                            }}
                            onChange={e => {
                                setCompanyFilter(e.target.value)
                                if (!e.target.value) {
                                    setExtraFilterValue({ company: undefined })
                                }
                            }
                            } onBlur={(e) => {
                                setCompanyFilter(undefined)
                                setExtraFilterValue({ company: e.target.value })
                            }} />

                        <Checkbox size="sm" isChecked={extraFilterValue?.requires_attention} onChange={(e) => setExtraFilterValue({ requires_attention: e.target.checked ? true : undefined })} >Requires attention</Checkbox>

                    </Stack>
                }}
                onClickNew={() => {
                    return new Promise((resolve, reject) => {
                        setModal(<EditModal
                            caption="Add new contact"
                            value={{}}
                            withFullscreenSwitch={false}
                            onOk={(val) => {
                                return createContactEndpoint.execute({}, val).then((res) => {
                                    setModal(undefined)
                                    resolve(undefined)
                                }).catch(e => {
                                    toast({
                                        title: "Error",
                                        description: e.text,
                                        status: "error",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                })
                            }}
                            onCancel={() => {
                                setModal(undefined)
                            }}>
                            {(val, setVal) => {
                                return <ContactDetail contact={val} onChange={setVal} />
                            }}
                        </EditModal>)
                    })
                }}
            />

            {<ContactInbox contactId={contactId} onContactModified={() => (listRef.current as any)?.refresh()} />}
        </HStack>
    );
};



const ContactInbox = ({ contactId: contactIdPreset, onContactModified }: { contactId?: string, onContactModified?: () => void }) => {

    const messages: ChatMessage[] = [
        {
            message: "Hello",
            type: "chat-request",
            timestamp: "2021-09-01T12:00:00Z",
            hidden: true
        }
    ]
    const { contactId: contactIdFromUrl } = useParams()
    const contactId = contactIdPreset || contactIdFromUrl;
    const [modal, setModal] = React.useState<JSX.Element>()

    const activitiesRequest = useApiFetch<ContactActivityBase[]>(`/inbox/contacts/${contactId}/activities`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })

    function refresh() {
        activitiesRequest.mutate()
        onContactModified && onContactModified()
    }
    const contactDetails = useApiFetch<{ campaign_name: string, campaign_id: string, campaign_state: string, waiting_for_activity?: boolean }>(`/inbox/contacts/${contactId}/details`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })
    const contactRequest = useApiFetch<any>(`/inbox/contacts/${contactId}`, { swrOptions: { revalidateOnFocus: false }, shouldFetch: !!contactId })
    const patchEndpoint = useApiEndpoint("PATCH", `/inbox/contacts/${contactId}/activities/{activityId}`, true, true)
    const sendEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/send`, true, false)
    const refreshEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/refresh`, true, false)
    const postActivityEndpoint = useApiEndpoint("POST", `/inbox/contacts/${contactId}/activities`, true, true)

    const bulkArchiveEndpoint = useApiEndpoint("DELETE", "/inbox/contacts/activities/messages/bulk_archive_scheduled", true, true)
    const [currentChanelAccount, setCurrentChannelAccount] = React.useState<{ channel: string, account: string }>()
    const [showContactDetails, setShowContactDetails] = useState<boolean>()
    const markHandledEndpoint = useApiEndpoint("PATCH", `/inbox/contacts/${contactId}/mark-as-handled`, true)

    const lastReceivedMessage = useMemo(() => {
        if (activitiesRequest?.data) {

            let received = [...activitiesRequest?.data.filter(m => m.type == "message_in")]
            received.reverse()
            return received[0]
        }
    }, [activitiesRequest.data])

    // const lastReceivedMessage = useMemo(() => {
    //     if (activitiesRequest?.data && currentChanelAccount) {

    //         let received = [...activitiesRequest?.data.filter(m => m.type == "message_in")]
    //         received.reverse()
    //         return received.find(m => (m as any).channel == currentChanelAccount?.channel && m.account == currentChanelAccount?.account)
    //     }
    // }, [activitiesRequest.data, currentChanelAccount])

    const channels = [
        { id: "linkedin", label: "LinkedIn", icon: <IconBrandLinkedin color="#5090FF" /> },

        { id: "email", label: "Email", icon: <IconMail color="#5090FF" /> }
    ]
    const getChannelInfo = (channel: string) => {
        return channels.find(c => c.id == channel) || { label: channel, icon: <IconInbox /> }
    }
    //const accounts = useMemo(() => { activitiesRequest.data?.map(a => a.account) }, [activitiesRequest.data])
    const [fetchAvailableAccounts, setFetchAvailableAccounts] = React.useState(false)
    useEffect(() => {
        setFetchAvailableAccounts(false)
    }, [contactId])

    const settingsEndpoint = useApiFetch<{ contact_statuses: { id: string, name: string }[] }>("/org/settings", { static: true, swrOptions: { refreshInterval: 9999999, revalidateIfStale: false, revalidateOnFocus: false, revalidateOnMount: true } })
    const getAvailableAccountOptionsEndpoint = useApiFetch<{
        channel: string,
        account: string,
        timestamp: string
    }[]>(`/inbox/contacts/${contactId}/channels`, { swrOptions: { revalidateOnFocus: false, revalidateOnReconnect: false, revalidateIfStale: false, refreshInterval: 9999999 }, shouldFetch: !!contactId && fetchAvailableAccounts })

    const [allChanelAccountOptions, setAllChanelAccountOptions] = React.useState<{ channel: string, account: string }[]>()
    useEffect(() => {
        if (activitiesRequest.data) {
            let activities = activitiesRequest.data.filter((a: any) => a.account && a.channel && !a.archived) as any[]
            let deduplicated: any = {}
            activities.forEach(a => {
                deduplicated[a.channel + a.account] = a
            })
            let options = Object.values(deduplicated).map((a: any) => ({ channel: a.channel, account: a.account }))
            if (activities && ((allChanelAccountOptions || JSON.stringify(allChanelAccountOptions) != JSON.stringify(options)))) {
                setAllChanelAccountOptions(options)
                if (activities.length > 0) {
                    let iLast = activities.length - 1
                    setCurrentChannelAccount({
                        channel: activities[iLast].channel, account: activities[iLast].account
                    })
                }

            }
        }


    }, [activitiesRequest.data])


    const toast = useToast()

    const isEmail = useMemo(() => ["email", "imap", "gmail", "outlook"].includes(currentChanelAccount?.channel), [currentChanelAccount])
    const [replyTo, setReplyTo] = useState<{ thread_id?: string, subject?: string }>()
    useEffect(() => {
        if (currentChanelAccount && activitiesRequest.data) {

            let lastMessage = activitiesRequest.data.slice().reverse()?.filter(a => (a as any).channel == currentChanelAccount?.channel)[0] as any
            setReplyTo({ thread_id: lastMessage?.thread_id, subject: (lastMessage?.subject && !lastMessage?.subject.startsWith("RE:") ? "RE:" + lastMessage?.subject : lastMessage?.subject) })
        }
    }, [currentChanelAccount])

    function sentMessage(message: string) {
        if (!currentChanelAccount) {
            toast({
                title: "No account selected",
                description: "Please select account (bottom right corner) to send message",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
        let extra = {}
        if (isEmail) {
            extra = {
                thread_id: replyTo?.thread_id,
                subject: replyTo?.subject
            }
        }

        return sendEndpoint.execute({}, { text: message, ...currentChanelAccount, ...extra }).then(() => {
            refresh()
        })
    }
    const navigate = useNavigate()
    const activities = useMemo<(any)[]>(() => {
        if (activitiesRequest.data) {
            return activitiesRequest.data.map(activity => {
                if (activity.archived) {
                    return <ArchivedActivity activity={activity as any} onRestore={() => {
                        let payload: any = {
                            archived: false
                        }
                        if (activity.type == "message_out" && (activity as any).scheduled_send_time) {
                            let message = activity as ExternalOutboundMessage
                            payload.scheduled_send_time = (new Date()).toISOString()
                            payload.schedule_preconditions = {
                                ...(message.schedule_preconditions || {}),
                                last_received_id: lastReceivedMessage?.id || message.schedule_preconditions?.last_received_id
                            }
                        }
                        return patchEndpoint.execute({ activityId: activity.id }, payload).then(() => {
                            refresh()
                        })
                    }} />
                }
                else if (activity.type == "message_out" || activity.type == "message_in") {
                    const message = activity as ExternalOutboundMessage //the most specific type


                        return {
                            message: activity.text,
                            type: activity.type == "message_out" ? "chat-request" : "chat-response",
                            timestamp: message.sent_at || activity.timestamp,
                            hidden: ((message.scheduled_send_time || (message.verification_status && message.verification_status != "verified")) && !message.sent_at),
                            original_message: message,
                            _extra: {
                                before: <Stack p="0px 30px" spacing={0}> <HStack >
                                    {message.sending_error && !message.sent_at && <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        {"❌ Error "}</Text>
                                    }
                                    {message.verification_status && (
                                        <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                            {message.verification_status == "pending" ? "☑️ Pending verification" : message.verification_status == "rejected" ? "❌ Rejected " : "✅ Verified "}
                                        </Text>
                                    )}
                                    {message.sent_at ? <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        {"Sent "}
                                        <Moment fromNow>{message.sent_at}</Moment>
                                    </Text> : <></>}

                                    {message.scheduled_send_time && !message.sent_at ?
                                        <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                            {message.sending_error ? "🔁 Retry " : "⏱️ Scheduled "}
                                            <Moment fromNow>{message.scheduled_send_time}</Moment>
                                        </Text>
                                        : <></>}
                                    {message.has_been_read ? <Tag
                                        borderRadius='full'
                                        colorScheme="gray"

                                        size="xs"
                                        p="0px 4px"

                                    >
                                        <IconEye size="18px" color="gray" />
                                        <TagLabel ml="4px" color="gray" fontSize="xs">
                                            {typeof (message.has_been_read) == "boolean" ?
                                                <Text>Seen</Text> : (
                                                    <Tooltip
                                                        label={
                                                            <Text><Moment>{message.has_been_read}</Moment></Text>
                                                        }><Text>Seen</Text></Tooltip>
                                                )}
                                        </TagLabel>
                                    </Tag> : <></>}
                                    {message.type == "message_out" && <Text fontSize={12}>{message.account}</Text>}
                                    {message.metadata?.connect && <Text fontSize="xs" color="#5090FF" fontWeight={900}> Connection request </Text>}
                                    {getChannelIcon(message.channel)}

                                </HStack>
                                    {message.subject && <Text textAlign={message.type == "message_out" ? "end" : "start"} fontWeight={500} color="gray.500">Subject: {message.subject}</Text>}
                                </Stack>
                                ,

                            }
                        } as any


                }
                else if (["event", "task", "note"].includes(activity.type)) {
                    return <ActivityCard activity={activity as any} onArchive={() => {
                        return patchEndpoint.execute({ activityId: (activity as any).id }, { archived: true }).then(() => {
                            refresh()

                        })
                    }} />
                }

                else {
                    return <Stack>
                        <AutoUI value={activity} />
                    </Stack>
                }
            })
        }
    }, [activitiesRequest.data])

    function showDialog(type: "new_message" | "new_event" | "schedule_message") {

        let newObj: any
        if (type == "schedule_message") {
            let received = [...activitiesRequest?.data.filter(m => m.type == "message_in")]
            received.reverse()
            let lastMsg = received.find(m => (m as any).channel == currentChanelAccount?.channel && m.account == currentChanelAccount?.account)
            newObj = { contact_id: contactId, text: "", type: "message_out", channel: currentChanelAccount?.channel, account: currentChanelAccount?.account }
            setModal(<EditModal
                caption="Schedule message"
                value={newObj}
                onOk={(val) => {
                    if (val.channel == "email") {
                        val = { ...val, thread_id: val.subject ? undefined : replyTo?.thread_id, schedule_preconditions: { last_received_id: lastMsg.id } }
                    }
                    postActivityEndpoint.execute({}, val).then(() => {
                        setModal(undefined)
                        refresh()
                    }).catch(e => {
                        toast({
                            title: "Error",
                            description: e.text,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    })
                }}
                onCancel={() => {
                    setModal(undefined)
                }}>
                {(val, setVal) => {

                    return <ScheduleDetail activity={val as any} lastReceivedMessage={lastMsg as any} onChange={setVal} />
                }}
            </EditModal>)
        }
        else {
            newObj = (type == "new_message" ? ({ contactId: contactId, text: "", type: "message_out" }) : ({ contactId: contactId, text: "", type: "event" }))
            setModal(<EditModal
                caption={type == "new_message" ? "New message" : type == "new_event" ? "New event" : "Schedule message"}
                value={newObj}
                onOk={(val) => {
                    postActivityEndpoint.execute({}, val).then(() => {
                        setModal(undefined)
                    }).catch(e => {
                        toast({
                            title: "Error",
                            description: e.text,
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                        })
                    })
                }}
                onCancel={() => {
                    setModal(undefined)
                }}
            >{(val, setVal) => (
                <ActivityDetail activity={val as any} onChange={setVal} />
            )}
            </EditModal>)
        }
    }

    return (


            <HStack height="100%" flexGrow={1} flexShrink={1} width="100%" >
                <Stack flexGrow={1} height="100%" border="1px solid lightgray" borderRadius={8} spacing={0} flexShrink={1} width="100%" >
                {modal}
                    {contactRequest.data && <ContactCard contact={contactRequest.data}
                        allowStateChange
                        detailsOpen={showContactDetails}
                        onUpdated={(val) => {
                            contactRequest.mutate(val)
                            onContactModified && onContactModified()
                        }}
                        onToggleDetail={() => { setShowContactDetails(!showContactDetails) }}
                    ><Stack>
                    <HStack justify="space-between">

                                {contactDetails.data?.campaign_name ?
                                    <HStack justify="start">
                                        <Link href={`/analytics/${contactDetails.data.campaign_id}`}>
                                            <Tag alignSelf="start" variant="outline" colorScheme="brand" size="xs" fontSize="xs" p="2px 4px"><TagLabel>


                                                Campaign: {contactDetails.data.campaign_name}

                                            </TagLabel></Tag>
                                        </Link>
                                        <Tag>

                                            <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                                State: {contactDetails.data.campaign_state}
                                            </Text>
                                        </Tag>
                                    </HStack> : <HStack></HStack>

                                }

                                <HStack justifySelf="end">
                                    {contactRequest.data?.waiting_for_activity && <Tooltip label="Mark as handled">
                                        <Button leftIcon={<IconMailExclamation />} colorScheme='brand' size="xs"
                                            onClick={() => {
                                                markHandledEndpoint.execute({}).then((res) => {
                                                    contactRequest.mutate()
                                                    onContactModified && onContactModified()
                                                })
                                            }}
                                        >Requires attention</Button>
                                    </Tooltip>}

                                </HStack>

                    </HStack>

                </Stack></ContactCard>}

                <GeniouslyThemeProvider>
                        {activitiesRequest.isLoading ? <Stack align="center" justifySelf="center" alignSelf="center" height="100%" p="20vh"><Spinner />Loading...</Stack> : (

                        <ChatContainer


                            onSend={sendEndpoint.isRunning ? undefined : (message) => {
                                return sentMessage((message as any).message)
                            }}
                            getChatMessagesActions={((message: { original_message: ExternalOutboundMessage }) => {
                                return ((message.original_message?.scheduled_send_time || (message.original_message?.verification_status)) && !(message as any).original_message?.sent_at) ? [
                                    ...(message.original_message?.verification_status !== "pending" ? ([]) : [{
                                        label: "✅ Verify",
                                        primary: true,
                                        onClick: () => {
                                            patchEndpoint.execute({ activityId: (message as any).original_message.id }, { verification_status: "verified" }).then(() => {
                                                refresh()
                                            })
                                        }
                                    },
                                    {
                                        label: "❌ Reject",
                                        primary: true,
                                        onClick: () => {
                                            patchEndpoint.execute({ activityId: (message as any).original_message.id }, { verification_status: "rejected" }).then(() => {

                                                refresh()
                                            })
                                        }
                                    }]),

                                    ...((message.original_message.scheduled_send_time) ? [{
                                        label: "✉️ Send now",
                                        primary: true,
                                        onClick: () => {
                                            sendEndpoint.execute({}, message.original_message, { "force": true }).then(() => {

                                                refresh()
                                            })
                                        }
                                    }] : []),

                                    {
                                        label: "✏️ Edit",
                                        primary: true,
                                        onClick: () => {
                                            return new Promise((resolve, reject) => {
                                                setModal(<EditModal
                                                    caption={"Update schedule activity"}
                                                    value={(message as any).original_message}
                                                    onOk={(val) => {
                                                        patchEndpoint.execute({ activityId: (message as any).original_message.id }, { scheduled_send_time: val.scheduled_send_time, text: val.text }).then(
                                                            () => {
                                                                setModal(undefined)
                                                                resolve(undefined)
                                                                refresh()

                                                            }
                                                        )
                                                    }}
                                                    onCancel={() => {
                                                        setModal(undefined)
                                                        resolve(undefined)
                                                    }}
                                                >{(val, setVal) => {
                                                    return <ScheduleDetail activity={val as any} onChange={setVal} />
                                                }}
                                                </EditModal>)
                                            })
                                        }
                                    },


                                    // {
                                    //     label: "✏️ Edit",
                                    //     primary: true,
                                    //     onClick: () => {
                                    //         return new Promise((resolve, reject) => {
                                    //             setModal(<EditModal
                                    //                 caption={"Edit activity"}
                                    //                 value={(message as any).original_message}
                                    //                 onOk={(val) => {
                                    //                     setModal(undefined)
                                    //                     resolve(undefined)
                                    //                 }}
                                    //                 onCancel={() => {
                                    //                     setModal(undefined)
                                    //                     resolve(undefined)
                                    //                 }}
                                    //             >{(val, setVal) => {
                                    //                 return <ActivityDetail activity={val as any} onChange={setVal} />
                                    //             }}
                                    //             </EditModal>)
                                    //         })
                                    //     }
                                    // },
                                    {
                                        label: "✖️ Archive",
                                        primary: true,
                                        onClick: () => {
                                            setModal(<ConfirmModal caption='Archive' question='Do you want do archive this message (it wont be sent)?' onOk={() => {
                                                return patchEndpoint.execute({ activityId: (message as any).original_message.id }, { archived: true }).then(() => {
                                                    refresh()
                                                    setModal(undefined)
                                                })

                                            }}
                                                onCancel={() => {
                                                    setModal(undefined)
                                                }}
                                            />)
                                        }
                                    },
                                    ...[
                                        {
                                            label: "✖️ Archive all in campaign",
                                            primary: true,
                                            onClick: () => {
                                                setModal(<ConfirmModal caption='Archive all in this campaign' question='Do you want to archive all unsent messages in this campaign?' onOk={() => {
                                                    return bulkArchiveEndpoint.execute({}, { metadata_filter: { campaign_name: (message as any).original_message?.metadata?.campaign_name, close_campaign: true } }).then(() => {
                                                        refresh()
                                                        setModal(undefined)
                                                    })

                                                }}
                                                    onCancel={() => {
                                                        setModal(undefined)
                                                    }}
                                                />)
                                            }
                                        }
                                    ].filter(() => (message as any).original_message?.metadata?.campaign_name),
                                    ...[
                                        {
                                            label: "📑 Show error details",
                                            primary: true,
                                            onClick: () => {
                                                //return navigator.clipboard.writeText((message as any).original_message?.id)
                                                setModal(<ConfirmModal
                                                    caption='Error details'
                                                    question={(message as any).original_message?.sending_error || "No error details"}
                                                    onOk={() => {
                                                        setModal(undefined)
                                                    }} />)
                                            }
                                        }
                                    ].filter(() => !(message as any).original_message?.sent_at && (message as any).original_message?.sending_error),

                                ] : []
                            }) as any}
                            messages={activities}
                        />
                    )}
                </GeniouslyThemeProvider>
                {sendEndpoint.isRunning && <Stack align="center" justifySelf="center" alignSelf="center" height="40px"><Spinner /><Text>Sending...</Text></Stack>}

                <HStack justify="space-between" flexWrap="wrap">

                    <HStack justify="start" p="8px" flexGrow={1}>
                        {isEmail && replyTo &&
                            <Stack spacing={0} flexGrow={1} >
                                <HStack mt="-5px">

                                    <Checkbox size="sm" isChecked={!!replyTo?.thread_id} onChange={(e) => {
                                        if (e.target.checked) {

                                            let lastMessage = activitiesRequest.data.slice().reverse()?.filter(a => (a as any).channel == currentChanelAccount?.channel)[0] as any
                                            setReplyTo({ thread_id: lastMessage?.thread_id, subject: (lastMessage?.subject && !lastMessage?.subject.startsWith("RE:") ? "RE:" + lastMessage?.subject : lastMessage?.subject) })
                                        }
                                        else {
                                            setReplyTo({ subject: "" })
                                        }
                                    }} />
                                    <Text fontSize="xs" color="gray.500" fontWeight={500}>
                                        Reply to last {currentChanelAccount.channel} message
                                    </Text>

                                </HStack>
                                <Input
                                        isDisabled={!!replyTo?.thread_id} border={!replyTo?.thread_id && !replyTo?.subject ? "1px solid red" : "1px solid gray"} size="xs"
                                    placeholder="Subject" value={replyTo?.subject} onChange={(e) => setReplyTo({ subject: e.target.value })} />



                            </Stack>
                        }
                    </HStack>

                <HStack justify="end" p="8px">
                            <Menu size="sm" variant="outline" >
                                <MenuButton size="xs" as={Button} leftIcon={<IconPlus size="15px" />} rightIcon={<IconChevronDown size="15px" />} variant="outline" colorScheme="blackAlpha">
                            Add message or event
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => showDialog("new_event")}>🔖 Add event</MenuItem>
                                <MenuItem onClick={() => showDialog("new_message")}>📨 Add message</MenuItem>
                                <MenuItem onClick={() => showDialog("schedule_message")}>⏰ Schedule a message</MenuItem>

                        </MenuList>
                    </Menu>
                            <ButtonGroup size='sm' isAttached variant="outline" colorScheme="blackAlpha">
                        <Menu size="sm">
                                    <MenuButton size="xs" as={Button} rightIcon={<IconChevronDown size="15px" />} onClick={() => setFetchAvailableAccounts(true)}>
                            {currentChanelAccount ? <HStack><Box>{getChannelInfo(currentChanelAccount?.channel).icon}</Box><Text>{currentChanelAccount.account}</Text></HStack> : <Text>Choose account for outreach</Text>}
                        </MenuButton>
                        <MenuList>
                                {getAvailableAccountOptionsEndpoint.isLoading ? <MenuItem><Spinner size="sm" /> Loading...</MenuItem> :
                                        getAvailableAccountOptionsEndpoint.data?.map(o => <MenuItem opacity={o.timestamp ? undefined : 0.5}
                                        onClick={() => {
                                            setCurrentChannelAccount(o)
                                        }}
                                        ><Box width="40px" >{getChannelInfo(o.channel)?.icon}</Box> {o.account}</MenuItem>)}

                        </MenuList>
                    </Menu>
                                <Tooltip label={"Refresh messages for " + currentChanelAccount?.account + " " + currentChanelAccount?.channel} placement='top-end'>
                                    <IconButton isLoading={refreshEndpoint.isRunning} size="xs" aria-label="Refresh messages" icon={<IconRefresh size="15px" />}
                                        onClick={() => refreshEndpoint.execute({}, {}, { channel: currentChanelAccount.channel, account: currentChanelAccount.account }).then(() => {
                                            refresh()
                                            getAvailableAccountOptionsEndpoint.mutate()
                                        })} />
                                </Tooltip>
                            </ButtonGroup>
                </HStack>

                </HStack >
                </Stack>
                {showContactDetails && <Stack flexGrow={1} w="30vw" minW="350px" border="1px solid lightgray" height="100%" borderRadius={8} spacing={0} flexShrink={0}>
                    <ContactDetail contact={contactRequest.data} onUpdated={(val) => {
                        contactRequest.mutate(val)
                    onContactModified && onContactModified()
                        getAvailableAccountOptionsEndpoint.mutate()
                    }} />
                </Stack>}
            </HStack>

    );
};

const FilterSelect = ({ options, value, onChange, label }: { options: Record<string, string>, value: string, onChange: (val: string) => void, label: string }) => {
    return (options[value] ? (
        <Tag cursor="pointer" fontWeight={500} alignSelf="start">
            <TagLabel >{label}{": "}{options[value]}</TagLabel>
            <TagCloseButton onClick={() => onChange(undefined)} />
        </Tag>
    ) : <Select placeholder={label} size="sm" value={value} onChange={(e) => onChange(e.target.value)}>
        {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}
    </Select>)
}

export { InboxPage, ContactInbox };