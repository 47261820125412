import { Box, Button, ButtonGroup, HStack, Heading, Stack, Table, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { CampaignStatsWidget, MiniWidget } from "./campaignStatsWidgets";
import { useState, useMemo } from "react";
import { IconAddressBook, IconCheckbox, IconNumber12Small, IconPercentage, IconSend } from "@tabler/icons-react";
import { FaReply } from "react-icons/fa";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useApiFetch } from "../../utils/useApiHook";


// Helper function to extract prefix from status ID
const getStatusPrefix = (statusId: string): string => {
    const slashIndex = statusId.indexOf('>');
    return slashIndex >= 0 ? statusId.substring(0, slashIndex).trim() : statusId;
};

interface CampaignAnalysisProps {
    selectedCampaigns: CampaignStats[];
}

const CampaignAnalysis = ({ selectedCampaigns }: CampaignAnalysisProps) => {
    const [viewMode, setViewMode] = useState<"absolute" | "percentage">("absolute");
    const [sortKey, setSortKey] = useState<string | null>("contacts_count");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");

    // Fetch settings for contact statuses
    const settingsEndpoint = useApiFetch<{ contact_statuses: { id: string; name: string; color: string }[] }>("/org/settings", {
        static: true,
        swrOptions: { refreshInterval: 9999999, revalidateIfStale: false, revalidateOnFocus: false }
    });

    const statusHeaders = useMemo(() => {
        return settingsEndpoint.data?.contact_statuses || [];
    }, [settingsEndpoint.data]);

    // Calculate subtotals in a separate state variable
    const { subtotals, prefixes, prefixColors, aggregatedSubtotals } = useMemo(() => {
        if (!selectedCampaigns || !statusHeaders?.length) {
            return { subtotals: {}, prefixes: [], prefixColors: {}, aggregatedSubtotals: {} };
        }

        const prefixes = new Set<string>();
        const prefixColors: Record<string, string> = {};
        const subtotals: Record<string, Record<string, number>> = {};
        const aggregatedSubtotals: Record<string, number> = {};

        // First identify all unique prefixes and their colors (from first occurrence)
        statusHeaders.forEach(status => {
            if (!status.name.includes('>')) return;

            const prefix = getStatusPrefix(status.name);
            prefixes.add(prefix);

            // Store color from first occurrence
            if (!prefixColors[prefix] && status.color) {
                prefixColors[prefix] = status.color;
            }
        });

        let statusIdNameMap = Object.fromEntries(statusHeaders.map(status => [status.id, status.name]))

        // Calculate subtotals for each campaign
        selectedCampaigns.forEach(campaign => {
            if (!campaign.by_statuses) return;

            const campaignId = campaign.campaign_id;
            subtotals[campaignId] = {};

            prefixes.forEach(prefix => {
                // Calculate subtotal for this prefix
                let subtotal = 0;
                Object.entries(campaign.by_statuses).forEach(([statusId, count]) => {
                    let statusName = statusIdNameMap[statusId] as string
                    if (statusName && (statusName === prefix || statusName.startsWith(prefix + ' >'))) {
                        subtotal += count as number;
                    }
                });

                if (subtotal > 0) {
                    const subtotalKey = `${prefix}_total`;
                    subtotals[campaignId][subtotalKey] = subtotal;

                    // Also aggregate for overall stats
                    aggregatedSubtotals[subtotalKey] = (aggregatedSubtotals[subtotalKey] || 0) + subtotal;
                }
            });
        });

        return {
            subtotals,
            prefixes: Array.from(prefixes),
            prefixColors,
            aggregatedSubtotals
        };
    }, [selectedCampaigns, statusHeaders]);

    // Sort campaigns based on current sort settings
    const sortedCampaigns = useMemo(() => {
        if (!selectedCampaigns) return [];

        return [...selectedCampaigns].sort((a, b) => {
            // Status-specific sorting
            if (sortKey?.startsWith("status:")) {
                const statusId = sortKey.substring(7);
                const aValue = (a.by_statuses?.[statusId] || 0);
                const bValue = (b.by_statuses?.[statusId] || 0);

                // For percentages, calculate proportional values
                if (viewMode === "percentage") {
                    const aPercent = a.contacts_count > 0 ? aValue / a.contacts_count : 0;
                    const bPercent = b.contacts_count > 0 ? bValue / b.contacts_count : 0;
                    return sortDirection === "asc" ? aPercent - bPercent : bPercent - aPercent;
                }

                return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
            }

            // Subtotal sorting
            if (sortKey?.startsWith("subtotal:")) {
                const prefix = sortKey.substring(9);
                const aValue = (subtotals[a.campaign_id]?.[`${prefix}_total`] || 0);
                const bValue = (subtotals[b.campaign_id]?.[`${prefix}_total`] || 0);

                if (viewMode === "percentage") {
                    const aPercent = a.contacts_count > 0 ? aValue / a.contacts_count : 0;
                    const bPercent = b.contacts_count > 0 ? bValue / b.contacts_count : 0;
                    return sortDirection === "asc" ? aPercent - bPercent : bPercent - aPercent;
                }

                return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
            }

            // Regular column sorting
            const aValue = a[sortKey as keyof CampaignStats];
            const bValue = b[sortKey as keyof CampaignStats];

            if (typeof aValue === "number" && typeof bValue === "number") {
                return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
            }

            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortDirection === "asc"
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            return 0;
        });
    }, [selectedCampaigns, sortKey, sortDirection, viewMode, subtotals]);

    // Calculate aggregated stats
    const aggregatedStats = useMemo(() => {
        if (!selectedCampaigns || selectedCampaigns.length === 0) return null;

        const result = selectedCampaigns.reduce(
            (acc, campaign) => {
                // Aggregate main stats
                acc.contacts_count += campaign.contacts_count || 0;
                acc.contacted += campaign.contacted || 0;
                acc.canceled += campaign.canceled || 0;
                acc.accepted_invite += campaign.accepted_invite || 0;
                acc.replied += campaign.replied || 0;

                // Aggregate status counts
                if (campaign.by_statuses) {
                    Object.entries(campaign.by_statuses).forEach(([status, count]) => {
                        acc.by_statuses[status] = (acc.by_statuses[status] || 0) + (count as number);
                    });
                }

                return acc;
            },
            {
                contacts_count: 0,
                contacted: 0,
                canceled: 0,
                accepted_invite: 0,
                replied: 0,
                by_statuses: {} as Record<string, number>
            }
        );

        return result;
    }, [selectedCampaigns]);

    const handleSort = (key: string) => {
        if (sortKey === key) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortKey(key);
            setSortDirection("desc");
        }
    };

    const renderSortIcon = (key: string) => {
        if (sortKey !== key) return null;
        return sortDirection === "asc" ? <IconChevronUp size="14px" /> : <IconChevronDown size="14px" />;
    };

    const renderValue = (value: number, total: number) => {
        if (viewMode === "absolute") {
            return value;
        } else {
            return total > 0 ? `${((value / total) * 100).toFixed(1)}%` : "0%";
        }
    };

    if (!selectedCampaigns || selectedCampaigns.length === 0) {
        return <Box p={4}>No campaign data available</Box>;
    }

    if (settingsEndpoint.isLoading) {
        return <Box p={4}>Loading status settings...</Box>;
    }

    return (
        <Box>
            <Box p={4}>


                {aggregatedStats && (
                    <Box mb={4}>
                        <Heading size="lg" >
                            Overall Statistics
                        </Heading>

                        <HStack spacing={4} flexWrap="wrap">
                            <MiniWidget
                                title="Contacts"
                                value={aggregatedStats.contacts_count?.toString() || "0"}
                                icon={IconAddressBook}
                                size="md"
                            />
                            <MiniWidget
                                title="Sent"
                                value={viewMode === "absolute"
                                    ? (aggregatedStats.contacted?.toString() || "0")
                                    : `${((aggregatedStats.contacted / aggregatedStats.contacts_count) * 100).toFixed(1)}%`}
                                icon={IconSend}
                                size="md"
                            />
                            <MiniWidget
                                title="Progress"
                                subtitle={`${aggregatedStats.contacted || 0}/${aggregatedStats.contacts_count && aggregatedStats.canceled
                                    ? aggregatedStats.contacts_count - aggregatedStats.canceled
                                    : 0
                                    }`}
                                value={
                                    aggregatedStats.contacts_count && aggregatedStats.canceled && aggregatedStats.contacts_count - aggregatedStats.canceled
                                        ? Math.round(
                                            100 * ((aggregatedStats.contacted || 0) / (aggregatedStats.contacts_count - aggregatedStats.canceled))
                                        ).toString() + "%"
                                        : "-"
                                }
                                size="md"
                            />
                            <MiniWidget
                                title="Accepted invite"
                                subtitle={aggregatedStats.accepted_invite ? `${aggregatedStats.accepted_invite}/${aggregatedStats.contacted}` : undefined}
                                value={(aggregatedStats.accepted_invite && aggregatedStats.contacted) ? `${Math.round(100 * aggregatedStats.accepted_invite / aggregatedStats.contacted)} %` : "-"}
                                icon={IconCheckbox}
                                size="md"
                            />
                            <MiniWidget
                                title="Replied"
                                subtitle={aggregatedStats.replied ? `${aggregatedStats.replied}/${aggregatedStats.contacted}` : undefined}
                                value={(aggregatedStats.replied && aggregatedStats.contacted) ? `${Math.round(100 * aggregatedStats.replied / aggregatedStats.contacted)} %` : "-"}
                                icon={FaReply}
                                size="md"
                            />

                            {/* Status widgets */}
                            {statusHeaders.map((status) => (
                                aggregatedStats.by_statuses[status.id] ? (
                                    <MiniWidget
                                        key={status.id}
                                        title={status.name}
                                        subtitle={`${aggregatedStats.by_statuses[status.id]}/${aggregatedStats.contacts_count}`}
                                        value={`${((aggregatedStats.by_statuses[status.id] / aggregatedStats.contacts_count) * 100).toFixed(1)}%`}
                                        color={status.color}
                                        size="md"
                                    />
                                ) : null
                            ))}
                            <Stack borderLeftWidth="1px" borderColor="gray.600" h="50px" mx={2} />
                            <HStack justifySelf="end">
                                {/* Subtotal widgets for each prefix */}
                                {prefixes.map((prefix) => {
                                    const subtotalKey = `${prefix}_total`;
                                    const subtotalValue = aggregatedSubtotals[subtotalKey];
                                    return (
                                        <MiniWidget
                                            key={subtotalKey}
                                            title={`${prefix} (total)`}
                                            subtitle={`${subtotalValue}/${aggregatedStats.contacts_count}`}
                                            value={`${((subtotalValue / aggregatedStats.contacts_count) * 100).toFixed(1)}%`}
                                            color={prefixColors[prefix]}
                                            size="md"
                                        />
                                    );
                                })}
                            </HStack>
                        </HStack>
                    </Box>
                )}
                <HStack justify="space-between" mb={4}>
                    <Heading size="lg">Analysis by campaign</Heading>
                    <ButtonGroup size="sm" isAttached colorScheme="brand">
                        <Button
                            leftIcon={<IconNumber12Small />}
                            variant={viewMode === "absolute" ? "solid" : "outline"}
                            onClick={() => setViewMode("absolute")}
                        >
                            Absolute
                        </Button>
                        <Button
                            leftIcon={<IconPercentage />}
                            variant={viewMode === "percentage" ? "solid" : "outline"}
                            onClick={() => setViewMode("percentage")}
                        >
                            Percentage
                        </Button>
                    </ButtonGroup>
                </HStack>

                <Box overflowX="auto">
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr>
                                <Th cursor="pointer" onClick={() => handleSort("name")}>
                                    Campaign Name {renderSortIcon("name")}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort("contacts_count")}>
                                    Total Contacts {renderSortIcon("contacts_count")}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort("contacted")}>
                                    Contacted {renderSortIcon("contacted")}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort("accepted_invite")}>
                                    Accepted Invite {renderSortIcon("accepted_invite")}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort("replied")}>
                                    Replied {renderSortIcon("replied")}
                                </Th>
                                <Th cursor="pointer" onClick={() => handleSort("canceled")}>
                                    Canceled {renderSortIcon("canceled")}
                                </Th>

                                {/* Status columns */}
                                {statusHeaders.map((status) => (
                                    <Th
                                        key={status.id}
                                        cursor="pointer"
                                        onClick={() => handleSort(`status:${status.id}`)}
                                        backgroundColor={status.color + "22"}
                                    >
                                        {status.name} {renderSortIcon(`status:${status.id}`)}
                                    </Th>
                                ))}

                                {/* Subtotal columns */}
                                {prefixes.map((prefix) => (
                                    <Th
                                        key={`subtotal-${prefix}`}
                                        cursor="pointer"
                                        onClick={() => handleSort(`subtotal:${prefix}`)}
                                        backgroundColor={(prefixColors[prefix] || "#cccccc") + "44"}
                                        fontWeight="bold"
                                    >
                                        {prefix} (total) {renderSortIcon(`subtotal:${prefix}`)}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedCampaigns.map((campaign) => (
                                <Tr key={campaign.campaign_id}>
                                    <Td>{campaign.name}</Td>
                                    <Td>{campaign.contacts_count}</Td>
                                    <Td>{renderValue(campaign.contacted || 0, campaign.contacts_count)}</Td>
                                    <Td>
                                        {renderValue(campaign.accepted_invite || 0, campaign.contacted || 1)}
                                    </Td>
                                    <Td>{renderValue(campaign.replied || 0, campaign.contacted || 1)}</Td>
                                    <Td>{renderValue(campaign.canceled || 0, campaign.contacts_count)}</Td>

                                    {/* Status values */}
                                    {statusHeaders.map((status) => (
                                        <Td
                                            key={status.id}
                                            backgroundColor={campaign.by_statuses?.[status.id] ? status.color + "22" : undefined}
                                        >
                                            {renderValue(campaign.by_statuses?.[status.id] || 0, campaign.contacts_count)}
                                        </Td>
                                    ))}

                                    {/* Subtotal values */}
                                    {prefixes.map((prefix) => {
                                        const subtotalKey = `${prefix}_total`;
                                        return (
                                            <Td
                                                key={`subtotal-${prefix}-${campaign.campaign_id}`}
                                                backgroundColor={(prefixColors[prefix] || "#cccccc") + "44"}
                                                fontWeight="bold"
                                            >
                                                {renderValue(subtotals[campaign.campaign_id]?.[subtotalKey] || 0, campaign.contacts_count)}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Box>
        </Box >
    );
};

export default CampaignAnalysis;