
import React from 'react';
import { HStack, Stack, Text, Tooltip, Tag, TagLabel, Button, CloseButton, Menu, MenuButton, IconButton, MenuList, MenuItem } from '@chakra-ui/react';
import Moment from 'react-moment';
import { IconArchive, IconArrowBackUp, IconClipboard, IconClipboardCheck, IconClock, IconClockExclamation, IconMessage, IconNote, IconX } from '@tabler/icons-react';

const ArchiveButton = ({ onArchive }: { onArchive: () => any }) => {
    return <Menu>
        <MenuButton as={IconButton} icon={<IconX size="20px" />} size="xs" variant="ghost" color="gray.500" ></MenuButton>
        <MenuList>

            <MenuItem color="#fa0f0f" onClick={() => onArchive()}>Archive record</MenuItem>
        </MenuList>
    </Menu>
}
const MessageActivityCard = ({ message }: { message: any }) => {
    return <HStack align="start" border="1px solid gray" borderRadius="0px 8px 8px 8px" p="6px" >
        {message.type == "message_in" && <IconMessage />}
        <Stack spacing={0} flexGrow={1}>
            <HStack justify="space-between" fontWeight={500} fontSize="xs" color="gray.500">
                <HStack >
                    {message.type == "message_out" && <IconMessage size="18px" />}
                    <Text >{message.channel}</Text>
                    <Tooltip label={message.timestamp}><Text fontSize="xs" ><Moment fromNow>{message.timestamp}</Moment></Text></Tooltip>
                </HStack>


            </HStack>
            {message.metadata?.classification && <Tag size="sm" variant="solid" >{message.metadata.classification}</Tag>}
            <Stack align={message.type == "message_out" ? "end" : "start"}>

                <Text fontSize="xs" color="gray.800" fontWeight={500} whiteSpace="pre-wrap">
                    {message.text}
                </Text>
                {message.sending_error && <Tooltip label={<Text>{message.sending_error}</Text>}><Text color="red" size="sm" variant="solid" whiteSpace="pre-wrap" fontSize="2xs" noOfLines={1}>{message.sending_error}</Text></Tooltip>}
            </Stack>
        </Stack>

    </HStack>
}
const EventActivityCard = ({ activity, onArchive }: { activity: any, onArchive?: () => any }) => {
    return <HStack align="start" border="1px solid lightgray" borderLeft="none" borderRight="none" p="6px" >
        <IconClockExclamation />
        <Stack spacing={0} flexGrow={1} textDecoration={activity.archived ? "line-through" : undefined}>
            <HStack justify="space-between" fontWeight={500} fontSize="xs" >
                <HStack>

                    <Text>Event</Text>
                    <Tooltip label={activity.timestamp}><Text fontSize="xs" ><Moment fromNow>{activity.timestamp}</Moment></Text></Tooltip>
                </HStack>
                {onArchive && <ArchiveButton onArchive={onArchive} />}
            </HStack>
            <Text fontSize="xs" color="gray.500" fontWeight={500} whiteSpace="pre-wrap">
                {activity.text}
            </Text>
        </Stack>
    </HStack>
}

const TaskActivityCard = ({ activity, onArchive }: { activity: any, onArchive?: () => any }) => {
    return <HStack align="start" border="1px solid lightgray" borderLeft="none" borderRight="none" p="6px" >
        {(activity as any).completed ? <IconClipboardCheck /> : <IconClipboard />}
        <Stack spacing={0} flexGrow={1} textDecoration={activity.archived ? "line-through" : undefined}>
            <HStack justify="space-between" fontWeight={500} fontSize="xs" >
                <HStack>

                    <Text>Task</Text>
                    <Tooltip label={activity.timestamp}><Text fontSize="xs" ><Moment fromNow>{activity.timestamp}</Moment></Text></Tooltip>
                </HStack>
                {onArchive && <ArchiveButton onArchive={onArchive} />}
            </HStack>
            <Text fontSize="xs" color="gray.500" fontWeight={500} whiteSpace="pre-wrap">
                {activity.text}
            </Text>
        </Stack>
    </HStack>
}

const NoteActivityCard = ({ activity, onArchive }: { activity: any, onArchive?: () => any }) => {
    return <HStack align="start" border="1px solid lightgray" borderLeft="none" borderRight="none" p="6px" >
        <IconNote />
        <Stack spacing={0} flexGrow={1} textDecoration={activity.archived ? "line-through" : undefined}>
            <HStack justify="space-between" fontWeight={500} fontSize="xs" >
                <HStack>

                    <Text>Note</Text>
                    {(activity).reminder &&

                        <Tag>
                            <HStack>

                                <IconClock size="15px" />
                                <Text fontSize="xs"  >Reminder </Text>
                                <Tooltip label={<Moment >{(activity).reminder}</Moment>
                                }>
                                    <HStack whiteSpace="nowrap" fontSize="xs">
                                        <Moment fromNow>{(activity).reminder}</Moment>

                                    </HStack>
                                </Tooltip>
                            </HStack>
                        </Tag>

                    }

                </HStack>
                <HStack>
                <Text color="gray.900" > {(activity as any).event_type}</Text>
                    {onArchive && <ArchiveButton onArchive={onArchive} />}
                </HStack>
            </HStack>
            <Text fontSize="xs" color="gray.500" fontWeight={500} whiteSpace="pre-wrap">
                {activity.text}
            </Text>

        </Stack>
    </HStack>
}

const ArchivedActivity = ({ activity, onRestore }: { activity: any, onRestore?: () => Promise<any> }) => {
    const [expanded, setExpanded] = React.useState(false)
    const [restoring, setRestoring] = React.useState(false)

    return <Stack opacity={expanded ? 1 : 0.5} border={expanded ? "1px solid gray" : undefined} borderRadius={4} align="end" p="8px 36px">
        <HStack width="100%" justify="end">


            <Tooltip placement="bottom-end" label={!expanded ? activity.text : undefined} ><Tag cursor="pointer" justifySelf="flex-end" variant="outline" color="gray" onClick={() => setExpanded(!expanded)}> <IconArchive size="15px" />

                <TagLabel>Archived</TagLabel></Tag>
            </Tooltip>
        </HStack>
        {expanded && <Stack align="end"> <Text fontSize="xs" color="gray.500" fontWeight={500}>
            {activity.text}
        </Text>
            <Text fontSize="xs" color="gray">
                Archived reason:
                {activity.archive_reason}
            </Text>

            {expanded && onRestore && <Button isLoading={restoring} size="xs" justifySelf="flex-start" leftIcon={<IconArrowBackUp size="15px" />} onClick={() => {
                setRestoring(true)
                onRestore().finally(() => setRestoring(false))
            }}>Restore from archive</Button>}

        </Stack>
        }
    </Stack>
}

const ActivityCard = ({ activity, onArchive }: { activity: any, onArchive?: () => any }) => {
    if (activity.archived) {
        return <ArchivedActivity activity={activity} />;
    } else if (activity.type == "message_out" || activity.type == "message_in") {

            return <MessageActivityCard message={activity} />;

    } else if (activity.type == "event") {
        return <EventActivityCard activity={activity} onArchive={onArchive} />;
    } else if (activity.type == "task") {
        return <TaskActivityCard activity={activity} onArchive={onArchive} />;
    } else if (activity.type == "note") {
        return <NoteActivityCard activity={activity} onArchive={onArchive} />;
    }
    return null;
};

export { ActivityCard, MessageActivityCard, EventActivityCard, TaskActivityCard, NoteActivityCard, ArchivedActivity };