import React, { useState } from 'react';
import FlowPageProps from './pageProps';
import { Heading, Stack, Text } from '@chakra-ui/react';
import { AutoUI, ChatContainer, GeniouslyThemeProvider, MarkdownContent } from 'geniously-chat-ui';
import { EditableField } from '../../EditableField/editableField';
import FlowPageWrapper from './pageWrapper';



const ChatFlowPage: React.FC<FlowPageProps> = (props: FlowPageProps) => {


    return (
        <FlowPageWrapper pageProps={props}>

            <Stack p="10px" >
                <GeniouslyThemeProvider>

                    <ChatContainer messages={props.page.chat_messages} maxH="calc(100vh - 300px)" />

                </GeniouslyThemeProvider>
            </Stack>
        </FlowPageWrapper>
    );
};

export default ChatFlowPage;