

import { useCallback, useEffect, useMemo, useRef, useState } from "react";



import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Editable, EditableInput, EditablePreview, filter, list, MenuDivider, MenuOptionGroup, Switch, TagLabel, Tooltip } from "@chakra-ui/react";

import { useAppContext } from "../appContext";
import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { TopicInsightsBox } from "../components/Insights/topicInsights";
import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, WidgetContainer } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import { IconArrowsDiagonal2, IconAsterisk, IconBoxAlignBottomLeftFilled, IconCheck, IconChevronDown, IconChevronRight, IconChevronsDown, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconFileDownload, IconHistory, IconMailFast, IconPlayerEjectFilled, IconPlayerPauseFilled, IconPlayerPlay, IconPlayerPlayFilled, IconPlayerTrackNextFilled, IconPlus, IconPointFilled, IconRefresh, IconRepeat, IconSend, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { LuImport } from "react-icons/lu";
import { Reorder } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useSWR, { mutate } from "swr";
import { ConfirmModal } from "../components/ModalDialogs/confirmModal";
import { EditModal } from "../components/ModalDialogs/editModal";
import { IconFilter } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import Navigation from "../components/Navigation/navigation";
import GenericList from "../components/GenericList/genericList";
import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";
import { ListOpDialog } from "../components/Lists/listOpDialog";
import {

    DataListColumn,
    DataListInfo,
    ListOp,
    ListOpTypeInfo,
    ListPreview
} from "../components/Lists/model";
import { IconCircleDashed } from "@tabler/icons-react";
import { IconRotateClockwise2 } from "@tabler/icons-react";
import ColumnsSelector from "../components/listUI/columnsSelector";
import { ListOpsWidget } from "../components/Lists/listOpsWidget";
import { ListVersionsComponent } from "../components/Lists/listVersionsComponent";
import { FilterButton } from "../components/Lists/filterButton";
import CampaignBuilder from "../components/Campaigns/campaignBuilder";
import { ListProvider } from "../components/Campaigns/listContext";
import { ListOpsSelectionDialog } from "../components/Lists/listOpsSelectionDialog";
import ListHeader from "../components/Lists/listHeader";
import {RunOptionsDialog, ListOpsForm} from "../components/Lists/listOpsForm";


export default function ListEditPage() {



    const navigate = useNavigate()
    const { listId, listVersion } = useParams()


    const [searchParams, setSearchParams] = useSearchParams()
    const pageNo: number = parseInt(searchParams.get("page")) || 1



    const levelGroup = searchParams.get("level_group") as "companies" | "people" | string;
    const setLevelGroup = (value: "companies" | "people" | string) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (value) {
            newSearchParams.set("level_group", value);
        } else {
            newSearchParams.delete("level_group");
        }
        setSearchParams(newSearchParams);
    };

    const listRequest = useApiFetch<DataListInfo>(`/lists/${listId}`, {
        queryArgs: { page: pageNo, version: listVersion, level_group: levelGroup },
        shouldFetch: !!listId && listId != "new", swrOptions: { revalidateOnFocus: false, refreshInterval: 0 }
    })

    useEffect(() => {
        if (listRequest.data?.current_level && listRequest.data?.current_level != levelGroup) {
            setLevelGroup(listRequest.data.current_level);
        }
    }, [listRequest.data?.current_level]);


    const setPageNo = (pageNo: number) => {
        if (pageNo <= 0) {
            if (listRequest.data?.total_count) {
                pageNo = Math.floor(listRequest.data?.total_count / 100)
            }
            else {
                return
            }
        }
        if (Math.floor(listRequest.data?.total_count / 100) + 1 < pageNo) {
            pageNo = 1
        }

        const newSearchParams = new URLSearchParams(searchParams);

        // Update the 'page' query parameter
        newSearchParams.set('page', pageNo.toString());

        setSearchParams(newSearchParams)
    }
    useEffect(() => {
        if (listRequest?.data?.page && listRequest?.data?.page != pageNo) {
            setPageNo(listRequest?.data?.page)
        }
        if (listRequest.data?.data_page?.length == 0 && pageNo > 1) {
            setPageNo(1)
        }
    }, [listRequest])

    const [modal, setModal] = useState<any>()

    const [currentOp, setCurrentOp] = useState<any>()
    const [statusMessage, setStatusMessage] = useState<{ status_type: string, message?: string, metadata: any }>(undefined)
    const [listPreviewAwaitingApproval, setListPreviewAwaitingApproval] = useState<ListPreview>()
    const [allAvailableCommands, setAvailableCommands] = useState<ListOpTypeInfo[]>()
    const availableCommands = useMemo(() => {
        return allAvailableCommands?.filter ? {
            add_column: [],
            filter: allAvailableCommands?.filter((cmd) => cmd.category == "filter"),
            import: allAvailableCommands?.filter((cmd) => cmd.category == "import"),
            others: []
        } : undefined

    }, [allAvailableCommands])
    const [filters, setFilters] = useState<{ [key: string]: any }>()




    useEffect(() => {
        if (listRequest.data?.name)
            document.title = listRequest.data?.name + " - EasyPie.ai"
        return () => {
            document.title = "EasyPie.ai"
        }
    }, [listRequest.data])


    const [listName, setListName] = useState<string>()

    const refreshData = listRequest.mutate
    const [runningAction, setRunningAction] = useState<string>()
    const [pendingUpdate, setPendingUpdate] = useState<any[]>()
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([])
    const [chatService, _setChatService] = useState<ChatService>()
    const [interrupting, setInterrupting] = useState<boolean>()

    const [hiddenColumnsDefault, setHiddenColumnsDefault] = useState<string[]>()
    const [columnOrderOverride, _setColumnOrderOverride] = useState<string[]>()
    const [currentListData, setCurrentListData] = useState<DataListInfo>()
    const [data, setData] = useState<{ [key: string]: any }[]>()
    useEffect(() => { setData(currentListData?.data_page) }, [currentListData])

    const reorderProperties = (columnSchema, columnOrderOverride) => {
        if (!columnSchema || !columnSchema.properties || !Array.isArray(columnOrderOverride)) {
            return columnSchema;
        }
        const { properties } = columnSchema;
        const reorderedProperties = {};
        columnOrderOverride.forEach((column) => {
            if (properties.hasOwnProperty(column)) {
                reorderedProperties[column] = properties[column];
            }
        });

        Object.keys(properties).forEach((key) => {
            if (!reorderedProperties.hasOwnProperty(key)) {
                reorderedProperties[key] = properties[key];
            }
        });

        return {
            ...columnSchema,
            properties: reorderedProperties,
        };
    };


    useEffect(() => {
        if (listRequest.data) {
            setListName(listRequest.data?.name)
            setCurrentListData(listRequest.data)
        }
        else if (currentListData) {
            setCurrentListData(undefined)

        }
    }, [listRequest.data])
    useEffect(() => { currentListData?.filter && setFilters(currentListData.filter) }, [currentListData?.filter])
    const [columnsSchema, setColumnsSchema] = useState<JSONSchema>()
    useEffect(() => {
        if (currentListData?.column_schema) {
            if (columnOrderOverride) {
                setColumnsSchema(reorderProperties(currentListData?.column_schema, columnOrderOverride))
            }
            else {
                setColumnsSchema(currentListData?.column_schema)
            }
        }
    }, [currentListData?.column_schema, columnOrderOverride])

    function updateCurrentListData(dataUpdate: any[], schema?: JSONSchema) {
        if (currentListData) {
            if (!Array.isArray(dataUpdate)) return;


            let newCurrentListData = [...currentListData.data_page]
            let newRows = []
            let changed = false
            // Iterate over each object in the dataUpdate array
            dataUpdate.forEach(updateObj => {
                // Check if the updateObj has an 'index' property
                if (updateObj && updateObj.index !== undefined) {
                    // Find the object in data_page with the same index
                    const index = updateObj.index;
                    const existingObjArrayIndex = newCurrentListData.findIndex(obj => obj.index === index);
                    const existingObj = newCurrentListData[existingObjArrayIndex];

                    if (existingObj) {
                        // Update the object as a patch
                        Object.assign(existingObj, updateObj);
                        changed = true
                    }
                    else if (newCurrentListData.length < 100) {
                        newRows.push(updateObj);
                    }


                }
            });
            if (newRows.length) {
                newCurrentListData = [...newCurrentListData, ...newRows]
            }
            if (changed) {
                setCurrentListData({ ...currentListData, data_page: newCurrentListData, column_schema: schema || currentListData.column_schema })
            }
        }
    }

    function onDisconnect(reason: any) {
        setStatusMessage(undefined)
    }
    function setChatService(service: ChatService) {
        if (service != chatService) {

            _setChatService(service)
            if (service) {
                service.subscribe({ "onDisconnect": onDisconnect })
                return () => { service.unsubscribe({ "onDisconnect": onDisconnect }) }
            }
        }

    }
    function showVersions() {
        setModal(<InfoModal caption="Versions" withFullscreenSwitch onClose={() => { setModal(undefined) }}>
            <ListVersionsComponent listId={listId} onRestored={() => {
                setModal(undefined)
                refreshData()

            }} />
        </InfoModal>)
    }
    useEffect(() => {
        if (currentListData) {

            let _newHiddenCols = hiddenColumns ? [...hiddenColumns] : []

            currentListData.columns.filter((col) => col.default_hidden && !hiddenColumns.includes(col.name)).forEach((col) => {
                _newHiddenCols.push(col.name)
            })
            setHiddenColumnsDefault(_newHiddenCols)

            setHiddenColumns(_newHiddenCols)
        }
    }, [currentListData])

    const { data: initStatusData, isLoading: initIsLoading, mutate: refreshInitStatus } = useSWR<any>(`/lists/${listId}/status/${!!chatService?.isActiveConnection}`, () => {
        if (chatService && chatService.isActiveConnection && !listVersion) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(chatService.sendCommand({ "type": "command", "action_id": "hello", "args": {} }, { waitForResult: true }))
                }, 5000)
            })

        }
    }
        , { revalidateOnFocus: false, refreshInterval: 0 })

    useEffect(() => {
        if (initStatusData) {


            setCurrentOp(initStatusData.current_op)

                    setAvailableCommands(initStatusData.commands)
            //setFilters(initStatusData.filters)
            setScheduledOps(initStatusData.scheduled_ops)
            setExecutorState(initStatusData.executor_state)


        }
    }, [initStatusData])

    function callCommand(commandId: string, args: any, timeout: number = 120) {
        setCurrentOp(true)
        chatService.sendCommand({ "type": "command", "action_id": commandId, "args": args }, { waitForResult: true, returnResultMessage: true, timeoutSec: timeout }).then((res) => {
            setCurrentOp(false)
            if (res.error) {
                toast({
                    title: "Error executing command",
                    description: res.error,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })
            }
            else if (res.result.widget_type) {


                    setModal(<InfoModal caption={res.result.title} onClose={() => { setModal(undefined) }}>
                        <WidgetContainer widget={res.result} isLastMessage={true} onExecuteAction={(actionId, val) => {
                            if (res.result.actions?.length && res.result.actions[0].args) {
                                callCommand(actionId, val)

                            }
                        }} />
                </InfoModal>)

            }
            else {

                setModal(undefined)
            }
            console.log(res)
        }).catch((err) => {
            console.error(err)
            setCurrentOp(false)
        })
    }
    const closeDialogCallback = useCallback(() => {
        setModal(undefined)
        setCurrentOp(false)
        setListPreviewAwaitingApproval(undefined)
    }, [])
    function showCommandDialog(opTypeInfo: ListOpTypeInfo, listOp: ListOp = undefined) {
        //if (availableCommands[section] && availableCommands[section][commandId] && availableCommands[section][commandId].schema?.properties && Object.keys(availableCommands[section][commandId].schema?.properties)?.length) {
        if ((!["copy_list_to_account"].includes(opTypeInfo.op_type))) {
                setModal(<ListOpDialog
                    opType={opTypeInfo.op_type}
                    list_id={listId}
                    listOp={listOp as any}
                    level_group={levelGroup}
                    chatService={chatService}
                    interactive={opTypeInfo.interactive}
                    supports_prompting={opTypeInfo.supports_prompting}
                    supports_preview={opTypeInfo.supports_preview}
                    argSchema={opTypeInfo.schema}
                    onClose={closeDialogCallback}
                />)
        }
            else
            setModal(<EditModal caption={opTypeInfo.name} value={{}} onOk={(args) => {
                callCommand(opTypeInfo.op_type, args)
            }}
                onCancel={() => {
                    setModal(undefined)
                }}
            >
                {(val, setVal) => (

                    <AutoUI value={val} onValueChange={setVal} schema={opTypeInfo.schema} />
                )}
            </EditModal>)
        // }
        // else {
        //     callCommand(commandId, {})

        // }
    }
    const tableBackgroundCss = `
    repeating-linear-gradient(
        to right,
        transparent,
        transparent 250px,
        #c8c8c85f 250px,
        #c8c8c85f 251px
    ),
    repeating-linear-gradient(
        to bottom,
        transparent,
        transparent 30px,
        #c8c8c8a0 30px,
        #c8c8c8a0 31px,
        #ffffffa1 32px,
        #ffffffa1 61px,
        #c8c8c8a0 62px,
        #c8c8c8a0 63px
    ),
    linear-gradient(-45deg, white,#eaeaeaa0);
    `

    const saveViewEndpoint = useApiEndpoint<any>("POST", `/lists/${listId}/views`, true, true)
    const campaignsEndpoint = useApiFetch<{ id: string, name: string }[]>(`/campaigns?list_id=${listId}`)
    const exportEndpoint = useApiEndpoint<Blob>("GET", `/lists/${listId}/export?format={format}`, true, true, { outputType: "blob" })
    function exportList(format: "csv" | "xlsx" | "json") {
        exportEndpoint.execute({ format: format }).then((data) => {
            exportEndpoint.execute({ format: format }).then(blob => {

                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = `list-${listId}.${format}`;
                a.click();
            })
        })
    }

    // const statusUpdateCallback = useCallback((statusMessage: StatusMessage) => { })
    // useEffect(() => {
    //     chatService.subscribe({

    //         onStatusUpdate: statusUpdateCallback
    //     })
    // }, [chatService])
    const toast = useToast()

    const [isSaving, setIsSaving] = useState(false)

    function save() {
        if (pendingUpdate && data) {
            let deletedItems = data.filter((item, i) => !pendingUpdate.find(c => c.index === item.index))
            let patch = pendingUpdate.map((item, i) => {
                if (!data.find(c => JSON.stringify(c) === JSON.stringify(item))) {
                    return item
                }
            }).filter((item) => item !== undefined)
            console.log("patch", patch)
            console.log("delete", deletedItems)
            if (patch.length || deletedItems.length) {
                setIsSaving(true)
                chatService.sendCommand({
                    "type": "command", "action_id": "update-data", "args": {
                        "level_group": levelGroup,
                        "patch": patch,
                        "delete": deletedItems,
                        "page": pageNo
                    }
                }, { waitForResult: true }).then((res) => {
                    //setData(res)
                    refreshData()
                    setIsSaving(false)
                    setPendingUpdate(undefined)
                }).catch((err) => {
                    setIsSaving(false)
                    toast({
                        title: "Error updating list",
                        description: "An error occurred while updating the list, please reload the page and try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,

                    })
                })
            }
        }
        if (listName != listRequest.data.name) {
            saveMetadata({ name: listName })
        }
    }
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         if (pendingUpdate && data) {
    //             save()
    //         }
    //     }, 2000)
    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [pendingUpdate])

    const [executorState, setExecutorState] = useState<"running" | "ready" | "paused">()
    const [scheduledOps, setScheduledOps] = useState<ListOp[]>()


    function findAvailableCommand(op_type: string) {
        return allAvailableCommands?.find((cmd) => cmd.op_type == op_type)
    }


    const handleWidgetRendering = ((widget: any, msg, editable) => {
        if (widget.widget_type == "list_ops") {

            return <ListOpsWidget widget={widget}
                listId={listId}
                chatService={chatService}
                allAvailableCommands={allAvailableCommands}
                setModal={setModal}

            />

        }
    })



    const setColumnOrderOverride = (newOrder) => {

        _setColumnOrderOverride(newOrder)

    }

    function saveMetadata(payload: {
        name?: string, list_category?: string,
        change_column_visibility?: { [colName: string]: boolean },
        change_column_order?: string[]
    }) {
        chatService.sendCommand({ "type": "command", "action_id": "update-metadata", "args": payload }, { waitForResult: true }).then((res) => {
            if (payload.change_column_visibility) {
                setHiddenColumns(Object.keys(payload.change_column_visibility).filter((colName) => !payload.change_column_visibility[colName]))
            }
            if (payload.name) {
                listRequest.mutate({ ...listRequest.data, name: payload.name })
            }
        })
    }

    function saveView(levelGroup: string, columnVisibility: { [colName: string]: boolean }) {
        return saveViewEndpoint.invoke({ queryArgs: { level_group: levelGroup }, body: { column_visibility: columnVisibility } })
    }

    function showActionSelectionDialog(category: string = undefined) {
        if (allAvailableCommands)
            setModal(<ListOpsSelectionDialog
                opTypes={allAvailableCommands}
                defaultCategory={category}
                onCancel={() => { setModal(undefined) }}
                onOk={(opTypeInfo, listOp) => {
                    showCommandDialog(opTypeInfo, listOp)
                    // setModal(<ListOpDialog
                    //     opType={opTypeInfo.op_type}
                    //     listOp={listOp as any}
                    //     list_id={listId}
                    //     chatService={chatService}
                    //     interactive={opTypeInfo.interactive}
                    //     supports_prompting={opTypeInfo?.supports_prompting}
                    //     supports_preview={opTypeInfo?.supports_preview}
                    //     argSchema={opTypeInfo?.schema}
                    //     onClose={closeDialogCallback}


                    // />)
                }}
            />)
    }

    const convertEndpoint = useApiEndpoint("POST", `/lists/${listId}/convert`, true, true)

    return (<Stack className="page" align="stretch" flexGrow={1} justify="stretch" spacing={0}>
        {/* <ListProvider columns={currentListData?.columns.map(c => c.name)} list_data={currentListData?.data_page}>
            <CampaignBuilder />

        </ListProvider> */}
        <HStack justify="space-between" mb="-15px">
            <ListHeader
                listName={listName}
                setListName={setListName}
                levels={listRequest.data?.levels}
                levelGroup={levelGroup}
                setLevelGroup={setLevelGroup}
                pendingUpdate={pendingUpdate}
                isSaving={isSaving}
                save={save}
                listRequest={listRequest}
                setPendingUpdate={setPendingUpdate}
                isLoading={listRequest.isValidating || listRequest.isLoading}
                onReload={() => listRequest.mutate()}
            />
            {listRequest?.data?.message && (
                <HStack >
                    <Tag colorScheme={listRequest?.data.message.type == "error" ? "red" : (listRequest?.data.message.type == "warning" ? "orange" : undefined)}>{listRequest.data.message.message}</Tag>
                    {listRequest?.data.message.message_type_key == "old_list_format" && (
                        <Button isLoading={convertEndpoint.isRunning} colorScheme="brand" size="xs" onClick={() => {
                            convertEndpoint.invoke({}).then(() => {
                                listRequest.mutate()
                            })
                        }}
                        >Convert</Button>
                    )}
                </HStack>
            )}
            {(currentOp || statusMessage?.status_type == "typing") && <Portal> <HStack height="10px" p="15px" position="fixed" top="10px" left="calc(50% - 220px)" zIndex={901}>


                {!statusMessage?.message && <HStack>
                    <Spinner size="sm" />
                    <Text>
                    {typeof (currentOp) === "string" ? currentOp : "Processing..."}
                    </Text>
                </HStack>
                }

                {(statusMessage) && <Stack p="0px 5px" justify="stretch" minW="400px">
                    <GeniouslyThemeProvider>
                        <ChatStatusBadge statusMessage={statusMessage} />
                    </GeniouslyThemeProvider>


                </Stack>}
                {/* <Text>{JSON.stringify(statusMessage)}</Text> */}
                {statusMessage?.metadata.executor_state == "running" && <Button size="xs" ml="-50px"
                            isLoading={interrupting}
                            onClick={() => {
                                setInterrupting(true)
                                chatService.sendCommand({ "type": "command", "action_id": "stop-process", "args": {} }, { waitForResult: true, returnResultMessage: true, timeoutSec: 120 }).then((res) => {
                                setInterrupting(false)

                                if (!res.success) {
                                    toast({
                                        title: "Interruption failed",

                                        status: "error",
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                } else {
                                    refreshInitStatus()
                                }
                            })}}
                > Stop </Button>}

            </HStack>
            </Portal>
            }
            <HStack p="0px 10px" flexShrink={0} fontWeight={500} fontSize="sm">



                {
                    <ButtonGroup size="sm">
                        {!modal && !listVersion && (
                            <>

                        {!availableCommands && <Spinner opacity={0.6} size="sm" m="0px 10px" alignSelf="center" justifySelf="center" />}

                        {/* Import */}
                        {<Menu size="sm">
                            <MenuButton variant="outline"

                                leftIcon={<LuImport size="18px" />}
                                colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                                Import
                            </MenuButton>
                            <MenuList zIndex={15}>

                                <Text m="0px 5px">List sources</Text>
                                <Stack maxH="50vh" spacing={0} overflow="auto">
                                {listRequest?.data?.sources?.map((op, i) => (

                                    <MenuItem key={i} onClick={() => {
                                        // setModal(<ListOpDialog list_id={listId} opType={op.op_type} listOp={op as any} argSchema={op.arg_schema} argValues={op.kwargs} chatService={chatService} onClose={() => { setModal(undefined) }} />)
                                    }} >

                                        <HStack border="1px solid lightgray" borderRadius={4} p="4px" width="100%" justify="space-between">
                                            <Tooltip label={op.name} >
                                                <Text maxW="200px" noOfLines={1}>
                                                    {op.name}
                                                </Text>
                                            </Tooltip>
                                            <HStack>
                                                <Tag colorScheme="black" size="sm" color="white">
                                                    <TagLabel>{op.imported_count || 0} / {op.total_records || "?"}</TagLabel>
                                                </Tag>

                                                {(op.state != "done" || op.total_records == null || (op.total_records && op.total_records) > (op.processed_count || 0)) && <Tooltip label="Import more" >
                                                    <IconButton size="xs" icon={<IconPlayerPlay size="15px" />} aria-label="Play" onClick={() => {
                                                        setModal(<RunOptionsDialog
                                                            onCancel={() => { setModal(undefined) }}
                                                            onOk={(options: any) => {
                                                                chatService.sendCommand({ "type": "command", "action_id": "op-rerun", "args": { "op_id": op.id, "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                    if (res.error || !res.success) {
                                                                        toast({
                                                                            title: "Error re-running operation",
                                                                            description: res.error,
                                                                            status: "error",
                                                                            duration: 9000,
                                                                            isClosable: true,
                                                                        })
                                                                    }
                                                                    else {
                                                                        setModal(undefined)
                                                                    }

                                                                })
                                                            }} />)


                                                    }} />

                                                </Tooltip>}
                                                <IconButton size="xs" icon={<IconX size="15px" />} aria-label="Delete" onClick={() => {
                                                    setModal(<ConfirmModal caption="Delete operation" question="Are you sure you want to delete this source and its data from the list?"
                                                        onCancel={() => { setModal(undefined) }}
                                                        onOk={() => {

                                                            chatService.sendCommand({ "type": "command", "action_id": "delete-list-source", "args": { "list_source_id": op.id } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error while deleting source",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                            })
                                                        }} />)


                                                }} />

                                            </HStack>
                                        </HStack>
                                    </MenuItem>
                                ))}
                                </Stack>

                                <MenuDivider />

                                        {availableCommands?.import?.map((opTypeInfo) => (

                                            <MenuItem key={opTypeInfo.op_type} onClick={() => showCommandDialog(opTypeInfo)}>{opTypeInfo.name}</MenuItem>
                                ))}



                            </MenuList>
                        </Menu>}

                        {/* Filter */}
                        {availableCommands?.filter && Object.keys(availableCommands?.filter) && <FilterButton
                            filters={filters}

                                    filterOps={availableCommands?.filter.map((opTypeInfo) => ({
                                        id: opTypeInfo.op_type,
                                        name: opTypeInfo.name
                            }))}
                                    isLoading={runningAction == "toggle_filter"}
                                    onFilterOp={(cmdId) => showCommandDialog(availableCommands?.filter.find(f => f.op_type == cmdId))}
                            onToggleFilter={(filterName, val) => {

                                setRunningAction("toggle_filter")

                                chatService.sendCommand({ "type": "command", "action_id": "update-metadata", "args": { "toggle_filters": { [filterName]: val } } }, { waitForResult: true }).then((res) => {


                                    refreshData().then(() => {
                                        setRunningAction("undefined")
                                    })

                                })
                            }}
                            onRemoveFilter={(filterName) => {
                                let newFilters = { ...filters }
                                delete newFilters[filterName]
                                chatService.sendCommand({ "type": "command", "action_id": "delete-filter", "args": { "filter": filterName } }, { waitForResult: true }).then((res) => {

                                    setFilters(newFilters)
                                    refreshData().then(() => {
                                        setRunningAction("undefined")
                                    })

                                })
                            }}

                        />}



                                <ButtonGroup isAttached size="sm">
                        {/* Add column */}
                        {availableCommands?.add_column && Object.keys(availableCommands?.add_column) && <Menu size="sm">
                            <MenuButton variant="outline"
                                            onClick={() => showActionSelectionDialog("Popular")}

                                leftIcon={<IconPlus size="18px" />}
                                            colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} >
                                Add column
                            </MenuButton>
                                        {/* <MenuList zIndex={15}>
                                {availableCommands?.add_column && Object.keys(availableCommands?.add_column).map((cmdId) => (

                                    <MenuItem key={cmdId} onClick={() => showCommandDialog("add_column", cmdId)}>{availableCommands?.add_column[cmdId].name}</MenuItem>
                                ))}

                            </MenuList> */}
                        </Menu>}


                        {/* Others */}
                        {availableCommands?.others && Object.keys(availableCommands?.others) && <Menu size="sm">
                            <MenuButton variant="outline" as={IconButton}
                                            onClick={() => showActionSelectionDialog("add_or_modify_column")}
                                icon={<IconDotsVertical size="18px" />}
                                colorScheme="brand" />


                                        {/* <MenuList zIndex={15}>
                                {allAvailableCommands && allAvailableCommands.filter(opTypeInfo => opTypeInfo.op_type !== "CreateCampaign").map((cmdName) => (

                                    <MenuItem key={ opTypeInfo.op_type } onClick={() => showCommandDialog( cmdName)}>{availableCommands?.others[cmdName].name}</MenuItem>
                                ))}


                            </MenuList> */}

                        </Menu>}
                                </ButtonGroup>


                        {/* Campaigns */}
                        <Menu size="sm">
                            <MenuButton variant="outline"

                                leftIcon={<IconMailFast size="25px" />}
                                colorScheme="brand" as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />}>
                                {campaignsEndpoint?.data?.length ? "Campaigns" : "New campaign"}
                            </MenuButton>
                            {availableCommands && <MenuList zIndex={15}>
                                <Text fontWeight={700} m="4px">Campaigns</Text>
                                {campaignsEndpoint?.data?.map((campaign, i) => (

                                    <MenuItem p="1px" key={i} onClick={() => {
                                        setModal(<CampaignEditor campaign={campaign as any} chatService={chatService} onClose={() => { setModal(undefined) }} />)
                                    }} >
                                        <HStack m="2px 4px" p="4px 8px" border="1px solid lightgray" borderRadius={6}>
                                            <Text>

                                            {campaign.name}
                                            </Text>
                                        </HStack>
                                    </MenuItem>
                                ))}
                                <MenuDivider />
                                <MenuItem as={Button} variant="outline" m="2px" colorScheme="brand" leftIcon={<IconPlus />} key={-1} onClick={() => {
                                            showCommandDialog(allAvailableCommands.find(op => op.op_type === "CreateCampaign"))
                                }}>New campaign</MenuItem>
                            </MenuList>}

                        </Menu>


                        <Menu size="sm">
                            <MenuButton as={IconButton}
                                onClick={() => {

                                    chatService.sendCommand({ "type": "command", "action_id": "get-executor-state", "args": {} }, { waitForResult: true }).then((res) => {
                                        setExecutorState(res.executor_state)
                                        setScheduledOps(res.scheduled_ops)
                                        setCurrentOp(res.current_op)

                                        setModal(<ConfirmModal caption="Operations" onOk={() => { setModal(undefined) }}>
                                            <ListOpsForm listOps={listRequest?.data.operations} openVersions={() => showVersions()} importOps={listRequest?.data.sources} executorState={res.executor_state} scheduledOps={res.scheduled_ops}
                                                onExecutorAction={(action) => {
                                                    return chatService.sendCommand({ "type": "command", "action_id": "change-executor-state", "args": { "change_state": action } }, { waitForResult: true }).then(() => {
                                                        refreshData()
                                                        setModal(undefined)
                                                    })
                                                }}
                                                onOpAction={(op, action, options) => {
                                                    if (op == "all") {
                                                        if (action == "rerun") {
                                                            return chatService.sendCommand({ "type": "command", "action_id": "op-rerun-all", "args": { "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error re-running operation",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                            })
                                                        }
                                                    }
                                                    else if (action === "rerun") {
                                                        return new Promise((resolve, reject) => {

                                                            chatService.sendCommand({ "type": "command", "action_id": "op-rerun", "args": { "op_id": op.id, "$metadata": options } }, { waitForResult: true, returnResultMessage: true }).then((res) => {
                                                                if (res.error || !res.success) {
                                                                    toast({
                                                                        title: "Error re-running operation",
                                                                        description: res.error,
                                                                        status: "error",
                                                                        duration: 9000,
                                                                        isClosable: true,
                                                                    })
                                                                }
                                                                else {
                                                                    setModal(undefined)
                                                                }

                                                    })
                                                        })

                                                    }
                                                    else if (action === "retry") {
                                                        let cmd = findAvailableCommand(op.op_type)
                                                        return new Promise((resolve, reject) => {
                                                            setModal(<ListOpDialog list_id={listId} opType={op.op_type} listOp={op as any} argSchema={op.arg_schema} argValues={op.kwargs} chatService={chatService}
                                                                interactive={cmd?.interactive}
                                                                level_group={levelGroup}
                                                                supports_preview={cmd?.supports_preview}
                                                                supports_prompting={cmd?.supports_prompting}
                                                                onSave={(listOp) => {
                                                                    return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, update: listOp } }, { waitForResult: true }).then(() => {


                                                                    })
                                                                }}
                                                                onClose={closeDialogCallback} />)
                                                        })
                                                    }
                                                    else if (action === "cancel") {
                                                        return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, "change_state": action } }, { waitForResult: true }).then(() => {
                                                            refreshData()
                                                            setModal(undefined)
                                                        })
                                                    }
                                                    else if (action === "delete") {
                                                        return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, "change_state": "delete" } }, { waitForResult: true }).then(() => {
                                                            refreshData()
                                                            setModal(undefined)
                                                        })
                                                    }
                                                    else if (action === "update") {
                                                        return chatService.sendCommand({ "type": "command", "action_id": "change-op-state", "args": { "op_id": op.id, update: options } }, { waitForResult: true }).then(() => {


                                                        })

                                                    }

                                                }}

                                            />
                                        </ConfirmModal>)
                                    })
                                }}
                                        icon={<HStack m="0px 6px"><IconHistory size="18px" />{executorState == "running" ? <Spinner size="xs" /> : (executorState == "paused" ? (<IconPlayerPauseFilled size="18px" />) : <></>)}</HStack>}
                            />
                        </Menu>
                        <Tooltip label="Export" aria-label="Export">
                            <Menu size="sm" >
                                <MenuButton as={Button} isLoading={exportEndpoint.isRunning} rightIcon={<IconChevronDown size="15px" />} p="2px 6px" >
                                    <IconFileDownload size="22px" color="darkslategray" />
                        </MenuButton>
                                <MenuList zIndex={15} fontWeight={500}>
                            <MenuItem onClick={() => exportList("csv")}>Export to csv</MenuItem>
                            <MenuItem onClick={() => exportList("xlsx")}>Export to xlsx</MenuItem>
                            <MenuItem onClick={() => exportList("json")}>Export to json</MenuItem>
                        </MenuList>
                    </Menu>
                        </Tooltip>
                            </>
                        )}
                        {/* <Button
                        onClick={() => {}}
                        >Share</Button> */}
                        <ColumnsSelector

                            columns={currentListData?.columns?.map((col) => col.name)}
                            hiddenColumns={hiddenColumns}
                            saveMetadata={(newCols) => {
                                const allColumns = currentListData?.columns?.map(col => col.name) || [];
                                const change_column_visibility = {};
                                let willBeShown = false
                                const hiddenCols = currentListData?.columns?.filter(c => c.default_hidden).map(c => c.name)
                                if (newCols.some(col => hiddenCols.includes(col))) {
                                    willBeShown = true;
                                }
                                allColumns.forEach(columnName => {
                                    const wasHidden = hiddenColumns.includes(columnName);
                                    const willBeHidden = !newCols.includes(columnName);
                                    change_column_visibility[columnName] = !willBeHidden;
                                });
                                if (!levelGroup) {




                                    saveMetadata({ change_column_visibility, change_column_order: newCols });
                                }
                                else {
                                    saveView(levelGroup, change_column_visibility).then(() => {
                                        if (willBeShown) {
                                            refreshData()
                                        }
                                        else {
                                            setHiddenColumns(allColumns.filter(col => !newCols.includes(col)))
                                            setColumnOrderOverride(newCols)
                                        }

                                    })
                                }
                            }}
                            // setColumnOrder={setColumnOrderOverride}
                            // setHiddenColumns={setHiddenColumns}
                            columnOrder={columnOrderOverride}
                        />



                    </ButtonGroup>} 

                {/* {
                    listVersion && (
                        <ButtonGroup>
                            <Button size="sm"
                                variant="outline"
                                leftIcon={<IconChevronLeft size="15px" />}
                                colorScheme="brand"
                                isDisabled={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    navigate(`/lists/${listId}`)
                                }}
                            >Back to current version</Button>

                            <Button size="sm"
                                colorScheme="brand"
                                isLoading={restoreVersionEndpoint.isRunning}
                                onClick={() => {
                                    restoreVersionEndpoint.execute({}).then(() => {
                                        navigate(`/lists/${listId}`)
                                    })
                                }}
                            >Restore this version</Button>
                        </ButtonGroup>
                    )} */}


            </HStack>
        </HStack>



        <GeniouslyThemeProvider>
            <ListProvider columns={currentListData?.columns} list_data={currentListData?.data_page}>



            {modal}
            {(listRequest.isLoading) ? <Stack m="50px" minHeight="40vh" width="auto"
                align="center" justify={"center"}
                border="1px solid #c8c8c8" borderRadius={"5px"}
            >

                {listRequest.isLoading ? (
                    <Box opacity={0.4}>
                        <Spinner size="xl" />
                    </Box>
                ) : (

                    <Stack direction="column" spacing="10px" width="50%" p="4px" border="c8c8c8" shadow="dark-lg" borderRadius="4px" background="white">


                        <ChatInput placeholderText="What list are we gonna build?" onSend={() => { }} />

                    </Stack>
                )}
            </Stack>
                : (
                        <Stack m="0px 4px" width="auto" align="center" justify={"start"}

                        // background={data?.length ? "white" : tableBackgroundCss}
                    >

                            <Stack mb="10px" border="2px solid #c8c8c8" borderRadius={"5px"} height="calc(100vh - 190px)" width="100%" background={!data?.length ? tableBackgroundCss : "white"}>
                                {/* <Text>{JSON.stringify(hiddenColumns) || "-"}</Text> */}

                        <AutoUI
                            layout="table"
                                onValueChange={(val) => {
                                    setPendingUpdate(val)
                                }}

                                    value={pendingUpdate || data}

                                    excludeFields={hiddenColumns}
                                    schema={columnsSchema ? { type: "array", items: columnsSchema } : undefined}
                            />


                            </Stack>
                            {!listPreviewAwaitingApproval && <HStack justify="end" alignSelf="end">
                            <Stack align="end" spacing={0}>
                                <Menu >
                                    <MenuButton as={Button} size="xs" rightIcon={<IconChevronDown size="15px" />}>
                                        {100 * (pageNo - 1) + 1}-{100 * (pageNo) > currentListData?.total_count ? currentListData?.total_count : 100 * (pageNo)}
                                    </MenuButton>
                                    <MenuList  >

                                            {Array.from({ length: Math.floor((currentListData?.filtered_count || currentListData?.total_count) / 100) + 1 }, (_, i) => i * 100).map((startRange) => (

                                            <MenuItem fontSize="xs" justifySelf="start" key={startRange} onClick={() => setPageNo((startRange / 100) + 1)}>{startRange} - {startRange + 100 > currentListData.total_count ? currentListData.total_count : startRange + 100}</MenuItem>
                                        ))}

                                    </MenuList>
                                </Menu>
                                    <HStack fontSize="xs"><Text > Total rows:</Text>
                                        {(currentListData?.filtered_count >= 0 && currentListData?.filtered_count != currentListData?.total_count) ?
                                            <>
                                                <Tooltip label="Total rows after filters"><Text fontWeight={900}>{currentListData?.filtered_count}</Text></Tooltip>

                                                <Tooltip label="Total unfiltered count"><Text color="gray" fontWeight={500}>(of {currentListData?.total_count})</Text></Tooltip>
                                            </> : <Text>{currentListData?.total_count}</Text>}
                                    </HStack>

                            </Stack>
                            <IconButton aria-label="Previous page" icon={<IconChevronLeft />} onClick={() => setPageNo(pageNo - 1)} />
                            <IconButton aria-label="Next page" icon={<IconChevronRight />} onClick={() => setPageNo(pageNo + 1)} />
                        </HStack>}

                        {!listVersion && <Stack direction="column" spacing="0px" width="50%" position="absolute" bottom={0}  >



                            <GeniouslyCommander

                                appId={"default"}
                                open={modal ? false : undefined}


                                onSessionChanged={(sessionId) => {
                                    if (sessionId != listId) {
                                        navigate(`/chat/${sessionId}`)
                                    }
                                }}
                                renderWidgets={handleWidgetRendering}

                                onStatusUpdate={(statusMsg: StatusMessage) => {
                                    setInterrupting(false)
                                    if (!statusMsg.metadata?.preview && statusMsg.metadata.executor_state && (!statusMsg.metadata.level_group || statusMsg.metadata.level_group == levelGroup)) {

                                        updateCurrentListData(statusMsg.metadata.update, statusMsg.metadata.schema)
                                        setExecutorState(statusMsg.metadata.executor_state)
                                        setScheduledOps(statusMsg.metadata.scheduled_ops)
                                        setStatusMessage(statusMsg as any)
                                    }
                                    if (statusMsg?.status_type != "waiting") {
                                        if (statusMsg.metadata?.current_op?.state && !["init", "running"].includes(statusMsg.metadata?.current_op?.state)) {
                                            listRequest.mutate()
                                        }
                                        //setStatusMessage(statusMsg as any)
                                    }                                    
                                    else {
                                        setCurrentOp(false)
                                        setStatusMessage(undefined)

                                    }
                                }}
                                onNewMessageReceived={(msg) => {
                                    setStatusMessage(undefined)
                                    if (msg?.widgets?.length && msg.widgets[0].widget_type == "list_preview") {
                                        setCurrentOp(false)
                                        setListPreviewAwaitingApproval(msg.widgets[0] as any)
                                    }
                                }}

                                onExecuteAction={(actionId, args) => {
                                    if (actionId == "refresh") {
                                        refreshData()
                                        refreshInitStatus()


                                        return Promise.resolve({ success: true })
                                    }

                                }}
                                apiUrl={process.env.REACT_APP_API_URL}
                                sessionId={listRequest?.data?.id}
                                visitorId={"-"}
                                inputPlaceholder={statusMessage ? (statusMessage.message || statusMessage.status_type) : (data?.length ? "What data should we add to this list?" : "What should we do with this data ?")}
                                useChatService={(service) => setChatService(service as ChatService)}
                                collectContext={() =>
                                    Promise.resolve({
                                        localTime: new Date().toString(),
                                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                    })
                                }
                                authDataRetriever={() => getApi().authorize()}
                            //onExecuteAction={(actionId, args) => onExecuteAction(actionId, args)}
                            />
                        </Stack>}

                    </Stack>

                )}
            {/* <AutoUI layout="table" value={[]} schema={{ type: "object", properties: { name: { type: "string" }, age: { type: "number" } } }} /> */}

            </ListProvider> 
        </GeniouslyThemeProvider>


    </Stack>

    )
}

const CampaignEditor = ({ campaign, onClose, chatService }: { chatService: any, campaign: Campaign, onClose: () => any }) => {
    const [error, setError] = useState<string>()
    function onSave(campaign: Campaign, options?: { updateMode: "updateFilters" | "preserveFilters" | "preserveSelection" }) {
        let metadata = {}
        if (options?.updateMode == "updateFilters") {
            metadata["override_filter_current_view"] = true
        }
        else if (options?.updateMode == "preserveSelection") {
            metadata["update_only_existing_rows"] = true
        }
        return chatService.sendCommand({
            "type": "command", "action_id": "accept-preview", "args": {
                "list_op": {
                    op_type: "CreateCampaign",
                    stages: [{ id: "init", name: "init", args: { campaign: campaign } }]
                }
                ,
                "$metadata": metadata
            },


        }, { waitForResult: true, returnResultMessage: true }).then((res: CommandResultMessage) => {
            if (res.success) {
                onClose()
            }
            else if (res.error) {
                setError(res.error)
            }
            else if (res.result?.command_accepted) {
                setError("Error approving changes")
            }
        })
    }
    return (
        <CampaignBuilder campaign={campaign} error={error} onCancel={onClose} onOk={onSave} />

    )
}

