import { Button, CloseButton, HStack, Stack, Tag, TagLabel, Text, Tooltip } from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import React from 'react';

import ColumnPicker from './columnPicker';
import { EditableField } from '../EditableField/editableField';
import { WithValidationTag } from '../ErrorValidation/validationTag';

interface ColumnMappingsProps {
    mapping: { [key: string]: string };
    onChange: (mapping: Record<string, string>) => void;
    standardColumns: { name: string, title: string, description?: string, type_filter?: "string" | "number" | "boolean" | "array" | undefined }[];
    allowExtraColumns: boolean;
    extraColumnsKey?: string;
    errors?: Record<string, string>;
    readOnly?: boolean;
}

const ColumnMappings: React.FC<ColumnMappingsProps> = ({
    mapping,
    onChange,
    readOnly,
    standardColumns,
    allowExtraColumns,
    extraColumnsKey = "extra",
    errors
}) => {

    const [addingNew, setAddingNew] = React.useState("")
    return (
        <Stack p="0px 8px">
            <Table size="xs">

                <Tbody>

                    {standardColumns.map((column, index) => (
                        <Tr key={index}>
                            <Td>
                                <Tooltip label={column.description}>
                                    <HStack>

                                        <Text fontWeight={600} fontSize="xs" whiteSpace="nowrap">
                                            {column.title || column.name}

                                        </Text>
                                        {column.description && <Text fontSize="xs" color="gray.500">?</Text>}
                                    </HStack>
                                </Tooltip>
                            </Td>
                            <Td>
                                {readOnly ? (mapping && mapping[column.name] && <Tag m="2px"><TagLabel>{(mapping && mapping[column.name]) || ""}</TagLabel></Tag>) :
                                    <WithValidationTag errors={errors} keyPath={column.name}>
                                        <ColumnPicker value={(mapping && mapping[column.name]) || ""} onSelected={(val) => onChange({ ...(mapping || {}), [column.name]: val })} />
                                    </WithValidationTag>}
                            </Td>


                        </Tr>
                    ))}

                    {allowExtraColumns && (

                        mapping && mapping[extraColumnsKey] && Object.keys(mapping[extraColumnsKey]).map((key, i) => (
                            <Tr key={key}>
                                <Td>

                                    <HStack spacing={0}>

                                        <EditableField value={key} size="sm" placeholder='Field name' onApply={(val) => {
                                            let extra: any = {}
                                            Object.entries(mapping[extraColumnsKey]).forEach(([k, v], idx) => {
                                                if (idx == i) {
                                                    extra[val] = mapping[extraColumnsKey][key]
                                                }
                                            })
                                            onChange({ ...mapping, [extraColumnsKey]: extra })
                                        }} />
                                        {!readOnly && <CloseButton size="sm" onClick={() => {
                                            const newMapping = { ...mapping }
                                            delete newMapping[extraColumnsKey][key]
                                            onChange(newMapping)
                                        }} />}

                                    </HStack>

                                </Td>
                                <Td>

                                    <ColumnPicker value={(mapping && mapping[key]) || ""} onSelected={(val) => onChange({ ...(mapping || {}), [key]: val })} />

                                </Td>
                            </Tr>
                        ))
                    )}
                    {allowExtraColumns && !readOnly && (
                        <Tr>
                            <Td>
                                <EditableField value={addingNew} size="sm" placeholder='Add new field'
                                    onChange={setAddingNew}
                                    onApply={(val) => {
                                        if (!val) return
                                        let _mapping = { ...(mapping || {}) }
                                        onChange({ ..._mapping, [extraColumnsKey]: { ...(_mapping[extraColumnsKey] as any || {}), [val]: "" } })
                                        setAddingNew("")
                                    }} />
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </Stack>
    );
};

export default ColumnMappings;