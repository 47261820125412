
import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    Flex,
    Spinner,
    FormControl,
    FormLabel,
    Input,
    Button,
    useToast,
    Stack
} from "@chakra-ui/react";
import { AutoUI, GeniouslyThemeProvider } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";

export interface ContactStatusSettings {
    id: string;
    name: string;
    description?: string;
    color?: string;
    requires_attention: boolean;
    auto_label: boolean;
}

interface TenantSettings {
    tenant_id?: string;
    contact_statuses: ContactStatusSettings[];
    send_email_notifications: boolean;
    discord_notifications_webhook?: string | null;
    slack_notifications_webhook?: string | null;
}



export default function LabelSettings() {
    const settingsEndpoint = useApiFetch<TenantSettings>("/org/settings", { static: true })
    const saveSettingsEndpoint = useApiEndpoint<TenantSettings>("POST", "/org/settings")
    const [contactStatuses, setContactStatuses] = useState<ContactStatusSettings[]>([]);

    useEffect(() => {
        if (settingsEndpoint.data) {
            setContactStatuses(settingsEndpoint.data.contact_statuses);
        }
    }, [settingsEndpoint.data]);

    const hasChanges = React.useMemo(() => {
        if (!settingsEndpoint.data) return false;
        return JSON.stringify(contactStatuses) !== JSON.stringify(settingsEndpoint.data.contact_statuses);
    }, [contactStatuses, settingsEndpoint.data]);
    const isValid = React.useMemo(() => {
        return contactStatuses.every(status => status.name && status.name.trim().length > 0);
    }, [contactStatuses]);

    function saveSettings() {
        const updatedStatuses = contactStatuses.map(status => ({
            ...status,
            id: status.id || status.name.toLowerCase().replace(/\s+/g, '-')
        }));
        saveSettingsEndpoint.execute({}, {
            contact_statuses: updatedStatuses
        }).then(() => {
            settingsEndpoint.mutate();
        })
    }


    if (settingsEndpoint.isLoading) {
        return (
            <Flex align="center" justify="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    return (
        <Box p={4}>
            <Heading mb={4}>Label Settings</Heading>
            <GeniouslyThemeProvider>

                <AutoUI value={contactStatuses}
                    onValueChange={(value) => {

                        setContactStatuses(value);
                    }}
                    allowAddNew
                    showOnlySchemaFields
                    schema={{
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                name: {
                                    type: "string",
                                    title: "Name"
                                },
                                description: {
                                    type: "string",
                                    title: "Description"
                                },
                                color: {
                                    type: "string",
                                    title: "Color",

                                },
                                requires_attention: {
                                    type: "boolean",
                                    title: "Requires Attention"
                                },
                                auto_label: {
                                    type: "boolean",
                                    title: "Auto Label"
                                }
                            }
                        }
                    }}
                />
            </GeniouslyThemeProvider>
            <Stack align="end">
                {hasChanges && <Button onClick={() => {
                    saveSettings()
                }}
                    colorScheme={"brand"}
                    isDisabled={!isValid}
                    mt={4}
                >
                    Save
                </Button>}
            </Stack>
        </Box>
    );
}