
import React, { useEffect, useState } from 'react';
import { Editable, EditableInput, EditablePreview, Button, ButtonGroup, HStack, Stack, Text, Spinner, IconButton } from "@chakra-ui/react";
import { IconReload } from '@tabler/icons-react';

interface ListHeaderProps {
    listName: string;
    setListName: (name: string) => void;
    levelGroup: "companies" | "people" | string;
    setLevelGroup: (group: "companies" | "people" | string) => void;
    pendingUpdate: any;
    isSaving: boolean;
    save: () => void;
    listRequest: any;
    levels?: string[]
    setPendingUpdate: (update: any) => void;
    isLoading?: boolean; // Added isLoading prop
    onReload?: () => void; // Added onReload prop
}

const ListHeader: React.FC<ListHeaderProps> = ({
    listName,
    setListName,
    levels,
    levelGroup,
    setLevelGroup,
    pendingUpdate,
    isSaving,
    save,
    listRequest,
    setPendingUpdate,
    isLoading, // Added isLoading prop
    onReload // Added onReload prop
}) => {
    const [listNameLocal, setListNameLocal] = useState(listName);
    const [listNameEditFocus, setListNameEditFocus] = useState(false);


    useEffect(() => {
        setListNameLocal(listName);
    }, [listName]);

    return (
        <HStack align="top">
            {!listName ? <Text>Loading...</Text> :
                <Stack spacing={0}>
                    <Editable value={listNameLocal} ml="10px">
                        <EditablePreview flexShrink={1} flexGrow={0} textOverflow="ellipsis" whiteSpace="nowrap" />
                        <EditableInput width={`${listNameLocal?.length}ch`} flexShrink={1} flexGrow={1} onChange={(e) => setListNameLocal(e.target.value)}
                            onFocus={() => setListNameEditFocus(true)}
                            onBlur={() => {
                                setListNameEditFocus(false)
                                setListName(listNameLocal)
                            }} />
                    </Editable>
                    {levels?.length && <ButtonGroup p="2px" borderRadius="8px" border="1px solid gray" alignSelf="start" m="2px" spacing={1} ml="5px" size="xs">
                        {levels.map((level, index) => (

                            <Button onClick={() => setLevelGroup(level)} color={levelGroup != level ? "gray" : undefined}
                                colorScheme={levelGroup == level ? "brand" : undefined}>
                                {level.charAt(0).toUpperCase() + level.slice(1)}
                            </Button>
                        ))}

                    </ButtonGroup>}
                </Stack>
            }
            {!listNameEditFocus && <>
                {!isLoading && !listNameEditFocus && (pendingUpdate || listName != listRequest.data?.name) && <ButtonGroup>
                    <Button isLoading={isSaving} onClick={() => save()} size="sm" colorScheme="brand">Save</Button>
                    <Button isDisabled={isSaving} onClick={() => {
                        setPendingUpdate(undefined);
                        listRequest.mutate();
                        setListName(listRequest.data.name);
                    }} size="sm" colorScheme="blackAlpha">Undo</Button>
                </ButtonGroup>
                }

                {isLoading ? (
                    <Spinner m="6px" size="sm" />
                ) : (
                    <IconButton
                        onClick={() => onReload()}
                        m="4px" size="xs" variant="ghost" aria-label='reload' opacity="0.5" _hover={{ opacity: 1 }} icon={<IconReload size="18px" />} />
                )}
            </>}
        </HStack>
    );
};

export default ListHeader;