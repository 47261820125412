



import { useEffect, useMemo, useState } from "react";


import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, WrapItem, Wrap, Link, ButtonGroup, Button, CloseButton, Checkbox } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import GenericList from "../components/GenericList/genericList";

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useApiEndpoint, useApiFetch } from "../utils/useApiHook";
import ContactCard from "../components/Inbox/ContactCard";
import { IconAddressBook, IconCheckbox, IconChevronDown, IconChevronLeft, IconSend } from "@tabler/icons-react";
import { CampaignDetail } from "../components/Campaigns/analyticsDetail";
import { FaReply } from "react-icons/fa";
import { CampaignStatsWidget } from "../components/Campaigns/campaignStatsWidgets";
import CampaignAnalysis from "../components/Campaigns/campaignAnalysis";



export default function CampaignAnalyticsPage() {

    const { currentUser, logOut } = useAppContext()

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { campaignId } = useParams()

    // useEffect(()=>{
    //     if (currentUser && searchParams.get("force")!="true" ){
    //         navigate("/home")
    //     }
    //     else{
    //         navigate("/login")
    //     }
    // },[currentUser])

    const getCampaignEndpoint = useApiFetch(`/analytics/campaigns/${campaignId}`, { shouldFetch: !!campaignId })


    const [selectedCampaign, setSelectedCampaign] = useState<any>(undefined)
    useEffect(() => {
        setSelectedCampaign(getCampaignEndpoint.data)
    }, [getCampaignEndpoint.data])
    // useEffect(() => {
    //     if (campaignId && !(selectedCampaign?.id == campaignId) || (selectedCampaign && !selectedCampaign.stats)) {
    //         getCampaignEndpoint.execute({ campaignId }).then((data) => {
    //             setSelectedCampaign(data)
    //         }).catch((err) => {
    //             setSelectedCampaign({ id: campaignId, name: "Campaign not found", stats: {} })
    //         })
    //     }
    //     else if (!campaignId) {
    //         setSelectedCampaign(undefined)
    //     }
    // }, [campaignId, selectedCampaign])

    const [analysisMode, setAnalysisMode] = useState<"select" | "analyse" | null>()
    const [selection, setSelection] = useState<string[]>([])
    const [filterQuery, setFilterQuery] = useState<{ query: string, extraFilter: any }>()
    const dataEndpoint = useApiFetch<CampaignStats[]>(`/analytics/campaigns`, { shouldFetch: true, queryArgs: filterQuery?.query ? { q: filterQuery.query, ...filterQuery.extraFilter } : undefined })
    const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignStats[]>([])

    if (analysisMode == "analyse") {
        return <Stack>
            <Button alignSelf="start" m="0px 8px" leftIcon={<IconChevronLeft />} onClick={() => setAnalysisMode(undefined)}>Back</Button>
            <CampaignAnalysis selectedCampaigns={selectedCampaigns} />
        </Stack>
    }
    else
    return (
        <HStack align="stretch" justify="stretch" height="calc(100% - 5px)" maxW={["100vh", "100vw", "calc(100vw - 40px)"]} width="100%">
            <GenericList dataEndpoint={dataEndpoint} listTitle="Campaign analytics" primaryField="campaign"
                onQueryChanged={(query, extraFilter) => {

                    setFilterQuery({ query, extraFilter })
                }}
                onSelected={(item) => { }}
                selectedId={campaignId}
                buttons={
                    analysisMode ? (
                        <>
                            <CloseButton onClick={() => setAnalysisMode(undefined)} />
                            <Button colorScheme="brand" isDisabled={!selection?.length} onClick={() => {
                                setSelectedCampaigns(dataEndpoint.data?.filter((item) => selection.includes(item.campaign_id)))
                                setAnalysisMode("analyse")
                            }}>Analyse</Button>
                            <Button colorScheme="brand" isDisabled={!dataEndpoint.data?.length} variant="outline"
                                onClick={() => {
                                    if (selection.length == dataEndpoint.data?.length) {
                                        setSelection([])
                                    }
                                    else
                                        setSelection(dataEndpoint.data?.map((item) => item.campaign_id))
                                }}
                            >{selection.length !== dataEndpoint.data?.length ? "Select all" : "Clear selection"}</Button>

                        </>
                    ) : (<Button colorScheme="brand" variant="outline" onClick={() => setAnalysisMode("select")}>Select for analysis</Button>)}
                customCardRenderer={
                    (item: {
                        campaign_id?: string;
                        name: string;
                        contacts_count?: number;
                        contacted?: number;
                        canceled?: number;
                        accepted_invite?: number;
                        replied?: number;
                        last_timestamp?: Date;
                        state: string;
                    }, index) => {

                        return (
                            <HStack key={index} border={item.campaign_id == campaignId ? "1px solid gray" : "1px solid lightgray"} borderRadius={8} p="6px"
                                bg={item.campaign_id == campaignId ? "white" : "gray.50"}
                                align="start"
                                onClick={() => {
                                    if (analysisMode == "select") {
                                        if (selection.includes(item.campaign_id)) {
                                            setSelection(selection.filter((id) => id != item.campaign_id))
                                        }
                                        else {
                                            setSelection([...selection, item.campaign_id])
                                        }
                                    }
                                    else {
                                        navigate("/analytics/" + item.campaign_id)
                                        setSelectedCampaign(item)
                                    }
                                }}

                            >
                                {analysisMode == "select" && (
                                    <Checkbox isChecked={selection.includes(item.campaign_id)}
                                    />
                                )}
                                <Stack >
                                    <HStack>
                                        <Text fontSize="md"> {item.name}</Text>
                                    </HStack>
                                    {true && (
                                        <CampaignStatsWidget stats={item} size={campaignId ? "sm" : "lg"} />
                                    )}
                                    <HStack justify="space-between" >
                                        <Stack flexGrow={1} fontSize="xs">
                                            {item.last_timestamp && <Text fontSize="xs" fontWeight={500}><Moment fromNow>{item.last_timestamp}</Moment></Text>}
                                            {/* <Text fontSize="2xl" textAlign="end" fontWeight={900}>{Math.round((item.goal_achieved / item.contacts) * 100)}%</Text> */}
                                        </Stack>
                                        {/* <Tag>{item.state}</Tag> */}

                                    </  HStack>

                                </Stack>

                            </HStack>
                        )
                    }

                }
            />
            <Box width="0px" m="-4px -10px" top="0px" zIndex={5}>

                <CloseButton size="sm" onClick={() => { navigate("/analytics/") }} />
            </Box>
            {selectedCampaign && (
                <CampaignDetail selectedCampaign={selectedCampaign} onRefreshRequested={() => { getCampaignEndpoint.mutate() }} />
            )}

        </HStack>
    );
}


