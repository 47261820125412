import { Accordion, AccordionButton, AccordionItem, AccordionPanel, ButtonGroup, HStack, IconButton, Stack, Tag, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import STEP_TYPES from './stepTypes';
import ColumnPicker from './columnPicker';
import { EditableField } from '../EditableField/editableField';
import ColumnsAwareTextArea from '../ColumnsAwareTextArea/columnsAwareTextArea';
import OptionsSwitch from '../OptionsSwitch/optionsSwitch';
import { MarkdownContent } from 'geniously-chat-ui';
import { useListContext } from './listContext';
import { IconChevronLeft, IconChevronRight, IconSettings } from '@tabler/icons-react';
import { CampaignStepExtendedSettings } from './campaignSteps';
import { useCampaignContext } from './campaignContexts';



const CampaignStagePreview = ({ campaignStage, items: input_items, onChange }: {
    campaignStage: CampaignStage
    items?: any[]
    onChange: (step: CampaignStage) => void
}) => {
    const listContext = useListContext();
    const items = input_items || listContext?.list_data;
    const [regime, setRegime] = React.useState<"edit" | "preview">("edit");
    const [previewIndex, setPreviewIndex] = React.useState(0);
    function formatString(template, context) {
        return template?.replace(/{([\w\s]+)}/g, (match, key) => {
            key = key.trim(); // Remove extra whitespace around the key
            return typeof context[key] === 'string' ? context[key] : "";
        }) || "";
    }
    const campaignContext = useCampaignContext();
    const messagePreview = useMemo(() => {
        if (!(regime == "preview" && items)) {
            return null;
        }

        const item = items[previewIndex];
        if (!item) {
            return null;
        }
        if (campaignStage.source_column) {
            return item[campaignStage.source_column] || null;
        }
        return campaignStage.message ? formatString(campaignStage.message, item) : "";

    }, [campaignStage.message, previewIndex, campaignStage.source_column, regime, items]);

    const subjectPreview = useMemo(() => {
        if (!(regime == "preview" && items && campaignStage.step_type?.includes("email"))) {
            return null;
        }

        const item = items[previewIndex];
        if (!item) {
            return null;
        }
        if (campaignStage.subject_source_column) {
            return item[campaignStage.subject_source_column] || null;
        }
        return formatString(campaignStage.subject, item);

    }, [campaignStage.subject, previewIndex, campaignStage.source_column, regime, items]);
    const replySubject = useMemo(() => {
        if (campaignContext?.currentCampaign && campaignStage.step_type == "email-reply") {
            let subject = "";
            for (const item of campaignContext.currentCampaign.steps) {
                if (item.step_type === "email-message") {
                    subject = item.subject;
                }
                if (item.step_id === campaignStage.step_id) {
                    break;
                }
            }
            return "RE: " + subject;
        }
    }, [campaignStage.step_type])

    return (
        <Stack p="10px">
            <Accordion allowToggle>
                <AccordionItem>
                    <AccordionButton >
                        <Stack width="100%">

                            <HStack alignSelf="end">
                                <Text fontSize="xs">Settings</Text>
                                <IconSettings />

                            </HStack>
                        </Stack>
                    </AccordionButton>
                    <AccordionPanel>
                        <Stack>

                            <Stack spacing={2} p="10px" background="white" alignSelf="end" borderRadius={8}>
                                <CampaignStepExtendedSettings step={campaignStage} onChange={onChange} />
                            </Stack>
                        </Stack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <Stack spacing={1} p="10px">
                <HStack spacing={2} justify="space-between">
                    <HStack fontSize="sm">
                        {campaignStage.step_type && STEP_TYPES[campaignStage.step_type]?.icon}
                        <Text>{STEP_TYPES[campaignStage.step_type]?.name}</Text>
                    </HStack>
                    <OptionsSwitch options={["preview", "edit"]} onChange={setRegime} value={items ? regime : "edit"} alignSelf="end" />
                </HStack>
                <Stack shadow="xl" border="1px solid black" borderRadius="10px 0px 10px 10px" p="10px" background="white">

                    <Stack>
                        {(campaignStage.step_type?.includes("email")) &&
                            <Stack spacing={0}>
                                <Text fontSize="xs" fontWeight={600} p="2px" backgroundColor="white" >Subject</Text>
                                {regime === "edit" && campaignStage.step_type == "email-message" ?
                                    <ColumnsAwareTextArea
                                        value={campaignStage.subject_source_column ? `{${campaignStage.subject_source_column}}` : campaignStage.subject}
                                        onApply={(subject) => {
                                            subject = subject || ""
                                            if (subject.trimStart().startsWith("{") && subject.trimEnd().endsWith("}")) {
                                                onChange({ ...campaignStage, subject_source_column: subject.slice(1, -1) })
                                            }
                                            else {
                                                onChange({ ...campaignStage, subject: subject, subject_source_column: null })
                                            }
                                        }}
                                        showHelp supportTemplateVariables supportMentions={false} />
                                    :
                                    <Stack border="1px solid gray" borderRadius="6px">
                                        <Text fontSize="xs" fontWeight={600} p="2px" >{subjectPreview || replySubject || "n/a"}</Text>
                                    </Stack>
                                }
                            </Stack>
                        }
                        <Stack spacing={0}>
                            <Text fontSize="xs" fontWeight={600} p="2px" backgroundColor="white" >Message </Text>
                            {regime == "edit" ? (
                                <ColumnsAwareTextArea value={campaignStage.source_column ? `{${campaignStage.source_column}}` : campaignStage.message}
                                    onApply={(text) => {
                                        text = text || ""
                                        if (text.trimStart().startsWith("{") && text.trimEnd().endsWith("}")) {
                                            onChange({ ...campaignStage, source_column: text.trimStart().trimEnd().slice(1, -1), message: undefined })
                                        }
                                        else {
                                            onChange({ ...campaignStage, message: text, source_column: null })
                                        }
                                    }} showHelp supportTemplateVariables supportMentions={false} />

                            ) : (
                                <Stack border="1px solid gray" borderRadius="6px" p="6px">
                                    {messagePreview !== null ? <MarkdownContent>{messagePreview}</MarkdownContent> : <Stack p="20px" align="center" borderRadius={8}><Text>Preview not available</Text></Stack>}
                                </Stack>
                            )}
                        </Stack>

                    </Stack>
                </Stack>
                {regime == "preview" && items && <HStack alignSelf="end">
                    <ButtonGroup size="xs">
                        <IconButton aria-label='previous' isDisabled={previewIndex <= 0} onClick={() => setPreviewIndex(Math.max(0, previewIndex - 1))} icon={<IconChevronLeft />} />
                        <Tag>{previewIndex + 1}</Tag>
                        <IconButton aria-label='next' isDisabled={previewIndex >= items.length - 1} onClick={() => setPreviewIndex(Math.min(items.length - 1, previewIndex + 1))} icon={<IconChevronRight />} />
                    </ButtonGroup>

                </HStack>}
            </Stack>
        </Stack >
    );
};

export default CampaignStagePreview;