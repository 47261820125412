
import {
    Tabs, TabList, DrawerBody,
    DrawerFooter, TabPanels, Tab, TabPanel, Tag, HStack, Checkbox, Button, Link, Tooltip, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, Stack, Text, Box
} from '@chakra-ui/react'
import { IconBrandLinkedin } from '@tabler/icons-react';
import Moment from 'react-moment';
import { MessageWithContact } from './dashboardModel'


const MessageCard = ({ message, checkbox, isChecked, onCheckedChanged, onClick }: { message: MessageWithContact, checkbox?: boolean, isChecked?: boolean, onCheckedChanged?: (val: boolean) => any, onClick?: () => void }) => {
    return <HStack align="start">
        {checkbox && <Checkbox colorScheme="brand" isChecked={isChecked} onChange={() => {
            onCheckedChanged && onCheckedChanged(!isChecked)
        }} />}
        <Stack
            cursor={onClick ? "pointer" : "default"}
            onClick={onClick}
            flexGrow={1} fontWeight={500} spacing={0} border="1px solid lightgray" backgroundColor="white" p="6px 10px" shadow="md" borderRadius="0px 10px 10px">
            <HStack justify="space-between" mb="4px">
                <HStack flexGrow={1} spacing={2}>
                    <Link href={`/inbox/${message.contact?.id}`} target="_blank" >
                        <Text>{message.contact?.full_name}</Text>
                    </Link>
                    {message.contact?.linkedin_url && <Link href={message.contact?.linkedin_url} target="_blank" >
                        <IconBrandLinkedin color="blue" size="18px" />
                    </Link>}
                </HStack>
                <Stack fontWeight={500} fontSize="xs" color="gray" textAlign="end">
                    <Tooltip label={<Moment>{message.message?.scheduled_send_time || message.message?.timestamp}</Moment>} >
                        <Box>
                            <Moment fromNow>{message.message?.scheduled_send_time || message.message?.timestamp}</Moment>
                        </Box>
                    </Tooltip>
                </Stack>
            </HStack>
            <Stack flexGrow={1}>{message.message?.subject}</Stack>
            <Box><Text fontSize="sm" fontWeight={400} whiteSpace="pre-wrap">{message.message?.text}</Text></Box>
        </Stack>
    </HStack>
}



export default MessageCard