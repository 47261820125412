import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, Input, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";

import { IconArrowsDiagonal2, IconBoxAlignBottomLeftFilled, IconChevronDown, IconChevronRight, IconChevronUp, IconCircleCheckFilled, IconDots, IconExclamationCircle, IconGripVertical, IconPlayerPauseFilled, IconPlayerPlay, IconPlus, IconRefresh, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { Reorder } from "framer-motion";
import { useEffect, useMemo, useState } from "react";


const ColumnsSelector = ({ columns, hiddenColumns, setHiddenColumns: onHiddenColumns, columnOrder, setColumnOrder: onSetColumnOrder, saveMetadata, ...rest }:
    {
        columns: string[],

        hiddenColumns: string[],
        setHiddenColumns?: (columns: string[]) => void, [key: string]: any
        columnOrder?: string[] | null,
        setColumnOrder?: (columns: string[]) => void,
        saveMetadata: (newColumns?: any) => void
    }
) => {


    function setColumnOrder(order: string[]) {
        setTmpColumnOrder(order)
        onSetColumnOrder && onSetColumnOrder(order)
    }
    function setHiddenColumns(columns: string[]) {
        setTmpHiddenColumns(columns)
        onHiddenColumns && onHiddenColumns(columns)
    }   

    const [tmpHiddenColumns, setTmpHiddenColumns] = useState(hiddenColumns)
    const [tmpColumnOrder, setTmpColumnOrder] = useState(columnOrder || columns)
    const [filter, setFilter] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    // Make hidden columns order invariant with Set comparison
    const pendingSave = useMemo(() => {
        // Column order comparison needs to respect order
        const columnOrderChanged = JSON.stringify(tmpColumnOrder) !== JSON.stringify(columnOrder || columns);

        const sortedHidden = [...hiddenColumns].sort().join(',');
        const sortedTmpHidden = [...tmpHiddenColumns].sort().join(',');
        const hiddenColumnsChanged = sortedHidden !== sortedTmpHidden;

        return columnOrderChanged || hiddenColumnsChanged;
    }, [tmpColumnOrder, columnOrder, hiddenColumns, tmpHiddenColumns]);
    useEffect(() => {
        setTmpColumnOrder(columnOrder ? (
            [...columnOrder, ...columns.filter(c => !columnOrder.includes(c))]
        ) : columns)
    }, [columnOrder, columns, isOpen])




    return columns?.length > 0 ? <Menu size="sm" closeOnSelect={false} isOpen={isOpen}>
        <MenuButton as={IconButton} icon={<IconChevronDown size="15px" />}
            onClick={() => {
                setTmpHiddenColumns(hiddenColumns)
                setTmpColumnOrder(columnOrder || columns)
                setIsOpen(!isOpen)
            }}
        />


        <MenuList zIndex={15} minW="350px" >
            <Stack overflow="auto">
                {/* <Text>

                    {pendingSave && JSON.stringify(tmpColumnOrder)}
                </Text> */}
                <HStack spacing={2} justify="space-between" p="0px 8px">

                    <HStack>
                        <Button
                            onClick={() => setHiddenColumns([])}
                            size="xs">Select all</Button>
                        <Button
                            onClick={() => setHiddenColumns(columns?.map((col) => col))}
                            size="xs">Clear selection</Button>
                    </HStack>
                    {pendingSave &&
                        <Button size="xs" colorScheme="red" justifySelf="end"
                        onClick={() => {
                            saveMetadata((tmpColumnOrder || columns).filter((col) => !tmpHiddenColumns?.includes(col)))
                            setIsOpen(false)
                        }}
                        >Apply & Save</Button>

                    }


                </HStack>
                <Stack p="0px 8px">
                    <Input size="xs" placeholder="Search columns" value={filter} onChange={(e) => setFilter(e.target.value)} />
                </Stack>
                {tmpColumnOrder && <Stack overflow="auto" maxH="70vh" as={Reorder.Group} onReorder={setColumnOrder} values={(tmpColumnOrder)} >
                    {(tmpColumnOrder)?.map((col, i) => (
                        <MenuItem key={col} as={Reorder.Item}
                            display={filter && !col.toLowerCase().includes(filter.toLowerCase()) ? "none" : undefined}
                            onDragEnd={() => {
                                setColumnOrder(tmpColumnOrder)
                            }}
                            // whileDrag="dragging"
                            // position="relative"
                            value={col}
                        >
                            <HStack>
                                <IconGripVertical color="lightgray" />
                            <Checkbox
                                    isChecked={!tmpHiddenColumns?.includes(col)}
                                onChange={(e) => {
                                    if (!tmpHiddenColumns?.includes(col)) {
                                        setHiddenColumns(Array.from(new Set([...(tmpHiddenColumns || []), col])));
                                    }
                                    else {
                                        setHiddenColumns(tmpHiddenColumns.filter((c) => c != col))
                                    }
                                    }} ></Checkbox>
                                <Text>{col}</Text>
                            </HStack>
                        </MenuItem>))}
                </Stack>}

            </Stack>
        </MenuList>
    </Menu> : <></>
}


export default ColumnsSelector;