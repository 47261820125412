

import { useEffect, useState } from "react";


import { Link as ReactRouterLink } from 'react-router-dom'
import { getApi } from "../apiService";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';

import LoginComponent from "../components/Login/loginBox";

import { useAppContext } from "../appContext";
import { FaUser } from "react-icons/fa";

import { MdLogout } from "react-icons/md";
import { Logo } from "../components/CommonIcons/commonIcons";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Card, Flex, FormControl, FormLabel, HStack, Heading, IconButton, Image, Input, Kbd, Link, PinInput, PinInputField, Stack, Text, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { IconAdjustmentsHorizontal, IconBook2, IconBrandChrome, IconBuilding, IconCopy, IconDownload, IconFileTypeZip, IconFolder, IconLogout, IconSettings, IconTag, IconWand } from "@tabler/icons-react";
import { IconSwitchHorizontal } from "@tabler/icons-react";
import { IconCheck } from "@tabler/icons-react";
import IntegrationsPage from "./integrationsPage";
import { DataSourcesSection } from "../components/DataSourcesEdit/dataSourcesSection";
import { AppProject } from "../models/dataModel";
import AppSelect from "../components/AppSelect/appSelect";
import AppSkillsEdit from "../components/AppDetail/appSkillsEdit";
import { InfoModal } from "../components/ModalDialogs/infoModal";
import { IconArrowBigRightLines } from "@tabler/icons-react";
import { TfiLayoutTabMin } from "react-icons/tfi";
import Navigation from "../components/Navigation/navigation";
import { AutoUI, GeniouslyThemeProvider } from "geniously-chat-ui";
import LabelSettings from "../components/Settings/labelSettings";

const ExtensionInstructions = ({ }) => {
    const isMac = navigator.userAgent.indexOf("Mac") != -1;

    const extensionVersion="0.0.8";
    return (<Stack spacing={4} p="10px" align="center" >
        <Text alignSelf="start" fontWeight={900}>1.Download the extension files</Text>
        <Button colorScheme="pink"  rightIcon={<IconDownload />}
        onClick={()=>{
            downloadFile(`https://www.easypie.ai/extension/chrome/easypie-chrome-extension_${extensionVersion}.zip`, `easypie-chrome-extension_${extensionVersion}.zip`)
        }}
        >Download </Button>
        <Text alignSelf="start" fontWeight={900}>2. Extract the files to folder</Text>
        <HStack><IconFileTypeZip size="35px"/>  <IconArrowBigRightLines color="gray"/> <IconFolder size="35px"/> </HStack>
        <Text alignSelf="start" fontWeight={900}>3. Open chrome extension settings</Text>
        
        <Text>Copy this url ⬇️</Text>
        <HStack> <Flex background="gray.200" p="5px 10px" rounded={5}>
        <Text>chrome://extensions</Text>
        </Flex><IconButton 
        aria-label="Copy to clipboard"
        icon={<IconCopy/>} onClick={()=> navigator.clipboard.writeText("chrome://extensions").catch((error) => console.error('Error copying to clipboard:', error))} />
        </HStack> 
        
        <HStack>            <Text>And open in in </Text> <Button size="sm" leftIcon={  <TfiLayoutTabMin size="25px"/> } onClick={()=>window.open('', '_blank')}>+ new tab</Button>  </HStack>
        
        <Text alignSelf="start" fontWeight={900}>4. Load the extension in developer mode</Text>
        <Image src="/assets/load-extension-dev.gif" width={["80%", "400px"]} />
        <Text fontWeight={900} color="gray">... and pick the folder from step 2</Text>
        <Text alignSelf="start" fontWeight={900}>5. ✅ Done</Text>
        <Text fontWeight={600}>Open anytime using <Kbd fontSize="lg">{isMac ? "⌘" : "Ctrl"}</Kbd> + <Kbd fontSize="sm" p="2px 8px">E</Kbd> </Text>
        <Text alignSelf="start" fontWeight={900}>(optional) Pin it to your toolbar </Text>
        <Image src="/assets/extension-pin.gif" width={["80%", "400px"]} />
        
    </Stack>)
}

function downloadFile(url: string, filename: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
export default function SimpleSettingsPage({ forwardIfSetUp=true }:{
    forwardIfSetUp?:boolean
}) {

    const { currentUser, currentAppProject, setCurrentAppProject } = useAppContext()
    const [finished, setFinished] = useState(false)
    const [modal, setModal] = useState<any>()
    const navigate = useNavigate()
    const [appValue, _setAppValue] = useState<AppProject>()
    const setAppValue = (app: AppProject) => {
        _setAppValue(app)
    }
    useEffect(() => {
        if (currentAppProject) {
            setAppValue(currentAppProject)
        }
    }, [currentAppProject])

    function updateAppValue(updateFunc: (appValue: AppProject) => void) {
        const newAppValue = { ...appValue }
        updateFunc(newAppValue)
        setAppValue(newAppValue)


    }
    const [savedValue, setSavedValue] = useState<AppProject>()
    const toast = useToast()
    const [saving, setSaving] = useState(false)


    function save(appValue: AppProject) {
        setSaving(true)
        return getApi().saveApp(appValue).then((res) => {
            toast({ title: "Success", description: "Assistant settings successfully saved 🎉", status: "success", duration: 2000, isClosable: true })
            setAppValue(res)
            setCurrentAppProject(res)

            setSavedValue(res)
            return res
        }).catch((err) => {
            console.error(err)
            let details = err.response?.data?.detail?.map(errDetail => {
                let sliced = null
                if (errDetail?.loc?.length > 1) {
                    // skip first element, it's always "body"
                    sliced = errDetail?.loc.slice(1)
                    return sliced.join(".") + ": " + errDetail?.msg
                }
                else {
                    return null
                }
            }).filter((s) => s)

            if (details?.length) {
                // slice only first 3 errors
                details = details.slice(0, 3)
            }

            toast({ title: "Error ", description: details ? details.join("\n") : err.message, status: "error", duration: 5000, isClosable: true })
            return null
        }).finally(() => {
            setSaving(false)
        })
    }

    function finish() {
        if (appValue) {
            localStorage.setItem("easypie-is-setup", "true")
            navigate(`/home`)
            // save(appValue).then((res) => {
            //     if (res) {
            //         setFinished(true)
            //     }
            // })
        }
    }

    useEffect(() => {
        let isSetUp = localStorage.getItem("easypie-is-setup")
        if (forwardIfSetUp && isSetUp) {
            navigate(`/home`)
        }
    },[])




    return (
        <Stack align="center" direction="column" className="general-page" width="100%" overflow="auto">
            {modal}
            <Stack  align="center">
                {/* <Flex direction="row" position="sticky" top="0px" justify="space-between" width="calc(100vw - 25px)" >
                <Box onClick={() => navigate("/")} p="10px">
                        <Navigation />
                </Box>
                <Stack alignSelf="end" align="center" spacing={0} mr="50px">
                    <Text fontSize="2xl" fontWeight={900} color="gray.600">Welcome</Text>
                    <Text fontWeight={900} fontSize="sm" color="gray.600">let's set you up first</Text>
                </Stack>
                <Tooltip label={`Logged as ${currentUser?.name || currentUser?.email}`} placement="bottom-end">

                <Button background="white" margin="5px" as={ReactRouterLink} to="/login" leftIcon={<IconLogout />} variant="ghost">Logout</Button>
                </Tooltip>
            </Flex> */}
            
            
            <AppSelect />
            
                <Stack align="center" flexGrow={1} m="20px" >

                    <Card p="20px" shadow="2xl" width={["95vw", "480px", "840px"]} borderWidth={1} borderColor="gray.400" >
                      
                    {!appValue ? (
                            <Stack align="center" marginTop="-15px">
                                <Text fontSize="2xl">⬆️</Text>
                                <Text>Please select the workspace</Text>
                            </Stack>
                        ) : (
                                
                            <Accordion width="100%" defaultIndex={0}>

                                <AccordionItem width="100%">
                                    <AccordionButton width="100%" p="2px" >
                                        <Stack direction="row" justify="space-between" align="center" width="100%">
                                            <Stack direction="row">
                                                <Stack direction="row">
                                                    {/* <Text fontSize="2xl" fontWeight={900} color="pink.400">1</Text> */}
                                                    <IconSwitchHorizontal color="gray" />
                                                </Stack>
                                                <Stack direction="column" spacing="1" align="start">
                                                    <Text fontWeight={700} whiteSpace="nowrap"> Integrations</Text>
                                                    <Text fontWeight={500} fontSize="xs">Connect with the tools you use</Text>
                                                </Stack>
                                            </Stack>
                                            {/* <AccordionIcon /> */}
                                        </Stack>
                                    </AccordionButton>
                                    <AccordionPanel overflow="auto" maxH="50vh">
                                        <Box >
                                            <IntegrationsPage app={appValue} onAppChange={setAppValue} />
                                        </Box>
                                    </AccordionPanel>

                                    </AccordionItem>

                                    <AccordionItem width="100%">
                                        <AccordionButton width="100%" p="2px" >
                                            <Stack direction="row" justify="space-between" align="center" width="100%">
                                                <Stack direction="row">
                                                    <Stack direction="row">
                                                        {/* <Text fontSize="2xl" fontWeight={900} color="pink.400">1</Text> */}
                                                        <IconTag color="gray" />
                                                    </Stack>
                                                    <Stack direction="column" spacing="1" align="start">
                                                        <Text fontWeight={700} whiteSpace="nowrap"> Label settings</Text>
                                                        <Text fontWeight={500} fontSize="xs">Define categories to classify your interactions</Text>
                                                    </Stack>
                                                </Stack>
                                                {/* <AccordionIcon /> */}
                                            </Stack>
                                        </AccordionButton>
                                        <AccordionPanel overflow="auto" maxH="50vh">
                                            <LabelSettings />
                                        </AccordionPanel>

                                    </AccordionItem>

                                    <AccordionItem width="100%">
                                        <AccordionButton width="100%" p="2px" >
                                            <Stack direction="row" justify="space-between" align="center" width="100%">
                                                <Stack direction="row">
                                                    <Stack direction="row">
                                                        {/* <Text fontSize="2xl" fontWeight={900} color="pink.400">1</Text> */}
                                                        <IconBook2 color="gray" />
                                                    </Stack>
                                                    <Stack direction="column" spacing="1" align="start">
                                                        <Text fontWeight={700} whiteSpace="nowrap"> Knowledge base</Text>
                                                        <Text fontWeight={500} fontSize="xs">Common knowledge to use in any prompts</Text>
                                                    </Stack>
                                                </Stack>
                                                {/* <AccordionIcon /> */}
                                            </Stack>
                                        </AccordionButton>
                                        <AccordionPanel overflow="auto" maxH="50vh">
                                            <Box >
                                                <DataSourcesSection
                                                    allDataSources={appValue.data_sources}
                                                    onEditDataSource={(i, ds) => {
                                                        const newDataSources = [...appValue.data_sources];
                                                        newDataSources[i] = ds;
                                                        setAppValue({ ...appValue, data_sources: newDataSources });
                                                    }}
                                                    onAddDataSource={(ds) => {
                                                        setAppValue({
                                                            ...appValue,
                                                            data_sources: [...appValue.data_sources, ds],
                                                        });
                                                    }}
                                                    onDeleteDataSource={(ds) => {
                                                        setAppValue({
                                                            ...appValue,
                                                            data_sources: appValue.data_sources.filter(
                                                                (d) => !(d === ds || (d.uuid && d.uuid === ds.uuid))
                                                            ),
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </AccordionPanel>

                                    </AccordionItem>
                        </Accordion>

                                                                        
                         )}
                           
                    </Card>

                    

                </Stack>
            </Stack>

            <Stack spacing="20px" mb="10px" align="center">
            <Button colorScheme="green" leftIcon={<IconCheck />} onClick={() => finish()} >Finish setup</Button>
            <Stack mb="-10px">
                <Box width="200px" borderBottom="1px solid #cacaca"></Box>
                <Stack mt="-25px" alignSelf="center"  p="5px" background="white"><Text color="#aaaaaa" fontSize="xs">optionaly</Text></Stack>
            </Stack>
            {/* <Button colorScheme="pink"  leftIcon={<IconBrandChrome />} rightIcon={<IconDownload />}
            size="sm"
            onClick={()=>setModal(<InfoModal
            caption="Download the Chrome extension"
            onClose={()=>setModal(undefined)}
            >
                <ExtensionInstructions/>
            </InfoModal>)}
            >Download extension</Button>  */}
            <Button colorScheme="pink" size="sm" variant="outline" leftIcon={<IconSettings/>} onClick={() => currentAppProject?navigate(`/app/${currentAppProject?.id}/setup`):navigate("/apps")}>Open advanced settings</Button>
            </Stack>
        </Stack>
    )

}