



import { useEffect, useMemo, useState } from "react";


import { Tabs, TabList, TabPanels, Tab, TabPanel, Tag, WrapItem, Wrap, Link, ButtonGroup, Button, TagLabel, Switch, Textarea, Spinner, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Moment from 'react-moment';



import { useAppContext } from "../../appContext";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";

import GenericList from "../GenericList/genericList";

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

import { IconChevronDown, IconCircleCheck, IconDirectionSignFilled, IconExclamationCircle, IconPlayerPlay, IconPlayerSkipForwardFilled, IconRefresh, IconRobot, IconRosetteFilled, IconShieldCheckered, IconSquareRoundedX } from "@tabler/icons-react";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";
import { EditableField } from "../EditableField/editableField";
import { AutoUI, GeniouslyThemeProvider, AutoDatePicker } from "geniously-chat-ui";
import { IconSquareRoundedCheck } from "@tabler/icons-react";
import { EditModal } from "../ModalDialogs/editModal";
import { IconCircleDashed, IconUserCheck } from "@tabler/icons-react";
import { SearchFilter } from "../SearchFilter/searchFilter";
import { InfoModal } from "../ModalDialogs/infoModal";
import ContactCard from "../Inbox/ContactCard";
import { ActivityCard } from "../Inbox/activitiesCards";

import useWebSocket from 'react-use-websocket';
import { getApi } from "../../apiService";


export default function CrmAgentsSettings() {



    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { agentId } = useParams()
    const [selectedAgent, _setSelectedAgent] = useState<any>(undefined)

    const setSelectedAgent = (agent) => {
        _setSelectedAgent(agent)
        if (agent && agent.id) {
            window.history.pushState({}, "", `/agents/${agent.id}`)
        }
        else {
            window.history.pushState({}, "", `/agents`)
        }
    }
    const getAgentEndpoint = useApiEndpoint("GET", `/agents/${agentId}`)
    useEffect(() => {
        if (agentId && !selectedAgent) {
            getAgentEndpoint.execute().then((agent) => {
                _setSelectedAgent(agent)
            })

        }
    }
        , [agentId, selectedAgent])





    return (
        <HStack justify="stretch" width="100%" align="stretch">
            <GenericList endpointPath="/agents" listTitle="Agents" primaryField="campaign"
                collapsible
                onClickNew={() => {

                    setSelectedAgent({
                        id: undefined,
                        name: "New agent",
                        active: true,
                        responsibilities: []
                    })
                }}
                onSelected={(item) => {
                    setSelectedAgent(item)
                }}
                customCardRenderer={
                    (item, index, selected) => {
                        return (
                            <HStack key={index} border="1px solid gray" borderRadius={8} align="start" p="6px" bg={selected ? "gray.100" : "white"} onClick={() => {


                            }}>
                                <IconRobot />
                                <Stack >
                                    <HStack>
                                        <Text fontSize="sm">{item.name}</Text>
                                    </HStack>
                                    <Wrap>
                                        {item.responsibilities?.map(r => <WrapItem>
                                            <Tag size="sm" ><TagLabel>{r.name}</TagLabel></Tag>
                                        </WrapItem>)}
                                    </Wrap>

                                </Stack>
                            </HStack>
                        )
                    }

                }
            />
            {selectedAgent && (
                <AgentDetail agent={selectedAgent} />
            )}

        </HStack>
    );
}

interface CrmAgent {
    id: string | undefined;
    name: string
    active: boolean
    responsibilities: AgentResponsibility[]
    writing_style: string
}
interface AgentResponsibility {
    id?: string | undefined;
    name?: string;
    engage_on: Array<"event" | "message_received" | "assigned_to_contact">;
    datasources: string[];
    engage_on_event_types?: string[];
    instructions: string;
    structured_instructions?: string;
    //structured_checklist?: ChecklistCondition[];
    rules: InstructionRule[];
    instructions_hash?: string;
    active: boolean;
    autonomy?: "full" | "requires_approval";
}
interface InstructionRule {
    if_condition: string;
    condition_explanation?: string
    then_instruction: string
    autogenerated?: boolean
}

interface ChecklistCondition {
    condition: string;
    condition_explanation?: string;
    criteria_type: "objective" | "subjective";
}
interface SessionInfo {
    contact_id: string;
    responsibility_id: string;
    triggered_by_activity_id?: string;
    summary?: string;
    // has to be able to be None to pass the validation for endpoint
    scratchpad_data?: ScratchpadRecord[];
    session_number?: number;
    created_at?: Date;
    error?: boolean;
}
interface ScratchpadRecord {

    type: "ai" | "human" | "function_result";
    content: string;
    timestamp: Date;
    session: number;
    action_name?: string;
    action_args?: Record<string, any>;
}

const AgentDetail = ({ agent: agentInput }: { agent: CrmAgent }) => {

    const [agentState, setAgentState] = useState<CrmAgent>(agentInput)
    const [agentSaved, setAgentSaved] = useState<CrmAgent>(agentInput)
    const [modal, setModal] = useState<JSX.Element | undefined>(undefined)
    const [sessionSearch, setSessionSearch] = useState<string>(undefined)
    const sessionsEndpoint = useApiFetch<any[]>(`/agents/${agentSaved?.id}/sessions`, { queryArgs: { q: sessionSearch }, shouldFetch: !!(agentSaved?.id), swrOptions: { revalidateIfStale: false, refreshInterval: 990000, revalidateOnFocus: false } })

    const saveAgentEndpoint = useApiEndpoint("POST", `/agents/${agentSaved?.id}`)
    const saveNewAgentEndpoint = useApiEndpoint("POST", `/agents`)

    const agentOpsEndpoint = useApiEndpoint("POST", `/agents/${agentSaved?.id}/operations/{operation}`)

    const [activeTab, setActiveTab] = useState(0)
    const [testState, setTestState] = useState<any>(undefined)
    const navigate = useNavigate()

    useEffect(() => {
        if (agentInput != agentState) {
            {
                setAgentSaved(agentInput)
                setAgentState(agentInput)
            }
        }
    }, [agentInput])


    const isEdited = useMemo(() => {
        return JSON.stringify(agentState) != JSON.stringify(agentSaved)
    }, [agentState, agentSaved])

    function save() {
        let savePromise: Promise<any> = undefined
        if (agentInput.id) {

            savePromise = saveAgentEndpoint.execute({}, agentState)
        }
        else {
            savePromise = saveNewAgentEndpoint.execute({}, agentState)
        }
        savePromise.then((res) => {
            if (res && res.id) {
                setAgentSaved(res)
                setAgentState(res)
            }
        })
    }

    function testRun(activityId: string = undefined, contactId: string = undefined) {
        let previousModal = modal

        setModal(<InfoModal caption="Test run" onClose={() => setModal(previousModal)}>
            <TestForm triggered_by_activity_id={activityId} agentState={agentState} testState={testState} onTestStateChange={setTestState} />
        </InfoModal>)
    }

    function openResponsibility(
        responsibility: AgentResponsibility
    ) {
        const isNew = !agentState.responsibilities.find((res, i) => responsibility.id == res.id)
        setModal(<EditModal caption="Edit Responsibility"
            value={responsibility}
            onCancel={() => setModal(undefined)}
            onOk={(val) => {
                if (isNew) {
                    setAgentState({ ...agentState, responsibilities: [...(agentState.responsibilities || []), val] })
                }
                else {
                    setAgentState({ ...agentState, responsibilities: agentState.responsibilities.map((res, i) => responsibility.id == res.id ? val : res) })
                }
                setModal(undefined)
            }}
        >
            {(val, setVal) => (
                <ResponsibilityDetail responsibility={val} onChange={setVal} />
            )}
        </EditModal>)
    }


    return agentState && <Stack align="stretch" justify="start" height="100%" maxH="calc(100vh - 60px)" flexGrow={1} width="100%">
        <Stack p="10px" borderBottom="1px solid lightgray" spacing={0}>
            {modal}
            <HStack justify="space-between" flexGrow="1">
                <EditableField value={agentState.name} onApply={(name) => {
                    setAgentState({ ...agentState, name })
                }} />
                {(isEdited || !agentSaved.id) && <ButtonGroup size="sm">
                    <Button onClick={() => save()} colorScheme="brand">Save</Button>
                    <Button onClick={() => setAgentState(agentSaved)}>Cancel</Button>
                </ButtonGroup>}
                <Menu>
                    <MenuButton as={Button} size="sm" rightIcon={<IconChevronDown />}>
                        Actions
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={() => {

                            setModal(<EditModal caption="Rerun for actions since"

                                value={{ since: (new Date()).toISOString() }}
                                onCancel={() => setModal(undefined)}
                                onOk={(val) => {
                                    agentOpsEndpoint.invoke({
                                        pathArgs: { operation: "rerun" }, body: {
                                            "rerun_type": "rerun-retroactively",
                                            "since": val.since
                                        }
                                    })
                                    setModal(undefined)
                                }}
                            >
                                {(val, setVal) => (
                                    <Stack>

                                        <Text fontSize="sm">Only errored actions and not handled activities will be executed</Text>
                                        <GeniouslyThemeProvider>

                                            <AutoDatePicker value={val.since} onApply={(date) => setVal({ since: date })} />
                                        </GeniouslyThemeProvider>
                                    </Stack>
                                )}


                            </EditModal>)


                        }}>Rerun retroactively </MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
            <HStack ml="6px">
                <Switch size="sm" isChecked={agentState.active} onChange={e => setAgentState({ ...agentState, active: e.target.checked })} />
                <Text fontSize="xs" fontWeight={500} >{agentState.active ? "Active" : "Deactivated"}</Text>
            </HStack>



        </Stack>

        <Tabs index={activeTab} onChange={(index) => setActiveTab(index)} variant="enclosed"  >
            <TabList>
                <Tab>Settings</Tab>
                <Tab>Sessions</Tab>
            </TabList>
            <TabPanels maxH="calc(100vh - 200px)" overflow="auto">
                <TabPanel>
                    <Stack>
                        <HStack width="100%" justify="space-between">
                            <Text fontWeight={900}>Responsibilities</Text>
                            <HStack>
                                <Button size="sm" onClick={() => {

                                    testRun()
                                }} >Test</Button>
                            <Button size="sm" colorScheme="brand" variant="outline" onClick={() => {
                                openResponsibility({
                                    instructions: "",
                                    engage_on: [],
                                    datasources: [],
                                    rules: [],
                                    active: true,
                                    autonomy: "requires_approval"
                                })
                            }} >Add new</Button>
                            </HStack>

                        </HStack>
                        <Stack borderRadius="6px" border="1px solid" borderColor="gray.200" minH="200px" p="8px">
                            {agentState.responsibilities?.map((r, i) => <Button key={i} p="8px"
                                leftIcon={r.active ? <IconSquareRoundedCheck /> : <IconSquareRoundedX color="gray" />}
                                backgroundColor="gray.100" borderRadius="5px" justifyContent="start"
                                onClick={() => {
                                    openResponsibility(r)
                                }}
                            >
                                <Text fontWeight="600" fontSize="sm">{r.name}</Text>
                            </Button>)}
                            {agentState.responsibilities?.length == 0 && <Stack justifySelf="center" alignSelf="center" p="20px" backgroundColor="gray.50" borderRadius="8px"><Text textColor="gray.400">Add responsibilities to the agent with instructions what and when is he supposed to do </Text></Stack>}

                        </Stack>
                        <Text fontWeight={900}>Writing style</Text>
                        <Textarea placeholder='Give instructions on writing style... i.e.: Use professional and formal language or Use casual and friendly tone' value={agentState.writing_style || ""} onChange={e => setAgentState({ ...agentState, writing_style: e.target.value })} />


                    </Stack>
                </TabPanel>
                <TabPanel>
                    <Stack justify="stretch">
                        <HStack justify="space-between">
                            <Text fontSize="lg" fontWeight={900}>Recent sessions</Text>
                            <SearchFilter search="" onChange={(search, filter) => { setSessionSearch(search) }} />
                        </HStack>
                        <Stack>
                            {sessionsEndpoint.data?.map((session, i) => (<Stack key={i} p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0}
                                cursor={"pointer"}
                                _hover={{ shadow: "md" }}
                                onClick={() => setModal(<InfoModal caption="Session detail" onClose={() => setModal(undefined)}>
                                    <SessionDetail session_id={session.id} on_run_test={() => {
                                        testRun(session.triggered_by_activity_id)
                                    }} />
                                </InfoModal>)}
                            >

                                {/* <Text fontSize="xs" fontWeight={500}>{session.session_number}</Text> */}
                                <HStack width="100%">
                                    <Text fontSize="xs" color="gray.500">{<Moment format="DD. MMM YYYY HH:mm:ss" >{session.created_at}</Moment>}</Text>
                                    <Text fontSize="xs" color="gray.500">{<Moment fromNow>{session.created_at}</Moment>}</Text>
                                    {session.error && <HStack color="red.500" justifySelf="end"><IconExclamationCircle size="15px" /><Text fontSize="xs">Error</Text></HStack>}
                                </HStack>
                                <Text fontSize="xs" fontWeight={400}>{session.summary}</Text>
                            </Stack>)
                            )
                            }
                        </Stack>
                    </Stack>
                </TabPanel>
            </TabPanels>
        </Tabs>


    </Stack >
}

const ResponsibilityDetail = ({ responsibility, onChange }: { responsibility: AgentResponsibility, onChange: (val: AgentResponsibility) => any }) => {
    const rewritePromptsEndpoint = useApiEndpoint("POST", `/agents/operations/rewrite-responsibility-instructions`)
    const [modal, setModal] = useState<JSX.Element | undefined>(undefined)

    const searchCampaignEndpoint = useApiEndpoint("GET", `/analytics/campaigns`)
    function rewriteInstructions() {
        rewritePromptsEndpoint.execute({}, responsibility).then((res) => {
            if (res?.instructions) {
                onChange(res)
            }
        })
    }

    return <Stack maxH="80vh" overflow="auto">
        <HStack >
            <EditableField placeholder="Name of the responsibility" fontWeight={900} value={responsibility.name} onApply={(name) => {
                onChange({ ...responsibility, name })
            }} />
            <HStack>
                <Switch size="sm" isChecked={responsibility.active} onChange={e => {
                    let res = { ...responsibility, active: e.target.checked }
                    onChange(res)
                }
                } />
                <Text fontSize="xs" fontWeight={500} >{responsibility.active ? "Active" : "Deactivated"}</Text>
            </HStack>
        </HStack>
        <HStack justify="end">
            <Text fontSize="xs" fontWeight={500} >Autonomy</Text>
            <Menu size="xs" >
                <MenuButton size="xs" as={Button} rightIcon={<IconChevronDown />}>
                    {responsibility.autonomy == "requires_approval" ? <HStack><IconUserCheck size="15px" /><Text>Requires Approval</Text></HStack> : <HStack><IconCircleCheck size="15px" /><Text>Full autonomy</Text></HStack>}
                </MenuButton>
                <MenuList fontSize="sm">
                    <MenuItem icon={<IconUserCheck size="15px" />} onClick={() => onChange({ ...responsibility, autonomy: "requires_approval" })}>Requires Approval</MenuItem>
                    <MenuItem icon={<IconCircleCheck size="15px" />} onClick={() => onChange({ ...responsibility, autonomy: "full" })}>Full</MenuItem>
                </MenuList>
            </Menu>

        </HStack >
        <Tabs variant="enclosed" >
            {modal}
            <TabList>
                <Tab>Instructions</Tab>
                <Tab>Rules and flow</Tab>
            </TabList>
            <TabPanels maxH="calc(100vh - 300px)" overflow="auto">
                <TabPanel>
                    <Stack p="0px 8px"  >
            <GeniouslyThemeProvider>
                            <AutoUI showOnlySchemaFields
                                handle_lookup={(val) => searchCampaignEndpoint.execute({}, null, { q: val }).then((res) => res.map((item) => ({ id: item.campaign_id, value: item.campaign_id, label: item.name })))}
                                schema={{
                    type: "object",

                    properties: {

                        engage_on: {
                            type: "array",
                            enum: ["event", "message_received", "assigned_to_contact"],
                            items: {
                                type: "string",
                            }
                        },
                        engage_on_event_types: {
                            type: "array",
                            //showCondition: { engage_on: "event" },
                            enum: [
                                "linkedin:connection_accepted",
                                "linkedin:work_anniversary",
                                "linkedin:birthday",
                                "linkedin:job_change"
                            ],
                            items: {
                                type: "string",
                            }
                        },
                        contact_in_campaign_ids: {
                            type: "array",
                            lookup_datasource: "campaigns",
                            lookup_item_key: "id",
                            items: {
                                type: "string",
                            }
                        },
                        datasources: {
                            type: "array",
                            title: "Available datasources",
                            items: {
                                type: "string",

                            }
                        },
                        instructions: {
                            type: "string",
                            maxLength: 4000,
                        },




                    }
                }} value={responsibility} onValueChange={(val) => onChange(val)} />
            </GeniouslyThemeProvider>
                        <Button size="sm" alignSelf="end" colorScheme="brand" isLoading={rewritePromptsEndpoint.isRunning} onClick={rewriteInstructions}>Compile</Button>


                    </Stack>
                </TabPanel>
                <TabPanel>
                    <Stack>
                        <Stack spacing="0">

                            <Text mb="-4px" fontWeight={900}>Rules</Text>
                            <Text fontSize="xs" fontWeight={400}>Instructions on how to handle specific situations</Text>

                        </Stack>
                        <Stack backgroundColor="gray.50" borderRadius={4} p="8px 8px 0px" maxH="40vh" overflow="auto">
                            {responsibility.rules?.map((rule, i) => <Stack key={i} p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0} backgroundColor="gray.100" _hover={{ backgroundColor: "gray.200" }}
                                cursor={"pointer"}
                                onClick={() => {
                                    setModal(<RuleEditModal caption="Edit rule"
                                        rule={rule}
                                        onCancel={() => setModal(undefined)}
                                        onOk={(val) => {
                                            onChange({ ...responsibility, rules: responsibility.rules.map((res, ir) => i == ir ? val : res) })
                                            setModal(undefined)
                                        }}
                                    />)

                                }}
                            >
                                <HStack >
                                    <IconDirectionSignFilled />
                                    <Text fontSize="xs" whiteSpace="pre-wrap" fontWeight={600}>{rule.if_condition}</Text>
                                </HStack>
                                <Stack p="2px 10px">
                                    <HStack>
                                        <Text fontSize="xs" minW="40px" fontWeight={500} noOfLines={1}><b>Then:</b> </Text>
                                        <Text fontSize="xs" fontWeight={400} noOfLines={1}>{rule.then_instruction}</Text>
                                    </HStack>

                                </Stack>
                            </Stack>)
                            }
                            <Stack align="center" position="sticky" bottom="0px" backgroundColor="gray.50" p="4px">

                                <Button size="sm" colorScheme="brand" onClick={() => {
                                    setModal(<RuleEditModal caption="Add rule"
                                        onOk={(val) => {
                                            onChange({ ...responsibility, rules: [...responsibility.rules, val] })
                                            setModal(undefined)
                                        }}
                                        onCancel={() => setModal(undefined)}
                                        rule={{ if_condition: "", then_instruction: "" }}
                                    />)
                                }}> Add new</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Text fontWeight={900}>Structured instructions</Text>

                        <Textarea placeholder="Write structured instructions for the agent" fontSize="sm"
                            minH="200px"
                            maxH="60vh"
                            height={((responsibility.structured_instructions?.split("\n").length || 1) + 1) + "em"}
                            value={responsibility.structured_instructions} onChange={e => onChange({ ...responsibility, structured_instructions: e.target.value })} />
                    </Stack>
                </TabPanel>
            </TabPanels>
        </Tabs>

    </Stack >
}

const RuleEditModal = ({ caption, rule, onOk, onCancel }: { caption?: string, rule: InstructionRule, onOk: (val: InstructionRule) => any, onCancel: () => any }) => {

    return <EditModal caption={caption || "Edit rule"}
        value={rule}
        onCancel={onCancel}
        onOk={(rule) => onOk(rule)}
    >
        {(val, setVal) => (
            <Stack>
                <AutoUI value={val}
                    showOnlySchemaFields
                    schema={{
                        type: "object",
                        properties: {
                            if_condition: {
                                type: "string",
                                title: "If condition",
                                description: "Condition that triggers the rule"
                            },
                            condition_explanation: {
                                type: "string",
                                title: "Condition explanation",
                                description: "Optional details on how to evaluate the condition",

                                maxLength: 4000
                            },
                            then_instruction: {
                                type: "string",
                                title: "Then instruction",
                                description: "Instruction on what to do if the condition is met",
                                maxLength: 4000
                            }
                        }
                    }}
                    onValueChange={setVal}
                />
            </Stack>
        )}
    </EditModal>
}



const ScratchpadRecordCard = ({ record, next }: { record: ScratchpadRecord, next?: ScratchpadRecord }) => {
    const actionPerformed = next?.action_name == record.action_name
    if (record.type == "function_result") {
        return <Stack p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0}>
            <HStack justify="space-between">
                <Tag>

                    <Text fontSize="xs" fontWeight={800}>⚡️ {record.action_name} </Text>
                </Tag>
                <Text fontSize="xs" color="gray.500">{<Moment format="DD. MMM YYYY HH:mm:ss">{record.timestamp}</Moment>}</Text>
            </HStack>
            <Text fontSize="xs" fontWeight={600}>result:</Text>
            <Stack p="2px 10px">
                <Text fontSize="xs" whiteSpace="pre-wrap">{record.content}</Text>
            </Stack>
        </Stack>
    }
    else if (record.type == "ai") {
        return <><Stack p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0}>
            <HStack justify="space-between">

                <Tag>

                    <Text fontSize="xs" fontWeight={800}> {!record.action_name ? "🧠 Thoughts" : (actionPerformed ? "⚡️ Action" : "🧠 Action intent")}</Text>
                </Tag>


                <Text fontSize="xs" color="gray.500">{<Moment format="DD. MMM YYYY HH:mm:ss">{record.timestamp}</Moment>}</Text>
            </HStack>

            <Text fontSize="xs" whiteSpace="pre-wrap">{record.content}</Text>
            {record.action_name && <Stack p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0} opacity={!actionPerformed ? 0.5 : undefined}>
                <Tag alignSelf="start" colorScheme="facebook" variant="outline">
                    <Text fontSize="sm" fontWeight={700}  >⚡️{record.action_name}</Text>
                </Tag>
                <Stack ml="20px">
                    <GeniouslyThemeProvider>

                        <AutoUI value={record.action_args} />
                    </GeniouslyThemeProvider>
                </Stack>
            </Stack>
            }
        </Stack>
        </>
    }
    else if (record.type == "human") {
        return <Stack p="8px" border="1px solid" borderColor="gray.200" borderRadius="5px" spacing={0}>
            <HStack justify="space-between">
                <Tag>
                    <Text fontSize="xs" fontWeight={500}>⬅️ Internal feedback</Text>
                </Tag>
                <Text fontSize="xs" color="gray.500">{<Moment format="DD. MMM YYYY HH:mm:ss">{record.timestamp}</Moment>}</Text>
            </HStack>
            <Text fontSize="xs" whiteSpace="pre-wrap">{record.content}</Text>
        </Stack>
    }
    else {
        return <Text color={"gray.300"}>{record.type}</Text>
    }
}

const SessionDetail = ({ session_id, on_run_test }: { session_id: string, on_run_test?: () => any }) => {
    const sessionEndpoint = useApiFetch<SessionInfo>(`/agents/*/sessions/${session_id}`, { static: true })
    const contactInfo = useApiFetch<any>(`/inbox/contacts/${sessionEndpoint.data?.contact_id}`, { static: true, shouldFetch: !!sessionEndpoint.data?.contact_id })
    const triggeredByActivityEndpoint = useApiFetch<any>(`/inbox/contacts/-/activities/${sessionEndpoint.data?.triggered_by_activity_id}`, { static: true, shouldFetch: !!sessionEndpoint.data?.triggered_by_activity_id })


    return (<Stack width="100%" flexGrow={1} justifySelf="stretch">
        <Text>Session detail</Text>
        {sessionEndpoint.isLoading && <Spinner />}
        {sessionEndpoint.error && <IconExclamationCircle />}

        {sessionEndpoint.data && <Stack >
            <HStack justify="space-between">
                <HStack>
                    <Text fontSize="xs" color="gray.500">{<Moment format="DD. MMM YYYY HH:mm:ss" >{sessionEndpoint.data.created_at}</Moment>}</Text>
                    <Text fontSize="xs" color="gray.500">{<Moment fromNow>{sessionEndpoint.data.created_at}</Moment>}</Text>

                </HStack>
                {on_run_test && <Button variant="outline" size="sm" colorScheme="brand" leftIcon={<IconPlayerPlay />} onClick={() => on_run_test()}>Test re-run</Button>}
            </HStack>
            {contactInfo.data && <ContactCard link={`/inbox/${sessionEndpoint.data?.contact_id}`} contact={contactInfo.data} />}
            <Text fontSize="sm" fontWeight={900}>Triggered by activity</Text>
            {triggeredByActivityEndpoint.data && <ActivityCard activity={triggeredByActivityEndpoint.data} />}


            <Stack>
                {sessionEndpoint.data.scratchpad_data?.map((record, i) => <ScratchpadRecordCard key={i} record={record} next={sessionEndpoint.data.scratchpad_data[i + 1]} />)}
            </Stack>

        </Stack>}

    </Stack>)

}



const TestForm = ({ triggered_by_activity_id, agentState, testState, onTestStateChange }: { triggered_by_activity_id?: string, agentState: CrmAgent, testState: any, onTestStateChange: (state: any) => any }) => {

    const [testResult, setTestResult] = useState<{
        state: "running" | "done",
        error?: string
        scratchpad: ScratchpadRecord[],
        output: any[] //activities

    }>()
    const triggeredByActivityEndpoint = useApiFetch<any>(`/inbox/contacts/-/activities/${triggered_by_activity_id}`, { static: true, shouldFetch: !!triggered_by_activity_id })
    const [testActivitiesState, setTestActivitiesState] = useState<{
        contact?: any,
        channel?: string,
        account?: string,
        activities: any[]
    }>({
        contact: undefined,
        activities: []
    })
    const [currentDate, setCurrentDate] = useState((new Date()).toISOString())
    const [continueFromOutput, setContinueFromOutput] = useState<any>()

    useEffect(() => {
        if (testState) {
            setTestActivitiesState(testState.activitiesState)
            setCurrentDate(testState.currentDate)

        }
    }, [testState])
    useEffect(() => {
        onTestStateChange({ activitiesState: testActivitiesState, currentDate })
    }, [currentDate, testActivitiesState])

    function runTest() {
        const socketUrl = getApi().baseUrl.replace("http", "ws") + "/agents/tests/run"
        getApi().authorize().then((headers) => {

            const auth = headers["Authorization"]
            const params = new URLSearchParams();
            if (triggered_by_activity_id) params.append("for_activity", triggered_by_activity_id);
            if (testActivitiesState.contact) params.append("for_contact", testActivitiesState.contact);
            params.append("auth", auth);

            const socket = new WebSocket(socketUrl + "?" + params.toString()); if (testActivitiesState.channel && testActivitiesState?.activities?.length) {
                testActivitiesState?.activities.forEach((activity) => activity.channel = testActivitiesState.channel)
            }
            if (testActivitiesState.account && testActivitiesState?.activities?.length) {
                testActivitiesState?.activities.forEach((activity) => activity.account = testActivitiesState.account)
            }
            if (currentDate && testActivitiesState?.activities?.length) {
                let lastDate = currentDate
                for (let activity of testActivitiesState.activities) {
                    if (!activity.timestamp) {
                        activity.timestamp = currentDate
                    }
                    else {
                        lastDate = activity.timestamp
                    }
                }
            }
            socket.onopen = () => {
                socket.send(JSON.stringify({ "agent": agentState, "activities": testActivitiesState?.activities, "as_of_date": currentDate }))
            }
            setTestResult({ state: "running", scratchpad: [], output: [] })
            socket.onmessage = (event) => {
                let data = JSON.parse(event.data)
                setTestResult(data)
                if (data.state == "done") {
                    socket.close()
                    socket.onclose = undefined
                }

            }
            const onError = (event) => {
                setTestResult({ ...testResult, state: "done", error: "Error occured during test run" })
            }
            socket.onclose = onError
            socket.onerror = onError
        })

    }

    const optionalFormProps: any = triggered_by_activity_id ? {} : {
        contact: {
            type: "string",
            title: "Contact",
            description: "Find contact by name",

            lookup_datasource: "contacts",
            lookup_item_key: "id"
        },
        channel: {
            type: "string",
            title: "Channel",
            description: "What communication channel to assume for the messages",
            enum: ["linkedin", "email"]
        },
        account: {
            type: "string",
            title: "Channel Account",
            description: "Social media account or email address",

        },
    }

    useEffect(() => {
        if (triggeredByActivityEndpoint.data?.timestamp) {
            setCurrentDate(triggeredByActivityEndpoint.data?.timestamp)
        }
    }, [triggeredByActivityEndpoint.data])



    return (<Stack width="100%" flexGrow={1} justifySelf="stretch">

        <Stack>

            {triggered_by_activity_id && <Text fontSize="sm" fontWeight={900}>Trigger activity</Text>}
            {testResult?.error && <Text color="red.500">{testResult.error}</Text>}
            {testResult?.state == "running" && <HStack><Spinner size="xs" /><Text >Running ...</Text></HStack>}
        </Stack>

        <GeniouslyThemeProvider>
            <Stack spacing={0}>

                <Text fontSize="xs" fontWeight={900}>Current date </Text>
                <AutoDatePicker value={currentDate} onValueChange={(val) => { val && setCurrentDate(val) }} />
            </Stack>
        {triggeredByActivityEndpoint.data && <ActivityCard activity={triggeredByActivityEndpoint.data} />}

            {(!triggered_by_activity_id || testActivitiesState?.activities) && <Stack>
                <AutoUI
                    handle_lookup={(val) => {
                        return getApi().fetch(`/inbox/contacts`, { q: val }).then((res) => res.map((item) => ({ id: item.id, value: item.id, label: item.full_name })))
                    }}
                    showOnlySchemaFields={true}
                    schema={{
                        type: "object",

                        properties: {
                            ...optionalFormProps,
                            activities: {
                                type: "array",
                                title: "Activities",
                                items: {
                                    type: "object",
                                    properties: {
                                        type: {
                                            type: "string",
                                            enum: ["message_in", "message_out", "event", "note"]
                                        },
                                        timestamp: {
                                            type: "string",
                                            format: "date-time"

                                        },
                                        event_type: {
                                            type: "string",
                                            showCondition: { type: "event" },
                                            enum: [
                                                "linkedin:connection_accepted",
                                                "linkedin:work_anniversary",
                                                "linkedin:birthday",
                                                "linkedin:job_change"
                                            ]

                                        },
                                        text: {
                                            type: "string",
                                            maxLength: 4000
                                        }
                                    }
                                }
                            }


                        }
                    }} value={testActivitiesState} onValueChange={setTestActivitiesState} />
            </Stack>}
        </GeniouslyThemeProvider>

        {testResult?.scratchpad && <Stack>
            <Accordion allowToggle defaultIndex={1}>
                <AccordionItem>
                    <AccordionButton p="8px">
                        <Text fontSize="sm" fontWeight={900}>🧠 Internal reasoning</Text>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        {testResult.scratchpad?.map((record, i) => <ScratchpadRecordCard key={i} record={record} next={testResult.scratchpad[i + 1]} />)}
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton p="8px">
                        <Text fontSize="sm" fontWeight={900}> Result</Text>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        {testResult.output && <Stack>

                            {testResult.output.map((activity, i) => <ActivityCard activity={activity} />)}
                        </Stack>
                        }
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Stack>}
        {testResult ? <Stack position="sticky" bottom="0px" background="white">

            {/* {contactInfo.data && <ContactCard link={`/inbox/${sessionEndpoint.data?.contact_id}`} contact={contactInfo.data} />} */}




            <HStack justify="center" width="100%">

                {testResult.state == "running" ? <Spinner /> : (
                    <Button colorScheme="brand" size="xs" leftIcon={<IconRefresh size="15px" />} variant="outline" onClick={runTest}>Re-run</Button>
                )}
                {(testResult.output?.length && continueFromOutput != testResult.output) ? (< Button colorScheme="brand" size="xs" leftIcon={<IconPlayerSkipForwardFilled size="15px" />} variant="outline" onClick={() => {

                    setTestActivitiesState({ ...testActivitiesState, activities: [...(testActivitiesState.activities || []), ...testResult.output] })

                    let lastOutput = testResult.output[testResult.output.length - 1]
                    if (continueFromOutput != testResult.output) {
                        setContinueFromOutput(testResult.output)
                        setCurrentDate(lastOutput.timestamp)
                    }
                    else {

                        runTest()
                    }
                }
                }>Continue next step</Button>) : (
                    <Text fontSize="xs" color="gray.500">The output has been added to activities... pls review/edit and click Re-run</Text>
                )}
            </HStack>

        </Stack> : (
            <Stack align="center">
                <Button colorScheme="brand" size="lg" leftIcon={<IconPlayerPlay />} onClick={runTest}>Run test</Button>
            </Stack>

        )}

    </Stack>)

}

const ContactAndActivitiesForm = ({ onOK, onCancel }: { onOK: () => any, onCancel: () => any }) => {
    const [state, setState] = useState<{
        contact: any,
        activities: any[]
    }>({
        contact: undefined,
        activities: []
    })

    return <Stack>
        <AutoUI schema={{
            type: "object",
            properties: {
                contact: {
                    type: "string",
                    title: "Contact",
                    lookup_datasource: "contacts",
                    lookup_item_key: "id"
                },
                activities: {
                    type: "array",
                    title: "Activities",
                    items: {
                        type: "object",
                        properties: {
                            type: {
                                type: "string",
                                enum: ["message_in", "message_out", "event", "note"]
                            },
                            text: {
                                type: "string",
                                maxLength: 4000
                            }
                        }
                    }
                }


            }
        }} value={state} onValueChange={setState} />

    </Stack>
}