import { Button, Stack } from '@chakra-ui/react';
import { IconChevronLeft } from '@tabler/icons-react';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface ExpandableTextSectionProps {
    children: ReactNode;
    maxHeight?: string;   // e.g., "3em", "60px"
    maxLength?: number;   // maximum character length for string children
}

const ExpandableTextSection: React.FC<ExpandableTextSectionProps> = ({
    children,
    maxHeight = '2em',
    maxLength
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    // Helper to convert maxHeight string to pixels.
    const toPx = (heightStr: string, fontSize: number): number => {
        if (heightStr.endsWith('em')) {
            return parseFloat(heightStr) * fontSize;
        } else if (heightStr.endsWith('px')) {
            return parseFloat(heightStr);
        }
        return parseFloat(heightStr);
    };

    useEffect(() => {
        if (containerRef.current) {
            // Temporarily remove the maxHeight style to measure full height.
            const previousMaxHeight = containerRef.current.style.maxHeight;
            containerRef.current.style.maxHeight = 'none';
            const fullHeight = containerRef.current.scrollHeight;
            containerRef.current.style.maxHeight = previousMaxHeight;

            const fontSize = parseFloat(getComputedStyle(containerRef.current).fontSize);
            const computedMaxHeight = toPx(maxHeight, fontSize);

            setIsOverflowing(fullHeight > computedMaxHeight);
        }
    }, [children, maxHeight]);

    // If children is a string and maxLength is provided, use truncated text when not expanded.
    const renderContent = () => {
        if (typeof children === 'string' && maxLength && !isExpanded) {
            return children.length > maxLength
                ? children.slice(0, maxLength) + '...'
                : children;
        }
        return children;
    };

    return (
        <Stack className="expandable-text-section" p="0px">
            <div
                ref={containerRef}
                style={{
                    maxHeight: isExpanded ? 'none' : `calc(${maxHeight} + 10px)`,
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease'
                }}
            >
                {renderContent()}
            </div>
            {isOverflowing && (
                <Button
                    alignSelf={!isExpanded ? "end" : "start"}
                    mt={!isExpanded ? "-1.5em" : undefined}
                    leftIcon={isExpanded ? <IconChevronLeft size="15px" /> : undefined}
                    size="xs"
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(!isExpanded);
                    }}
                >
                    {isExpanded ? 'Show Less' : '...'}
                </Button>
            )}
        </Stack>
    );
};

export default ExpandableTextSection;