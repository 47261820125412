import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { PageDefinition } from '../model';
import FlowPageProps from './pageProps';
import { AutoUI } from 'geniously-chat-ui';
import { Box, Button, Checkbox, HStack, Icon, IconButton, Image, Stack, Text, Textarea } from '@chakra-ui/react';
import FlowPageWrapper from './pageWrapper';
import { GeniouslyThemeProvider } from 'geniously-chat-ui';
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import remarkGfm from 'remark-gfm'
import { exitCode } from 'process';
import { IconCircleCheck, IconCircleCheckFilled } from '@tabler/icons-react';
import { IconCircle } from '@tabler/icons-react';
import * as Icons from '@tabler/icons-react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import CanvasCustomComponent from './canvasCustomComponents';
interface SharedState {
    selectedSections?: string[];
    sections?: Record<string, any>[]
    editedForms?: { [key: string]: any };
}
interface SelectableSectionProps {
    id: string,
    title: string,
    description: string,
    allow
    allowMultiselect: boolean,
    allowVoting?: boolean,
    vote?: "like" | "dislike" | null,
    voteComment?: string,
    image: string
}

// Create the context
const SharedStateContext = createContext<{
    state: SharedState;
    setState: React.Dispatch<React.SetStateAction<SharedState>>;
} | undefined>(undefined);

// Custom hook to use the shared state context
const useSharedState = () => {
    const context = useContext(SharedStateContext);
    if (!context) {
        throw new Error('useSharedState must be used within a SharedStateProvider');
    }
    return context;
};


const MarkdownContent = ({ children, customComponents, ...rest }: {
    children: string,
    customComponents?: any,
    [key: string]: any
}) => {

    const components = {
        ...(customComponents || {}),
        a: ({ children, href, ...rest }: any) => {
            return <a href={href} target="_blank" rel="noreferrer">{children}</a>
        }

    }

    return (<Box

        {...rest}
        fontWeight={500}
        whiteSpace="initial"
        css={` 
                
                h1{
                    font-size: 1.6em;
                    font-weight: 800;
                    margin: 10px 0px 0px;
                    
                    
                }
                h2{
                    font-size: 1.2em;
                    font-weight: 700;
                      margin: 8px 0px 0px;
                }
                h3{
                    font-size: 1em;
                    font-weight: 600;
                }
                    h4,h5,h6{
                    font-size: 0.9em;
                    font-weight: 600;
                }
                p,b,span {
                    white-space: wrap;
                    font-size: inherit;
                    font-family:  "Segoe UI", "Arial", sans-serif;
                    margin: 8px 0px;
                    font-weight: 500;
                }
                & li {margin-left: 15px;
                             
                    font-family:   "Segoe UI", "Arial", sans-serif;
                }
                & ol > li {
                    list-style-type: decimal;
                }
                & ul > li {
                    list-style-type: initial;
                }
                & a {
                    color: #0078d4;
                    text-decoration: underline;
                    cursor: pointer;
                }
             
                `}
    >




        <ReactMarkdown
            // disallowedElements={['code']}
            // unwrapDisallowed={true}
            remarkPlugins={[remarkBreaks, remarkGfm]}
            components={components}
        >
            {children}
        </ReactMarkdown>



    </Box >)
}

interface CommentDialogProps {
    isOpen: boolean;
    caption?: string;
    onClose: () => void;
    onSubmit: (comment: string) => void;
    initialComment?: string;
    skippable?: boolean
}

const CommentDialog = ({ isOpen, onClose, onSubmit, initialComment = "", caption = "Add comment", skippable = false }: CommentDialogProps) => {
    const [comment, setComment] = useState(initialComment);

    const handleSubmit = () => {
        onSubmit(comment);
        setComment("");
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{caption}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Textarea
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="md"
                        p={2}
                        height="80px"
                        width="100%"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Share your thoughts..."
                    />
                </ModalBody>

                <ModalFooter>
                    <Button
                        aria-label="Cancel"
                        leftIcon={<Icons.IconX />}
                        onClick={onClose}
                        variant="outline"
                        mr={3}
                    >
                        Cancel
                    </Button>
                    <Button
                        aria-label="Submit"
                        leftIcon={comment?.trim() ? <Icons.IconCheck /> : <Icons.IconChevronRight />}
                        onClick={handleSubmit}
                        colorScheme="black"
                        disabled={!comment?.trim() && !skippable}
                    >
                        {comment?.trim() || skippable ? "Submit" : "Skip"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};


const SelectableSection = ({ value }: { value: SelectableSectionProps }) => {
    const { state, setState } = useSharedState();

    const checked = state && state.selectedSections && state.selectedSections.includes(value.id)
    const [modal, setModal] = useState<any>()

    const handleCheckboxChange = () => {
        setState(prevState => {
            const isSelected = prevState?.selectedSections?.includes(value.id);
            let newSelectedSections;

            if (isSelected) {
                newSelectedSections = prevState?.selectedSections?.filter(id => id !== value.id);
            } else {
                if (value.allowMultiselect) {
                    newSelectedSections = [...(prevState?.selectedSections || []), value.id];
                } else {
                    newSelectedSections = [value.id];
                }
            }

            return {
                ...(prevState || {}),
                selectedSections: newSelectedSections,
            };
        });
    };

    const handleVote = (voteType: 'like' | 'dislike', comment: string = undefined) => {


        const setVote = (voteType, comment = undefined) => {
            setState(prevState => {
                const section = {
                    ...value,
                    vote: voteType,
                    vote_comment: comment
                };
                if (!prevState) {
                    return {
                        sections: [section]
                    };
                }
                else
                    return {
                        ...prevState,
                        sections: prevState.sections && prevState.sections.some(s => s.id === value.id)
                            ? prevState.sections.map(s => s.id === value.id ? section : s)
                            : [...(prevState.sections || []), section]
                    };
            });
        }
        if (voteType === "dislike" && !comment) {
            setModal(<CommentDialog
                caption="👎 not like?"
                isOpen={true}
                skippable={true}
                onClose={() => {
                    setModal(null)
                }}
                onSubmit={(comment) => {
                    value.voteComment = comment
                    setVote(voteType, comment)
                    setModal(null)
                }}
            />)
        }
        else {
            setVote(voteType, comment)
        }
    };

    function renderImg(image: string) {
        if (!image) {
            return <></>
        }
        else if (image?.startsWith("icon:")) {
            let iconName = image.replace("icon:", "")
            if (Object.prototype.hasOwnProperty.call(Icons, iconName)) {
                let IconComponent = Icons[iconName] as React.ElementType;
                return <IconComponent size="30px" />
            }
            else {
                return <></>
            }
        } else {
            return <Image src={image} fallback={<></>} />
        }
    }

    return (
        <HStack align="start" m="6px 0px" borderRadius={4} _hover={{ backgroundColor: "gray.50" }} cursor="pointer" p="2px" onClick={handleCheckboxChange}>
            {modal}
            <Stack flexShrink={0} color={checked ? "dodgerblue" : undefined} align="start" justify="start" p="8px 2px">
                {checked ? <IconCircleCheckFilled size="20px" /> : <IconCircle size="20px" />}
            </Stack>

            <HStack fontWeight={500} fontSize="sm" fontFamily="sans-serif" border={checked ? "1px solid lightskyblue" : "1px dashed gray"} color={value.vote === "dislike" ? "darkgray" : undefined}
                width="100%"
                dropShadow="xl"
                align="start"
                backgroundColor={checked ? "rgba(230 230 250 / 0.26)" : undefined}
                borderRadius={4} flexGrow={1} p="8px 8px">
                {
                    value?.image && (<Stack flexShrink={0} color={checked ? "dodgerblue" : undefined} align="start" justify="start" height="30px" width="30px" border="1px solid whitesmoke" borderRadius={4} overflow="hidden">
                        {renderImg(value?.image)}
                    </Stack>)
                }
                <Stack spacing={0} ml="4px" flexGrow={1}>

                    <HStack justify="space-between" align="start">
                    <Text m="0px !important" fontSize="16pt !important" fontWeight={900}>{value.title}</Text>
                        {value.allowVoting && <HStack spacing={0}
                            opacity={value.voteComment ? 1 : 0.6}
                            _hover={{ opacity: 1 }}
                        >
                            {value.vote && <IconButton aria-label='Comment'
                                opacity={value.voteComment ? 1 : 0.3}
                                _hover={{ opacity: 1 }}
                                onClick={() => setModal(<CommentDialog
                                    caption="Comment"
                                    initialComment={value.voteComment || ""}
                                    isOpen={true}
                                    onClose={() => {
                                        setModal(null)
                                    }}
                                    onSubmit={(comment) => {
                                        value.voteComment = comment
                                        handleVote(value.vote, comment)
                                        setModal(null)
                                    }}
                                />)} icon={value.voteComment ? <Icons.IconMessage size="20px" /> : <Icons.IconMessagePlus size="20px" />} variant="ghost" />}
                            <IconButton aria-label='Like' onClick={() => handleVote(value.vote !== "like" ? "like" : null)} icon={value.vote !== "like" ? <Icons.IconThumbUp size="20px" /> : <Icons.IconThumbUpFilled />} variant="ghost" />
                            <IconButton aria-label='Dislike' onClick={() => handleVote(value.vote !== "dislike" ? "dislike" : null)} icon={value.vote !== "dislike" ? <Icons.IconThumbDown size="20px" /> : <Icons.IconThumbDownFilled />} variant="ghost" />
                        </HStack>}
                    </HStack>

                    <MarkdownContent
                        customComponents={{
                            code: (props: any) => {
                                return (
                                    <Text
                                        as="strong"
                                        fontWeight={900}
                                        background={"rgb(240 240 240)"}
                                        borderColor={"gray"}
                                        borderWidth="0.5px"
                                        borderRadius="5px"
                                        p="2px 4px"

                                    >
                                        {props.children}
                                    </Text>
                                );
                            },
                        }}
                    >
                        {value.description}
                    </MarkdownContent>
                </Stack>
            </HStack>
        </HStack>
    );
};

const FormSection = ({ value }: { value: { id: string, title?: string, data: any, data_schema: JSONSchema } }) => {
    const { state, setState } = useSharedState();

    const handleFormChange = (newData: any) => {
        setState(prevState => ({
            ...prevState,
            editedForms: {
                ...(prevState?.editedForms || {}),
                [value.id]: newData
            }
        }));
    };
    const formValue = state?.editedForms && state?.editedForms[value.id] ? state?.editedForms[value.id] : (value.data || {})

    return (
        <Stack fontFamily="sans-serif" m="6px 0px">
            <Text m="0px !important" fontSize="16pt !important" fontWeight={900}>{value.title}</Text>
            <GeniouslyThemeProvider>
                <AutoUI size="xl" value={formValue} schema={value.data_schema} onValueChange={handleFormChange} showOnlySchemaFields />
            </GeniouslyThemeProvider>
        </Stack>
    );
};

const WidgetSection = ({ children, ...rest }: any) => {

    let childrenParsed = useMemo(() => {
        try {
            return JSON.parse(children)
        }
        catch {
            return
        }
    }, [children])
    const type = childrenParsed?.type;
    switch (type) {
        case "selectable":
            return <SelectableSection value={childrenParsed} />;
        case "form":
            return <FormSection value={childrenParsed} />;
        case "component":
            return <CanvasCustomComponent componentId={childrenParsed.component_id} args={childrenParsed.args} />
        default:
            return <Stack p="20px" backgroundColor="gray.100" ><Text whiteSpace="pre">{children?.toString()}</Text></Stack>;
    }
};

const codeQuotes = "```"





const CanvasFlowPage: React.FC<FlowPageProps> = (props: FlowPageProps) => {



    const value = useMemo(() => {
        var sections = props.page.canvas?.sections
        return sections?.map(s => typeof (s) === "string" ? s : "```\n" + JSON.stringify(s) + "\n```").join("\n\n")
    }, [props.currentPageState, props.page.canvas?.sections])



    return (
        <SharedStateContext.Provider value={{ state: props.currentPageState || props.page.canvas, setState: props.setCurrentPageState }}>
            <FlowPageWrapper pageProps={props}>
                <Stack p="10px" align="start">

                    <MarkdownContent
                        customComponents={{
                            code: WidgetSection,
                            pre: ({ children, ...rest }: any) => children
                        }}>
                        {value}
                    </MarkdownContent>
                </Stack>
            </FlowPageWrapper>
        </SharedStateContext.Provider>
    );
};


export default CanvasFlowPage;