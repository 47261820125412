

import React, { useEffect, useMemo, useState } from 'react';

import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList, AutoCompleteTag } from '@choc-ui/chakra-autocomplete';
import { useListContext } from './listContext';


const ColumnPicker = ({ columns: columnsInput, onChange, onSelected, allowCustom, value }: {
    columns?: string[],
    onChange?: (val: string) => void,
    onSelected?: (val: string) => void,
    allowCustom?: boolean,
    value?: string
}) => {
    const listContext = useListContext()
    const columns = columnsInput || listContext?.columns.map(c => c.name)
    const [inputValue, setInputValue] = useState(value)
    useEffect(() => {

    }, [inputValue, allowCustom])
    return <AutoComplete multiple openOnFocus values={columns}

        onChange={(val) => {
            onSelected(val)
        }}
    >
        <AutoCompleteInput size="sm"

            value={inputValue}
            onBlur={(e) => {
                console.log(e.target)
                if (allowCustom && (value || "") != (e.target.value || "")) {
                    if (columns.includes(e.target.value)) {
                        onSelected(e.target.value)
                    }
                    else {

                        onChange(e.target.value)
                    }
                }
            }}
            onChange={(e) => setInputValue(e.target.value)}
            display={value && columns.includes(value) ? "none" : undefined}
            placeholder={allowCustom ? "Select column or type the text" : "Select column as source ..."} >
        </AutoCompleteInput>
        {value && columns.includes(value) && <AutoCompleteTag
            m="6px"
            backgroundColor="gray.200"
            label={value}
            onRemove={() => {
                onSelected(undefined)
                setInputValue("")
            }}
        />}
        <AutoCompleteList p="8px">
            {columns?.map((col_name, cid) => (
                <AutoCompleteItem
                    key={`option-${cid}`}
                    value={col_name}
                    p="2px"
                    fontSize="sm"
                    onClick={() => {
                        onSelected(col_name)
                        setInputValue("")
                    }}

                    textTransform="capitalize"
                    _selected={{ bg: "whiteAlpha.50" }}
                    _focus={{ bg: "whiteAlpha.100" }}
                >
                    {col_name}
                </AutoCompleteItem>
            ))}

        </AutoCompleteList>
    </AutoComplete>
}

export default ColumnPicker;