// Language: tsx
import { Box, Text, Stack, Tag, Divider, Link, HStack, Button } from "@chakra-ui/react";
import { CampaignIdeaWithDetails } from "./dashboardModel";
import { MarkdownContent, GeniouslyThemeProvider } from "geniously-chat-ui";
import ExpandableTextSection from "../ExpandableSection/expandableTextSection";
import { CampaignStatsWidget } from "../Campaigns/campaignStatsWidgets";
import { IconMailFast, IconPlayerPlay, IconTable } from "@tabler/icons-react";

interface CampaignCardProps {
    campaign: CampaignIdeaWithDetails;
    onClick?: () => void;
}

const CampaignIdeaDetail: React.FC<CampaignCardProps> = ({ campaign, onClick }) => {
    // Aggregate campaign stats from campaigns array
    // const stats = campaign.campaigns?.reduce((acc, cur) => ({
    //     contacts_count: acc.contacts_count + cur.contacts_count,
    //     contacted: acc.contacted + cur.contacted,
    //     canceled: acc.canceled + cur.canceled,
    //     accepted_invite: acc.accepted_invite + cur.accepted_invite,
    //     replied: acc.replied + cur.replied,
    // }), { contacts_count: 0, contacted: 0, canceled: 0, accepted_invite: 0, replied: 0 }) || { contacts_count: 0, contacted: 0, canceled: 0, accepted_invite: 0, replied: 0 };

    return (
        <Box borderWidth="1px" borderRadius="lg" p={4} background="white" onClick={onClick}>
            <Stack spacing={3} align="start">
                <Text fontSize="xl" fontWeight="bold">
                    {campaign.campaign_idea.name}{" "}
                    <Tag
                        display="inline"
                        alignSelf="center"
                        p="8px"
                        m="8px"
                        colorScheme={campaign.campaign_idea.status === "running" ? "green" : "gray"}
                    >
                        {campaign.campaign_idea.status}
                    </Tag>
                </Text>

                {campaign.campaign_idea.target_companies_description && (
                    <Text fontSize="sm">
                        Target Companies Description: {campaign.campaign_idea.target_companies_description}
                    </Text>
                )}
                {campaign.campaign_idea.target_companies_locations && (
                    <Text fontSize="sm">
                        Target Companies Locations: {campaign.campaign_idea.target_companies_locations}
                    </Text>
                )}

                <GeniouslyThemeProvider>
                    <MarkdownContent>
                        {campaign.campaign_idea.description}
                    </MarkdownContent>
                </GeniouslyThemeProvider>

                <Divider />
                {campaign?.lists?.length > 1 ? (<Stack>
                    <Text>Associated lists</Text>
                    {campaign?.lists?.map(list => (
                        <Stack key={list.id} spacing={3} align="start">
                            <Link href={`/lists/${list.id}`} isExternal>
                                <Tag><Text fontSize="lg">{list.name}</Text></Tag>
                            </Link>

                        </Stack>
                    ))}</Stack>

                ) : (
                    campaign?.lists?.length > 0 ? (
                        <Stack key={campaign.lists[0].id} spacing={3} align="start">
                            <Text fontWeight={600}>List of leads</Text>
                            <Link href={`/lists/${campaign.lists[0].id}`} isExternal>
                                <Tag><IconTable size="15px" /><Text ml="8px" fontSize="lg">{campaign.lists[0].name}</Text></Tag>
                            </Link>
                        </Stack>
                    ) : (<></>)
                )}

                {campaign?.campaigns?.length > 0 && (
                    <Stack>
                        <Text fontWeight={600}>Campaign{campaign?.campaigns?.length > 1 ? "s" : ""}</Text>
                        {campaign?.campaigns?.map(campaign => (
                            <Link href={`/analytics/${campaign.campaign_id}`} isExternal _hover={{ textDecoration: "none" }}>
                                <Stack key={campaign.campaign_id} spacing={3} align="start" backgroundColor="gray.100" p={2} borderRadius={6}>

                                    <HStack>
                                        <IconMailFast size="20px" />
                                        <Text fontSize="lg" fontWeight={550}>{campaign.name}</Text>
                                    </HStack>
                                    <CampaignStatsWidget stats={campaign} size="md" />
                                </Stack>
                            </Link>

                        ))}
                    </Stack>
                )}
                {/* <Text whiteSpace="pre">{JSON.stringify(campaign, null, 2)}</Text> */}
                {campaign.campaign_idea.status === "proposal" && <Button colorScheme="brand"
                    leftIcon={<IconPlayerPlay size="20px" />}
                    onClick={() => {
                        window.location.href = `/flows/refine-campaign?campaign-idea-id=${campaign.campaign_idea.id}`
                    }}>Refine and launch</Button>}
            </Stack>
        </Box>
    );
};

export default CampaignIdeaDetail;