import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Editable, EditableInput, EditablePreview, filter, list, MenuDivider, MenuOptionGroup, Switch, TagLabel, Tooltip } from "@chakra-ui/react";


import { Box, Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, Checkbox, CloseButton, Flex, HStack, Heading, IconButton, MenuGroup, Portal, Progress, SimpleGrid, Spinner, Stack, Tag, Text, Wrap, WrapItem, background, keyframes, useToast } from "@chakra-ui/react";


import { AutoUI, ChatInput, GeniouslyCommander, ChatService, ChatStatusBadge, GeniouslyThemeProvider, StatusMessage, CommandResultMessage, WidgetContainer } from "geniously-chat-ui";
import { useApiEndpoint, useApiFetch } from "../../utils/useApiHook";
import { IconArrowsDiagonal2, IconAsterisk, IconBoxAlignBottomLeftFilled, IconCheck, IconChevronDown, IconChevronRight, IconChevronsDown, IconChevronUp, IconCircleCheckFilled, IconExclamationCircle, IconFileDownload, IconHistory, IconMailFast, IconPlayerEjectFilled, IconPlayerPauseFilled, IconPlayerPlay, IconPlayerPlayFilled, IconPlayerTrackNextFilled, IconPlus, IconPointFilled, IconRefresh, IconRepeat, IconSend, IconStack2, IconThumbUp } from "@tabler/icons-react";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import { LuImport } from "react-icons/lu";
import { Reorder } from "framer-motion";
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useSWR, { mutate } from "swr";
import { ConfirmModal } from "../ModalDialogs/confirmModal";
import { EditModal } from "../ModalDialogs/editModal";
import { IconFilter } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { InfoModal } from "../ModalDialogs/infoModal";
import Navigation from "../Navigation/navigation";
import GenericList from "../GenericList/genericList";
import { IconArrowsDiagonalMinimize } from "@tabler/icons-react";
import { IconWindowMaximize } from "@tabler/icons-react";
import { ListOpDialog } from "../Lists/listOpDialog";
import {

    DataListColumn,
    DataListInfo,
    ListOp,
    ListOpTypeInfo,
    ListPreview
} from "../Lists/model";
import { IconCircleDashed } from "@tabler/icons-react";
import { IconRotateClockwise2 } from "@tabler/icons-react";

import { ListOpsWidget } from "../Lists/listOpsWidget";
import { ListVersionsComponent } from "../Lists/listVersionsComponent";
import { FilterButton } from "../Lists/filterButton";
import CampaignBuilder from "../Campaigns/campaignBuilder";
import { ListProvider } from "../Campaigns/listContext";
import { ListOpsSelectionDialog } from "../Lists/listOpsSelectionDialog";
import ListHeader from "../Lists/listHeader";




const ListOpsForm = ({ importOps, listOps, currentOp, executorState, openVersions, scheduledOps, onOpAction, onExecutorAction }: {
    listOps: ListOp[]
    importOps?: ListOp[],
    openVersions?: () => void,
    currentOp?: ListOp,
    executorState: "running" | "ready" | "paused"
    scheduledOps: ListOp[]
    onOpAction?: (op: ListOp | "all", action: "pause" | "resume" | "stop" | "retry" | "rerun" | "cancel" | "update" | "delete", options?: any) => Promise<any>
    onExecutorAction?: (action: "stop" | "resume") => Promise<any>
}) => {

    function getStateIcon(state: "init" | "running" | "paused" | "stopped" | "done" | "error") {
        if (state == "init") {
            return <IconCircleDashed size="15px" />
        }

        else if (state == "paused") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "stopped") {
            return <IconPlayerPauseFilled size="15px" />
        }
        else if (state == "done") {
            return <Stack color="#50C878"><IconCircleCheckFilled size="15px" /></Stack>
        }
        else if (state == "error") {
            return <Stack color="#FF5733"><IconExclamationCircle color="crimson" size="15px" /></Stack>

        }
        else if (state == "running") {
            return <Stack ><Spinner size="xs" /></Stack>

        }
        else {
            return <Text>state</Text>
        }
    }
    const [modal, setModal] = useState<any>()
    const [waiting, setWaiting] = useState<boolean>()
    const [error, setError] = useState<string>()
    const [updatingOpId, setUpdatingOpId] = useState<string>()
    const [updatedOps, setUpdatedOps] = useState<{ [id: string]: ListOp }>()
    const [editMode, setEditMode] = useState<boolean>(false)

    function getListOpsAccordion(listOps: ListOp[], isImport: boolean = false) {
        return <Accordion allowToggle index={undefined} >
            {listOps?.map((_op, i) => {
                const op = updatedOps ? (updatedOps[_op.id] || _op) : _op
                if (!op) {
                    return <></>
                }
                return <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                        {({ isExpanded }) => (
                            <>
                                <HStack>
                                    <AccordionButton p="2px"  >
                                    <AccordionIcon />
                                        <Stack flexGrow={1} align="start">
                                            <Text >{op.name} </Text>


                                        </Stack>

                                </AccordionButton>
                                {updatingOpId == op.id ? (<Box m="0px 8px"><Spinner size="xs" /></Box>) : <HStack m="0px 8px">
                                        {/* <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconRefresh size="15px" />} onClick={() => {

                                        }} /> */}
                                        {op.state_message && <Tooltip label={op.state_message} >
                                            <Tag maxW="200px" whiteSpace="nowrap" textOverflow="ellipsis" ><TagLabel>{op.state_message}</TagLabel></Tag>
                                        </Tooltip>}

                                        {op.processed_count && (
                                            <Tooltip label={op.processed_count + " processed records"}>
                                                <Tag size="sm" colorScheme="gray" ><IconCheck size="12px" /> {op.processed_count}</Tag>
                                            </Tooltip>

                                        )}
                                        {op.imported_count && (
                                            <Tooltip label={`Imported ${op.imported_count} records out of ${op.total_records || '?'}`}>
                                                <Tag size="sm" colorScheme="gray" whiteSpace="nowrap"><IconCheck size="12px" /> {op.imported_count} / <b> {op.total_records || "?"}</b></Tag>
                                            </Tooltip>

                                        )}
                                        {
                                            <Tooltip label={op.state} >
                                                {getStateIcon(op.state)}
                                            </Tooltip>
                                        }


                                        {(op.state == "paused" || op.state == "stopped" || op.state == "error") && <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                                            setModal(
                                                <RunOptionsDialog onOk={(options: any) => {


                                                    onOpAction(op, "rerun", options).then(() => {
                                                        setModal(undefined)
                                                    })


                                                }} onCancel={() => {
                                                    setModal(undefined)
                                                    }} />

                                            )

                                        }} >{op.state == "error" ? "Try continue" : "Continue"}</Button>}

                                    {!isImport &&
                                        <Tooltip label="Auto run on new rows">
                                            <Box opacity={0.8}>
                                                <Switch colorScheme="green" size="sm" isChecked={op.auto_run_on_new_rows} onChange={() => {
                                                    setUpdatingOpId(op.id)
                                                    onOpAction(op, "update", { auto_run_on_new_rows: !op.auto_run_on_new_rows }).then(() => {
                                                        setUpdatedOps({ ...(updatedOps || {}), [op.id]: { ...op, auto_run_on_new_rows: !op.auto_run_on_new_rows } })
                                                    }).finally(() => {
                                                        setUpdatingOpId(undefined)
                                                    })
                                                }} />
                                            </Box>
                                        </Tooltip>}
                                    <Box>
                                        <IconButton className="row-btn" opacity={0.5} _hover={{ opacity: 1 }} aria-label="ss" size="xs" variant="ghost" icon={<IconX size="15px" />} onClick={() => {
                                            setUpdatingOpId(op.id)
                                            onOpAction(op, "delete").then(() => {
                                                setUpdatedOps({ ...(updatedOps || {}), [op.id]: null })
                                            }).finally(() => {
                                                setUpdatingOpId(undefined)
                                            })
                                        }} />
                                    </Box>
                                </HStack>}
                                </HStack>
                                <AccordionPanel p="0px">
                                    <Stack p="8px">
                                        <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                        {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                            <AutoUI value={op.kwargs} />
                                        )}
                                        {op.stages && (
                                            op.stages.map((stage, i) => (
                                                <Accordion allowToggle index={undefined} >
                                                    <AccordionItem border="1px solid lightgray" borderRadius={4} mt="4px" >
                                                        <AccordionButton p="2px"  >
                                                            <Text fontSize="sm">- {stage.name}</Text>
                                                        </AccordionButton>
                                                        <AccordionPanel p="0px">
                                                            <AutoUI value={stage.args} schema={stage.args_schema} />
                                                        </AccordionPanel>

                                                    </AccordionItem>
                                                </Accordion>

                                            ))
                                        )}
                                        {op.filters && op.filters.map && (
                                            <Stack spacing={2}>
                                                <HStack>
                                                    <IconFilter size="15px" />
                                                    <Text fontWeight={700} fontSize={13}>Applied filters</Text>
                                                </HStack>
                                                <Stack p="0px 8px" spacing={1} fontSize={13} >
                                                    {op.filters.map((filter, i) => (
                                                        <HStack key={i}>
                                                            <IconPointFilled size="15px" />
                                                            {typeof (filter) === "string" ? (
                                                                <Text >{filter}</Text>
                                                            ) : (
                                                                <Tooltip label={filter.expression}>
                                                                    <HStack>

                                                                        <Text>{filter.name}</Text>
                                                                    </HStack>
                                                                </Tooltip>
                                                            )}
                                                        </HStack>
                                                    ))}

                                                </Stack>
                                            </Stack>
                                        )}
                                        <HStack>
                                            <Button alignSelf="end" size="xs"
                                            onClick={() => {
                                                onOpAction(op, "retry")

                                            }}
                                                leftIcon={<IconRefresh size="15px" />} >Edit / Retry</Button>

                                        {<Button alignSelf="end" size="xs"
                                                onClick={() => {



                                                        setModal(
                                                            <EditModal caption="Re-run operation" value={{}} onOk={(val: any) => {
                                                                if ((val && Object.keys(val).length)) {
                                                                    let overwrite = null;

                                                                    if (val.emptyAndFailed) {
                                                                        overwrite = "empty_and_failed"
                                                                    }
                                                                    else if (val.allRecords) {
                                                                        overwrite = "all"
                                                                    }
                                                                    onOpAction(op, "rerun", { overwrite: overwrite, override_filter_current_view: val.override_filter_current_view }).then(() => {
                                                                        setModal(undefined)
                                                                    })

                                                                }
                                                            }} onCancel={() => {
                                                                setModal(undefined)
                                                            }}>
                                                                {(val, setVal) => (<Stack>
                                                                    <Text>Are you sure you want to re-run the operation?</Text>
                                                                    <ReRunOptions optionsValue={val as any} setOptionsValue={setVal} />
                                                                </Stack>)}
                                                            </EditModal>
                                                        )


                                                }}
                                            leftIcon={<IconRotateClockwise2 size="15px" />} >Re-run</Button>}




                                        </HStack>

                                    </Stack>
                                </AccordionPanel>
                            </>)}
                    </AccordionItem>
            })}
            </Accordion>
    }


    return (<Stack >
        {modal}
        {waiting && <Spinner />}
        <Stack spacing={1} opacity={waiting ? 0.5 : undefined} pointerEvents={waiting ? "none" : undefined} maxH="70vh" overflow="auto">
            <HStack width="100%" justify="space-between">
                <Text>Sources</Text>
                <Button colorScheme="brand" size="sm" variant="outline" justifySelf="start" leftIcon={<IconHistory />} onClick={() => openVersions()}>Version history</Button>

            </HStack>
            {getListOpsAccordion(importOps, true)}
            <HStack width="100%" justify="space-between" pt="15px">
            <Text>Operations</Text>
                <Menu>
                    <MenuButton as={Button} leftIcon={<Box transform="rotate(90deg)"><IconPlayerEjectFilled size="18px" /></Box>} rightIcon={<IconChevronDown />} size="xs" variant="outline" colorScheme="brand" >Re-run operations</MenuButton>
                    <MenuList fontSize="xs">


                        <MenuItem icon={<HStack spacing="0px"><IconPlayerPlayFilled size="15px" /><IconAsterisk style={{ position: "relative", top: "-5px", margin: "-5px" }} size="10px" /></HStack>} iconSpacing={2} onClick={() => {
                            setWaiting(true)
                            onOpAction("all", "rerun").catch(e => {
                                setError(e.toString())
                            })
                                .finally(() => {
                                    setWaiting(false)

                                })
                        }}>Run on New and Unprocessed Row</MenuItem>
                        <MenuItem icon={<IconPlayerPlay size="15px" />} iconSpacing={2} onClick={() => {
                            setModal(
                                undefined
                            )
                        }}>Empty and failed</MenuItem>
                        <MenuItem icon={<IconRepeat size="15px" />} iconSpacing={2} onClick={() => {
                            setModal(
                                <ConfirmModal caption="Re-run all operations" question="Are you sure you want to re-run all operations on all visible rows?" onOk={() => {
                                    onOpAction(undefined, "rerun").then(() => {
                                        setModal(undefined)
                                    })
                                }} onCancel={() => {
                                    setModal(undefined)
                                }} />
                            )
                        }}>All visible rows</MenuItem>


                    </MenuList>

                </Menu>

            </HStack>
            {getListOpsAccordion(listOps)}


            {scheduledOps?.length && <Stack>
                <HStack justify="space-between">
                    <Text fontWeight={600} color="gray">Pending</Text>

                </HStack>
                <Accordion allowToggle index={undefined} >
                    {scheduledOps?.map((op, i) => (
                        <AccordionItem key={i} border="1px solid lightgray" borderRadius={4} mt="4px" >
                            {({ isExpanded }) => (
                                <>
                                    <HStack>
                                        {/* <Text fontSize={10}>{JSON.stringify(op)}</Text> */}
                                        <AccordionButton p="2px"  >
                                            <AccordionIcon />
                                            <Stack flexGrow={1} align="start">
                                                <Text >{op.name || op.op_type}</Text>

                                            </Stack>

                                        </AccordionButton>
                                        <HStack m="0px 8px">
                                            {getStateIcon(op.state)}
                                            <IconButton className="row-btn" aria-label="ss" size="xs" variant="ghost" icon={<IconX size="15px" />} onClick={() => {
                                                setWaiting(true)
                                                onOpAction(op, "cancel").finally(() => {
                                                    setWaiting(false)
                                                })
                                            }} />
                                        </HStack>
                                    </HStack>
                                    <AccordionPanel p="0px">
                                        <Stack spacing={1} p="8px">
                                            <Text fontSize="md" fontWeight={900} mb="-8px">{op.op_type}</Text>
                                            {isExpanded && op.kwargs && Object.keys(op.kwargs).length && (
                                                <AutoUI value={op.kwargs} />
                                            )}

                                        </Stack>

                                    </AccordionPanel>
                                </>)}
                        </AccordionItem>
                    ))}
                </Accordion>

            </Stack>}

            <HStack justify="space-between" m="10px 0px" p="4px 8px" border="1px solid gray" borderRadius="8px" shadow="md" backgroundColor="gray.50">
                {currentOp && <Text fontWeight={900}>Current: {currentOp.name}</Text>}
                <HStack justifySelf="end">
                    {getStateIcon(executorState as any)}  <Text fontSize="xs">Execution {executorState}</Text>
                    <Button size="xs" variant="outline" colorScheme="red" onClick={() => {
                        onExecutorAction(executorState == "running" ? "stop" : "resume")
                    }}>{executorState == "running" ? "Stop" : "Resume"}</Button>
                </HStack>
            </HStack>

        </Stack>

    </Stack>
    )
}




const ReRunOptions = ({ optionsValue, setOptionsValue }: {
    optionsValue: { newRecords: boolean, emptyAndFailed: boolean, allRecords: boolean },
    setOptionsValue: (value: any) => void
}) => {

    return <Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["newRecords"]} onChange={(e) => {
                setOptionsValue({ ...(optionsValue || {}), emptyAndFailed: optionsValue.emptyAndFailed, "newRecords": e.target.checked })
            }}>New records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">Records that have been added later, or record that have been filtered out by the view</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["emptyAndFailed"]} onChange={(e) => {
                setOptionsValue({ ...(optionsValue || {}), newRecords: optionsValue.newRecords, "emptyAndFailed": e.target.checked })
            }}>Empty and failed</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">This includes all records that don't have value (relevant for column operations)</Text>
        </Stack>
        <Stack spacing={0}>
            <Checkbox isChecked={!!optionsValue["allRecords"]} onChange={(e) => {
                setOptionsValue({ ...(optionsValue || {}), "allRecords": e.target.checked })
            }}>All records</Checkbox>
            <Text ml="25px" fontStyle="italic" fontSize="xs">All current values (overwrite existing)</Text>
        </Stack>
        <Stack>
            <Text fontWeight={600}>Filter override</Text>
            <Stack spacing={0}>
                <HStack>

                    <Switch isChecked={!!optionsValue["override_filter_current_view"]} onChange={(e) => {
                        setOptionsValue({ ...(optionsValue || {}), "override_filter_current_view": e.target.checked })
                    }} />
                    <Text>{optionsValue["override_filter_current_view"] ? "Override filter with current view" : "Use original filter defined on the operation"}</Text>
                </HStack>
                <Text ml="40px" fontStyle="italic" fontSize="xs">Whether to run the operation on the same subset of rows as originally defined, or wether to temporarily override the defined filters and execute on {optionsValue["allRecords"] ? <b>all</b> : "the"} visible rows {optionsValue["allRecords"] ? "." : <b>{optionsValue["emptyAndFailed"] ? "that are empty or failed" : (optionsValue["newRecords"] ? "that are new" : "where operation has not been executed yet")}</b>}</Text>
            </Stack>
        </Stack>
    </Stack>
}

const RunOptions = ({ options = [10, 50, 100, 200, 500], stopAfter, onStopAfterChange }: {
    options?: number[],
    stopAfter: number | null,
    onStopAfterChange: (value: number | null) => void
}) => {
    function optionToString(option: number | null) {
        return option ? `stop after ${option} rows` : "Process to the end"
    }
    return <Stack>

        <Menu>
            <MenuButton as={Button} rightIcon={<IconChevronDown />} size="xs" variant="outline" colorScheme="red" >{optionToString(stopAfter)}</MenuButton>
            <MenuList>
                {[...options, null].map((opt, i) => (
                    <MenuItem key={i} onClick={() => {
                        onStopAfterChange(opt)
                    }}>{optionToString(opt)}</MenuItem>
                ))}

            </MenuList>
        </Menu>
    </Stack>
}


const RunOptionsDialog = ({ defaults, onOk, onCancel }: {
    defaults?: {
        pause_after_steps?: {
            default: number,
            options: number[]
        }
    }
    onOk: (options: { overwrite?: null | string, pause_after_steps?: number }) => void,
    onCancel: () => void
}) => {
    return <EditModal size="sm" withFullscreenSwitch={false} caption="Continue operation" value={defaults?.pause_after_steps?.default}
        onOk={(val: any) => {
            onOk({ overwrite: null, pause_after_steps: val })
        }}
        onCancel={() => {
            onCancel()
        }}>
        {(val, setVal) => (<Stack>
            <Text>Do you want to run the op the end of the list?</Text>
            <RunOptions stopAfter={val} onStopAfterChange={setVal} options={defaults?.pause_after_steps?.options} />
        </Stack>)}
    </EditModal>
}

export { ListOpsForm, RunOptionsDialog }